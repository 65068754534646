import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  resetSnackbar,
  setSnackbar,
} from "../store/snackbarSlice/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";

const useSnackbar = () => {
  const dispatch = useDispatch();
  const snackbarInfo = useSelector((state) => state.snackbarInfo);

  const { message, isSnackbar } = snackbarInfo || {};

  const showSnackbar = (message) => {
    const showSnackBarInfo = {
      message: message,
      isSnackbar: true,
    };
    dispatch(setSnackbar(showSnackBarInfo));
  };
  const closeSnackbar = () => {
    dispatch(resetSnackbar());
  };
  const AlertSnackbar = () => (
    <Snackbar
      open={isSnackbar}
      autoHideDuration={3000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={closeSnackbar} severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
  return {
    showSnackbar,
    AlertSnackbar,
  };
};
export default useSnackbar;
