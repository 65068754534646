import React, { useState } from "react";
import DataGridComponent from "../../../../Common/DataGridComponent";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  AttendanceMuiCard,
  EmpTypography,
  JobMuiCard,
  LeaveGrid,
  PendingText,
  ViewText,
  ViewTypography,
} from "../Dashboard.styled";
import Grid from "@mui/material/Grid";
import { useSideNav } from "../../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import { setNavigationRoute } from "../../../SideNavbar/Store/navigationSlice/navigationSlice";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";
import { useTheme } from "@emotion/react";
import {
  StatusGrid,
  StyledLensIcon,
  StyledTypography,
} from "../../../Performance/Components/MyPerformance.styled";
import { useNavigate } from "react-router-dom";

const HrAdminLeaveApproval = () => {
  useSideNav();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;

  const rowData = [
    {
      employeName: "Dibya Ranjan Sahu",
      id: "123456",
      designation: "Software Developer",
      requestType: "Sick Leave",
      requestedDate: "09-04-2024",
      reason: "Not feeling well",
      status: "Pending",
    },
    {
      employeName: "Emma Johnson",
      id: "123457",
      designation: "UI/UX Designer",
      requestType: "Vacation",
      requestedDate: "08-12-2024",
      reason: "Family vacation",
      status: "Approved",
    },
    {
      employeName: "Liam Smith",
      id: "123458",
      designation: "Product Manager",
      requestType: "Casual Leave",
      requestedDate: "07-20-2024",
      reason: "Personal errands",
      status: "Rejected",
    },
    {
      employeName: "Sophia Brown",
      id: "123459",
      designation: "HR Manager",
      requestType: "Maternity Leave",
      requestedDate: "06-01-2024",
      reason: "Expected delivery date approaching",
      status: "Pending",
    },
    {
      employeName: "Noah Davis",
      id: "123460",
      designation: "QA Engineer",
      requestType: "Sick Leave",
      requestedDate: "09-10-2024",
      reason: "Recovering from surgery",
      status: "Approved",
    },
  ];

  const colsData = [
    {
      field: "employeName",
      headerName: "Employee Name",
      width: 140,
      renderCell: (params) => (
        <Grid container direction="column">
          <EmpTypography>{params.row.employeName}</EmpTypography>
          <ViewTypography>{params.row.designation}</ViewTypography>
        </Grid>
      ),
    },
    { field: "id", headerName: "Id", width: 90 },
    { field: "requestType", headerName: "Request Type", width: 120 },
    { field: "requestedDate", headerName: "Requested Dates", width: 130 },
    { field: "reason", headerName: "Reason", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const formattedStatus = capitalize(params?.row?.status);
        const value =
          formattedStatus === "Approved"
            ? theme.palette.success.main
            : formattedStatus === "Pending"
            ? theme.palette.warning.main
            : theme.palette.error.main;

        return (
          <StatusGrid>
            <StyledLensIcon color={value} />
            <StyledTypography color={value}>{formattedStatus}</StyledTypography>
          </StatusGrid>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 70,
      align: "center",
      getActions: (params) => {
        return [
          <GridActionsCellItem label="Approve" showInMenu />,
          <GridActionsCellItem label="Reject" showInMenu />,
          <GridActionsCellItem label="View" showInMenu />,
        ];
      },
    },
  ];

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleLeaveOverview = () => {
    navigate("/my-action");
    dispatch(setNavigationRoute("/my-action"));
  };

  return (
    <>
      <AttendanceMuiCard>
        <LeaveGrid>
          <PendingText>Pending Approvals</PendingText>
          <ViewText underline="always" onClick={handleLeaveOverview}>
            View All
          </ViewText>
        </LeaveGrid>
        <DataGridComponent
          autoHeight
          columnsData={colsData}
          rowData={rowData}
          editable={true}
          height={"40vh"}
          page={page - 1}
          handlePaginationChange={handlePaginationChange}
        />
      </AttendanceMuiCard>
    </>
  );
};

export default HrAdminLeaveApproval;
