import { createSlice } from "@reduxjs/toolkit";
import {
  _setIsMyAssignmentForm,
  _setIsSubmitMyAssignment,
  _setMyAssignmentForm,
} from "./MyAssignmentAction";
import initialState from "./MyAssignmentInitialState";

export const myAssignmentFormSlice = createSlice({
  name: "ep/myAssignment",
  initialState,
  reducers: {
    setMyAssignmentForm: _setMyAssignmentForm,
    setIsMyAssignmentForm: _setIsMyAssignmentForm,
    setIsSubmitMyAssignment: _setIsSubmitMyAssignment,
    resetMyAssignmentForm: () => initialState,
  },
});

export const {
  setMyAssignmentForm,
  setIsMyAssignmentForm,
  resetMyAssignmentForm,
  setIsSubmitMyAssignment,
} = myAssignmentFormSlice.actions;

export default myAssignmentFormSlice.reducer;
