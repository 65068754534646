import React, { useEffect, useState } from "react";
import {
  useSideNav,
  useLoader,
  useNotification,
} from "../../../Common/CommonUtils";
import { Grid } from "@mui/material";
import { getProjectHistory } from "../services/MyAssignmentServices";
import { formattedHistory } from "./ProjectHistoryUtils";
import { useSelector, useDispatch } from "react-redux";
import { NoDataText } from "./MyAssignment.styled";
import {
  HistoryContainer,
  TimelineSection,
  LabelText,
  DateText,
  RowText,
  Dates,
  ResponsiveTitle,
} from "./MyAssignment.styled";
const ProjectHistory = () => {
  useSideNav();
  const userId = useSelector((state) => state.user?.data?.employeeId);
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const [historyData, setHistoryData] = useState([]);
  const { setLoading, LoaderPopup } = useLoader();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      setLoading(true);
      const projectData = await getProjectHistory(userId, employeeId);
      const projectHistory = formattedHistory(
        projectData.data.ProjectAssignedList
      );
      setHistoryData(projectHistory);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };

  return (
    <>
      {historyData.length > 0 ? (
        <>
          <ResponsiveTitle gutterBottom>Project Timeline</ResponsiveTitle>
          <HistoryContainer mt={4}>
            {historyData.map((project) => (
              <Grid index={project.id} mb={3}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    mb={1}
                    container
                    alignItems="center"
                  >
                    <DateText>Project Start Date</DateText>
                    <Dates>Effective {project.startDate}</Dates>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    mb={1}
                    container
                    alignItems="center"
                  >
                    <DateText>Project End Date</DateText>
                    <Dates>Effective {project.relievedDate || "Ongoing"}</Dates>
                  </Grid>
                </Grid>
                <Grid container>
                  <TimelineSection item xs={12} sm={8}>
                    <Grid item xs={12}>
                      <LabelText>Project Name:</LabelText>
                      <RowText>{project.projectName}</RowText>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelText>Department:</LabelText>
                      <RowText>{project.department}</RowText>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelText>Billing Type:</LabelText>
                      <RowText>{project.billableType}</RowText>
                    </Grid>
                  </TimelineSection>
                </Grid>
              </Grid>
            ))}
          </HistoryContainer>
        </>
      ) : (
        <Grid container justifyContent="center" alignItems="center" mt={34}>
          <NoDataText>No project history available</NoDataText>
        </Grid>
      )}
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default ProjectHistory;
