import React, { useState } from "react";
import { FormControlLabel, Grid, Popover, Typography } from "@mui/material";
import {
  useLoader,
  useNotification,
  useSideNav,
} from "../../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import FormDropDown from "../../../../Common/FormDropDown";
import {
  CheckboxCloumn,
  CheckboxRow,
  FilterClearContainer,
  FilterContainer,
  MyTimesheetDate,
  TeamCalendarMonth,
} from "../MyTimesheet.styled";
import { DataMainGrid } from "../../../Employees/Employee.Styled";
import { FormInputSearch } from "../../../../Common/FormInputSearch";
import { DayCalendar } from "../../../../Common/CalenderMonthWeek";
import { StyledButton } from "../../../../Common/Ui/styled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getTeamTimesheet } from "../../services/MyTimeSheetServices";
import { getTeamTimesheetData } from "./TeamTimesheetDataGridUtils";
import DataGridComponent from "../../../../Common/DataGridComponent";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";
import useDebounceEffect from "../../../../Common/CustomHooks/useDebounceEffect";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import {
  CalendarMonthIconContainer,
  StatusContainer,
  StyledIcon,
  StyledText,
} from "../../../Attendance/Components/LogAttendance.styled";
import ClearFilterButton from "../../../Employees/ClearFilterButton";
import { CheckboxContainer } from "../../../MyAssignment/Components/MyAssignment.styled";
import useWeekDetails from "../../../../Common/useWeekDetails";

const TeamTimesheet = () => {
  useSideNav();
  const [teamTimeSheet, setTeamTimeSheet] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [projectsDropdown, setProjectsDropdown] = useState(null);
  const tempData = [];
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const {
    // weekData,
    // weekDetails,
    weekStartDate,
    weekEndDate,
    calendarAnchor,
    setWeekStartDate,
    setWeekEndDate,
    handleDateChange,
    handleCalendarIconClick,
    handleCalendarClose,
  } = useWeekDetails(tempData);

  const open = Boolean(calendarAnchor);
  const metaData = useSelector((state) => state?.metaData);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = teamTimeSheet;
  const SearchResult =
    searchQuery || projectsDropdown || weekStartDate || weekEndDate;

  const handleEditTimeSheet = (params) => {
    console.log("handleEditTimeSheet", params);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setIsAllSelected(isChecked);
    setTeamTimeSheet((prev) => ({
      ...prev,
      rows: prev.rows.map((row) => ({
        ...row,
        isSelected: isChecked,
      })),
    }));
  };

  const handleRowCheckboxChange = (employeeId, isChecked) => {
    setTeamTimeSheet((prev) => ({
      ...prev,
      rows: prev.rows.map((row) =>
        row.employeeId === employeeId ? { ...row, isSelected: isChecked } : row
      ),
    }));
  };

  const sorting = {
    sortModel: [{ field: "employeeName", sort: "asc" }],
  };

  const statusStyles = {
    approved: {
      color: "green",
      Icon: FiberManualRecordRoundedIcon,
    },
    draft: {
      color: "orange",
      Icon: FiberManualRecordRoundedIcon,
    },
    pending: {
      color: "orange",
      Icon: FiberManualRecordRoundedIcon,
    },
  };

  const columnsData = [
    {
      field: "employeeName",
      headerName: "Employee Name",
      width: 270,
      renderHeader: (params) => {
        return (
          <CheckboxCloumn>
            <FormControlLabel
              control={
                <CheckboxContainer
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  indeterminate={
                    teamTimeSheet.rows.some((row) => row.isSelected) &&
                    !isAllSelected
                  }
                />
              }
              label="Employee Name"
            />
          </CheckboxCloumn>
        );
      },
      renderCell: (params) => {
        const { employeeName, isFirstInGroup, employeeId, isSelected } =
          params.row;
        return (
          <CheckboxRow>
            <FormControlLabel
              value={employeeName}
              control={
                <CheckboxContainer
                  checked={isSelected || false}
                  onChange={(event) =>
                    handleRowCheckboxChange(employeeId, event.target.checked)
                  }
                />
              }
              label={employeeName}
            />
          </CheckboxRow>
        );
      },
    },
    {
      field: "project",
      headerName: "Project Name",
      width: 240,
    },
    {
      field: "date",
      headerName: "Date",
      width: 190,
      renderCell: (params) => {
        const { weekStartDate, weekEndDate } = params.row;
        return (
          <Typography variant="body">{`${weekStartDate} to ${weekEndDate}`}</Typography>
        );
      },
    },
    {
      field: "totalHours",
      headerName: "Total Hours Worked For Week",
      width: 270,
    },
    {
      field: "status",
      headerName: "Status",
      width: 190,
      renderCell: (params) => {
        const status = params.row.status;
        const statusText = status ? status.toLowerCase() : "";
        const { color, Icon } =
          statusStyles[statusText] || statusStyles.default;

        return (
          <StatusContainer alignItems={"center"}>
            <StyledIcon color={color}>
              <Icon
                size="small"
                style={{ fontSize: "12px", marginTop: "6px" }}
              />
            </StyledIcon>
            <StyledText color={color}>{status}</StyledText>
          </StatusContainer>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: (params) => {
        return (
          <StyledButton variant="text">
            <VisibilityIcon onClick={() => handleEditTimeSheet(params)} />
          </StyledButton>
        );
      },
    },
  ];

  const defaultValue = {};
  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { control, formState, reset, register } = useFunction;
  const { errors } = formState;

  const { projectList } = metaData || [];

  const mapToProjectOptionsList = (items) =>
    items?.map((item) => ({
      label: item.projectName,
      value: item.projectName,
    })) || [];

  const projectsOptionsList = mapToProjectOptionsList(projectList);

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  useDebounceEffect(
    () => {
      fetchTeamTimeSheet();
      setIsFilterSelected(SearchResult);
    },
    500,
    [page, pageSize, searchQuery, projectsDropdown, weekStartDate, weekEndDate]
  );

  const fetchTeamTimeSheet = async () => {
    try {
      setLoading(true);
      setTeamTimeSheet({ ...teamTimeSheet, isLoading: true });

      const payload = {
        userId: loginUserId,
        page: page,
        limit: pageSize,
        ...(projectsDropdown && { project: projectsDropdown }),
        ...(searchQuery && { searchBy: searchQuery }),
        ...(weekStartDate && { weekStartDate: weekStartDate }),
        ...(weekEndDate && { weekEndDate: weekEndDate }),
      };

      const teamTimesheetList = await getTeamTimesheet(payload);

      if (teamTimesheetList?.data) {
        const TeamTimeSheetData = getTeamTimesheetData(teamTimesheetList?.data);
        setTeamTimeSheet((prev) => ({
          ...prev,
          rows: TeamTimeSheetData,
          isLoading: false,
          totalItems: teamTimesheetList?.pagination?.totalItems,
        }));
        setLoading(false);
      } else {
        setLoading(false);
        setNotificationMessage("Invalid API response");
      }
    } catch (error) {
      setLoading(false);
      setTeamTimeSheet({ ...teamTimeSheet, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };

  const clearSelection = () => {
    reset(defaultValue);
    setProjectsDropdown("");
    setSearchQuery("");
    setWeekStartDate(null);
    setWeekEndDate(null);
  };

  const handleApproveAll = () => {
    const getSelectedItems = () => {
      return teamTimeSheet.rows.filter((row) => row.isSelected);
    };
    const selectedItems = getSelectedItems();
    console.log(selectedItems, "selectedItems");
  };

  return (
    <>
      <DataMainGrid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2.5} md={4} xl={4}>
            <Typography variant="h5" component="body">
              Team sheet
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9.5} md={8} xl={8}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <FilterContainer item xs={12} sm={3.5} md={3.5} xl={3.5}>
                <FormInputSearch
                  searchQuery={searchQuery}
                  fullWidth
                  setSearchQuery={setSearchQuery}
                  placeholder="Search"
                />
              </FilterContainer>
              <FilterContainer item xs={12} sm={3} md={3} xl={3.5}>
                <FormDropDown
                  label="Projects"
                  name="projects"
                  control={control}
                  errors={errors?.projects}
                  helperText={errors?.projects?.message}
                  options={projectsOptionsList}
                  register={register("projects", {
                    onChange: (e) => {
                      setProjectsDropdown(e.target.value);
                    },
                  })}
                />
              </FilterContainer>
              <Grid item xs={12} sm={5.5} md={5.5} xl={5}>
                <MyTimesheetDate container spacing={1}>
                  <FilterClearContainer item xs={2} sm={2} md={2} xl={2}>
                    <TeamCalendarMonth
                      size="large"
                      onClick={handleCalendarIconClick}
                    >
                      <CalendarMonthIconContainer />
                    </TeamCalendarMonth>
                  </FilterClearContainer>
                  <Grid xs={6} sm={4} md={4} xl={4.5}>
                    {isFilterSelected && (
                      <ClearFilterButton clearSelection={clearSelection} />
                    )}
                  </Grid>
                  <Grid item xs={4} sm={4.5} md={5} xl={4.5}>
                    <StyledButton
                      width={"120px"}
                      style={{ margin: "0px" }}
                      variant="contained"
                      onClick={handleApproveAll}
                    >
                      Approve All
                    </StyledButton>
                  </Grid>
                </MyTimesheetDate>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <DataGridComponent
          columnsData={columnsData}
          rowData={teamTimeSheet.rows}
          editable={true}
          isLoading={isLoading}
          rowCount={totalItems}
          page={page - 1}
          sorting={sorting}
          handlePaginationChange={handlePaginationChange}
        />

        <Popover
          open={open}
          anchorEl={calendarAnchor}
          onClose={handleCalendarClose}
        >
          <DayCalendar onDateSelect={handleDateChange} />
        </Popover>
      </DataMainGrid>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default TeamTimesheet;
