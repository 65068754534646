import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

export const LayoutRoot = styled(Grid)`
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  ${(props) =>
    props.isSideNav && props.isTab
      ? `margin-left: 84px`
      : !props.isTab
      ? `margin-left:${props.theme.palette.spacing[1] * 2}px`
      : `0px`};
`;

export const LayoutContainer = styled(Grid)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`;