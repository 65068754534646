import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

export const LogContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 634px;
  margin-top: 10px;
  overflow-x: hidden;

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.md}px) {
    max-height: auto;
    min-height: 100vh;
  }
`;

export const TitleContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BackButtonGrid = styled(Grid)`
  display: flex;
  align-self: center;
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 0.5}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: flex;
    flex-direction: column-reverse;
    margin-right: 0px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-right: 8px;
    display: flex;
    justify-content: space-between;
  }
`;

export const BackButtonStyle = styled(Typography)`
  cursor: pointer;
  margin-left: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 0.5}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-left: 27px;
    text-align: right;
    font-size: 14px;
  }
`;

export const ArrowBackIconStyle = styled(ArrowBackIcon)`
  cursor: pointer;
  // margin-top: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  font-size: 20px;
`;

export const LogHeader = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 5px 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;
    margin-bottom: 20px;
  }
`;

export const LogAttendenceContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

export const HeadButton = styled(Button)(
  ({ theme, selected, firstButton }) => ({
    width: "90px",
    backgroundColor: selected ? `${theme.palette.info.blue}` : "transparent",
    color: selected ? "#fff" : theme.palette.text.primary,
    border: `1px solid ${
      selected ? `${theme.palette.info.blue}` : theme.palette.text.primary
    }`,
    textAlign: "center",
    padding: "7px",
    fontSize: "16px",
    borderRadius: firstButton ? "4px 0 0 4px" : "0",
    "&:hover": {
      color: "white",
      backgroundColor: `${theme.palette.info.blue}`,
    },
  })
);

export const HeadTypo = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 5px;
`;

export const ButtonGrid = styled(Grid)`
  display: flex;
  width: 269px;
  align-items: center;
  height: 44px;
  margin-right: 15px;
`;

export const Container = styled(Grid)`
  padding: 0px 16px;
`;

export const StyledContainer = styled(Box)`
  width: 500px;
`;
export const CreateButtonStyling = styled(Button)`
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
export const StyledGrid = styled(Grid)`
  padding: 0px 18px;

  @media (max-width: 600px) {
    padding: 0px 10px;
  }
`;

export const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 3px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledFormBox = styled(Box)`
  width: 100%;

  @media (min-width: 600px) {
    width: 48%;
  }
`;

export const WrapperInput = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputTypo = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  margin-left: 5px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const TextArea = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;

  textarea {
    resize: none;
  }
`;

export const ButtonBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 13px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const MonthFetchAttendence = styled(Grid)`
  display: flex;
  align-self: center;
  align-items: center;
`;

export const DownloadAttendence = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const StatusPresent = styled(Box)`
  color: green;
  text-align: center;
  color: #00c24e;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
export const StatusAbsent = styled(Box)`
  color: green;
  text-align: center;
  color: #ff4b4b;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
export const FormTypo = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
  margin-top: -15px;
`;
export const StatusContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Box)`
  font-size: 10px;
  margin-right: 8px;
  color: ${({ color }) => color};
  text-align: center;
`;

export const StyledText = styled(Typography)`
  color: ${({ color }) => color};
  text-align: center;
`;
export const RequestTypo = styled(Typography)`
  font-size: 20px;
  padding: 10px 5px;
  font-weight: 600;
  line-height: 30px;
  font-family: Inter, Arial, sans-serif;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-left: 22px;
  }
`;

export const StyledCard = styled(Grid)`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  min-height: 100px;
  padding: 24px;
  border-radius: 12px;
  gap: 16px;
  border: 0.5px solid rgb(236, 234, 234);

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    min-width: 220px;
    min-height: 100px;
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.md}px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 5px;
  }
`;

export const IconContainer = styled(Grid)`
  padding: 5px;
  border-radius: 8px;
  border: 2px solid #e0e0e0;
  color: ${({ theme }) => theme.palette.info.blue};
`;

export const ContentContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ContentIconContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  gap: 8px;
  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    // justify-content: space-between;
    gap: 30px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.xl}px) {
    gap: 30px;
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    gap: 10px;
  }
`;

export const CardText = styled(Typography)`
  margin: 5px 0;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  font-family: "Lato", Arial, sans-serif;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    font-size: 30px;
  }
`;

export const CardTitle = styled(Typography)`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Lato", Arial, sans-serif;
`;

export const ChangeText = styled(Typography)`
  font-size: 12px;
  color: ${(props) => props.color || "black"};
`;

export const FilterAttendenceContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0px;
  margin: 0px;

  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-top: 10px;
    margin-left: 10px;
  }
`;

export const FilterAttendence = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-top: 20px;
  }
`;

export const CalendarMonthIconContainer = styled(CalendarMonthOutlinedIcon)`
  font-size: 32px;
  // color: ${({ theme }) => theme.palette.info.blue};
  color: white;
  height: 24px;
  padding: 6px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.info.blue};
  margin-left: 0px;
`;

export const SearchContainer = styled(Grid)`
  margin: 0px;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-top: 30px;
    margin-left: 5px;
  }
`;

export const ProfileContainer = styled("img")`
  height: 35px;
  width: 35px;
  border-radius: 50%;
`;

export const CheckboxLabel = styled(Box)`
  display: flex;
  align-items: center;
  // margin-left: 5px;
  gap: 12px;
`;

export const EmpTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.info.blue};
  font-size: 13px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
`;
