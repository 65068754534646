export const formattedOverView = (overviewData) => {
  if (!Array.isArray(overviewData) || overviewData.length === 0) {
    return [];
  }
  return overviewData.map((overView) => ({
    ...overView,
    id: overView.performanceId,
    quarter: overView.quarter,
    submissionDate: overView.submissionDate,
    comments: overView.comments,
    status: overView.status,
    projects:
      Array.isArray(overView.projects) && overView.projects.length > 0
        ? overView.projects[0]
        : null,
    employeeAverage: overView.employeeAverage,
    managerAverage: overView.managerAverage,
    quarterMonth: overView.quarterMonth,
    year: overView.year,
  }));
};
