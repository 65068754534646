import React from "react";
import Grid from "@mui/material/Grid";
import PersonalInformation from "./PersonalInformation";
import AssignmentInformation from "./AssignmentInformation";
import AssetsInformation from "./AssetsInformation";

const EmployeeInformation = () => {
  return (
    <Grid container spacing={2} style={{ marginTop: 8 }}>
      <Grid item xs={12} sm={12}>
        <PersonalInformation />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container>
          {/* <Grid item xs={12} style={{ marginBottom: 20 }}>
            <AssignmentInformation />
          </Grid> */}
          {/* <Grid item xs={12} style={{ marginBottom: 20 }}>
            <AssetsInformation />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmployeeInformation;
