import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/system";
import LoginIcon from "@mui/icons-material/Login";
import Card from "@mui/material/Card";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

export const StyledCardContentLeft = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => `${props.theme.spacing(1)}`};
  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    padding: 8px 8px;
    height: 220px;
  }
`;

export const TimeContainer = styled(Grid)`
  display: flex;
  align-items: baseline;
`;

export const CheckInCheckOutTime = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const CheckInCheckOutTimeContainer = styled(CheckInCheckOutTime)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${(props) => props.theme.spacing(1)};
`;

export const StyledCardContentRight = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(props) => `${props.theme.spacing(1)}`};
`;

export const StyledFormattedTime = styled(Typography)`
  color: #000000;
  font-weight: 600;
  font-size: 24px;
  font-family: "Inter", sans-serif;
`;

export const StyledSecondsAmPm = styled(Typography)`
  font-size: 12px;
  margin-left: 0.25rem;
`;

export const StyledLoginIcon = styled(LogoutIcon)`
  font-size: 55px;
  margin-left: 1px;
  color: white;
`;

export const StyledLogOutIcon = styled(LoginIcon)`
  font-size: 55px;
  margin-left: 1px;
  color: white;
`;

export const StyledCheckInOutText = styled(Typography)`
  color: white;
  font-weight: bold;
  font-family: "Inter", sans-serif;
`;

export const StyledCheckInText = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
`;

export const StyledCheckInTimeText = styled(Typography)`
  color: black;
  font-weight: bold;
  display: inline;
  font-size: 14px;
`;

export const StyledCheckOutTimeText = styled(Grid)`
 display: "flex", 
 alignItems: "center", 
 marginTop: 1
`;

export const StyledCheckOutIconContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.info.blue};
  border-radius: 10px;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const MonthDayYearText = styled(Typography)`
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
`;

export const AttendanceOverview = styled(Link)`
  color: #0000b3;
  cursor: "pointer";
`;

export const AttendanceOverviewContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const TimeCheckInCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(1)}`};
  border-radius: 8px;
  height: 213px;
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${theme.breakpoints.values.sm}px) {
      height: 33vh;
    }
  `}
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl}px) {
      height: 38vh;
    }
  `}
`;

export const StyledGridContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
