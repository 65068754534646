import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { css } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

export const disableFocusStyles = css`
  background-color: transparent;
  border-color: rgb(229, 231, 235);
  border-style: solid;
  border-width: 1px;
  box-shadow: unset;
`;

export const MuiCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(2)}`};
  border-radius: 8px;
`;

export const LeaveChartCard = styled(MuiCard)(({ theme }) => ({
  height: "100%",
  display: "flex",
  padding: "4px 48px",
  alignItems: "center",
  justifyContent: "space-between",

  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  [`@media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl}px)`]:
    {
      padding: "2px 4px",
    },
}));

export const TimeOutGrid = styled(Grid)`
  padding: 8px 32px;
`;

export const InputLabelRed = styled(InputLabel)`
  color: #ff0000;
`;

export const TimePickerTypography = styled(Grid)`
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => (props.errors ? "#ff0000" : "inherit")};
`;

export const ProgressBarContainer = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BarContainer = styled(Box)`
  margin-right: 1px;
  width: 100%;
`;

export const BoxTextContainer = styled(Box)`
  position: absolute;
  left: 3%;
`;

export const TextContainer = styled(Typography)`
  color: text.primary;
  font-weight: bold;
`;

export const IconContainer = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.info.blue};
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 8px;
  border-radius: 4px;
  align-self: center;
  // margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: ${(props) =>
    `${props?.isTop ? `${props.theme.palette.spacing[1] * 1}px` : "0px"}`};

  &:hover {
    backgroundcolor: ${({ theme }) => theme.palette.info.blue};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-top: ${(props) =>
      `${props?.isTop ? `${props.theme.palette.spacing[0]}px` : "0px"}`};
    margin-left: ${(props) =>
      `${props?.isTop ? `-${props.theme.palette.spacing[1] * 2}px` : "0px"}`};
  }
`;
export const GreenSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#00C24E",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#00C24E",
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#ccc",
  },
});

export const SmallGreenSwitch = styled(Typography)(({ theme }) => ({
  "& .MuiSwitch-root": {
    transform: "scale(0.7)",
  },
  "& .MuiTypography-root": {
    fontSize: "0.7rem",
  },
}));

export const LoaderContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.39);
  z-index: 1300;
`;

export const SpinnerContainer = styled(Box)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
`;

export const SpinnerProgress = styled(CircularProgress)`
  color: #284495;
  position: absolute;
`;

export const SpinnerLogo = styled(Box)`
  width: 40px;
  height: 40px;
  z-index: 1;
  border-radius: 50%;
`;

export const StyledGrid = styled(Grid)`
  height: 100vh;
  // width: 100%;
  // flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => "#d8d8d8" || theme.palette.info.blue};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  &.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledDataGrid = styled(DataGrid)`
  & .MuiDataGrid-virtualScroller {
    overflow-y: auto;
    overflow-x: auto;
    flex: 1 1 auto;
    ${(props) =>
      props.className === "no-scrollbar" &&
      `
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      `}

    ${(props) =>
      props.className === "dark-scrollbar" &&
      `
        &::-webkit-scrollbar {
          width: 8px;
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f1f1f1;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #d8d8d8;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: #111;
        }
      `}
  }
`;
export const MuiCompactMarginTab = styled(Tab)`
  &.MuiButtonBase-root {
    font-size: 17px;
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  }
`;

export const MuiLargeSpacingTab = styled(Tab)`
  &.MuiButtonBase-root {
    font-size: 17px;
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 8}px`};
  }
`;

export const TabContainer = styled(Box)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 1}px ${
      props.theme.palette.spacing[1] * 0
    }px`};
`;

export const StickyTab = styled(Tabs)`
  position: sticky;
  top: ${(props) => `${props.theme.palette.spacing[1] * 9.5}px`};
  z-index: 1;
`;
