import { createSlice } from "@reduxjs/toolkit";
import initialState from "./metaDataIntialState";
import {
  _setBranches,
  _setCoreTechnologies,
  _setCategory,
  _setDepartments,
  _setManagers,
  _setRoles,
  _setFrameWorks,
  _setDesignations,
  _setProjects,
  _setLeaveType,
  _setSkillLevel,
  _setTechnology,
  _setManagerEmployees,
  _setEmploymentType,
  _setUtilization,
  _setProjectList,
  _setUtilizationPercentage,
  _setBillableType,
  _setQuarterMonthList,
  _setTeamQuarterList,
  _setTechnologyByCategory,
  _setLeaveStructure,
  _setAttendanceDayStatus,
} from "./metaDataAction";

export const metaDataSlice = createSlice({
  name: "ep/metaData",
  initialState,
  reducers: {
    setBranches: _setBranches,
    setRoles: _setRoles,
    setManagers: _setManagers,
    setDepartments: _setDepartments,
    setCoreTechnologies: _setCoreTechnologies,
    setCategory: _setCategory,
    setSkillLevel: _setSkillLevel,
    setTechnology: _setTechnology,
    setDesignations: _setDesignations,
    setFrameWorks: _setFrameWorks,
    setProjects: _setProjects,
    setLeaveType: _setLeaveType,
    setManagerEmployees: _setManagerEmployees,
    setEmploymentType: _setEmploymentType,
    setUtilization: _setUtilization,
    setProjectList: _setProjectList,
    setUtilizationPercentage: _setUtilizationPercentage,
    setBillableType: _setBillableType,
    setQuarterMonthList: _setQuarterMonthList,
    setTeamQuarterList: _setTeamQuarterList,
    setTechnologyByCategory: _setTechnologyByCategory,
    setLeaveStructure: _setLeaveStructure,
    setAttendanceDayStatus: _setAttendanceDayStatus,
    resetMetaData: () => initialState,
  },
});
export const {
  setBranches,
  setRoles,
  setManagers,
  setDepartments,
  setCoreTechnologies,
  setCategory,
  setDesignations,
  setFrameWorks,
  setProjects,
  resetMetaData,
  setLeaveType,
  setSkillLevel,
  setTechnology,
  setManagerEmployees,
  setEmploymentType,
  setUtilization,
  setProjectList,
  setUtilizationPercentage,
  setBillableType,
  setQuarterMonthList,
  setTeamQuarterList,
  setTechnologyByCategory,
  setLeaveStructure,
  setAttendanceDayStatus,
} = metaDataSlice.actions;
export default metaDataSlice.reducer;
