import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import { disableFocusStyles } from "./Common.styled";
 
export const FieldSelect = styled(Select)`
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    ${(props) => props.disableFocus && disableFocusStyles};
  }
  & .MuiOutlinedInput-input {
    text-align: left;
  }
  & .MuiSelect-icon {
    ${(props) => props.disableFocus && "display: none;"};
  }
`;
 