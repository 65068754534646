import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import ProgressBar from "../../../Common/ProgressBar";
import { LinearProgress, TextField, Typography } from "@mui/material";

export const FilterItemsContainer = styled(Grid)`
  display: flex;
  justify-content: space-around;
`;

export const AllocateProject = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2.5}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2.5}px`};
`;

export const ClearFilter = styled(Grid)`
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2.5}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-right: ${(props) => `${props.theme.palette.spacing[1] * 6}px`};
  }
`;

export const CheckboxContainer = styled(Checkbox)`
  & .MuiSvgIcon-root {
    display: none;
  }
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 8px;
  border: 2px solid lightgray;

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.info.blue};
    background-color: ${({ theme }) => theme.palette.info.blue};
    border: 2px solid lightgray;
  }
`;

export const RadioContainer = styled(Radio)`
  & .MuiSvgIcon-root {
    display: none;
  }
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 8px;
  align-self: center;
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  border: 2px solid lightgray;

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.info.blue};
    background-color: ${({ theme }) => theme.palette.info.blue};
    border: 2px solid lightgray;
  }
`;

export const LinearProgressComponent = styled(LinearProgress)`
  height: 30px;
  background-color: #cacaca;
  border-radius: 4px;

  & .MuiLinearProgress-bar {
    background-color: ${(props) =>
      Math.round(props.value) < 20 ? "#FFD6D8" : "#D1F7E0"};
    border: 1px solid
      ${(props) => (Math.round(props.value) < 20 ? "#ED1C24" : "#00C24E")};
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
  }
`;

export const AssignMentContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

export const HeadGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const StyledTextField = styled(TextField)({
  marginTop: "5px",
  "& .MuiInputBase-input": {
    fontSize: "70%",
  },
});
export const HistoryContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));

export const TimelineSection = styled(Grid)(({ theme, isIndented }) => ({
  border: "1px solid #ddd",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  display: "flex",
  marginLeft: isIndented ? theme.spacing(2) : 0,
}));

export const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontFamily: "Roboto",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: "0.9rem",
  },
}));

export const DateText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: "Roboto",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: "0.9rem",
  },
}));

export const RowText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontFamily: "Roboto",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: "0.9rem",
  },
}));

export const Dates = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(3),
  fontFamily: "Roboto",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: "0.9rem",
  },
}));

export const ResponsiveTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(1),
  fontFamily: "Roboto",
}));
export const NoDataText = styled(Typography)`
  font-weight: 500;
  font-family: "Roboto";
`;
export const AssignmentGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 5}px`};
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
