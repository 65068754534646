import Grid from "@mui/material/Grid";
import { FormInputDate } from "../../Common/FormDatePicker";
import { LeaveStyledGrid } from "../Leave/components/Leave.styled";
import FormDropDown from "../../Common/FormDropDown";
import { FormTextField } from "../../Common/FormTextField";
import { EmployeePortalActionDailog } from "../../Common/EmployeePortalDialog.styled";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  dropDownOptions,
  formatedZoneDate,
  RenderSaveCancelButtons,
  useNotification,
} from "../../Common/CommonUtils";
import {
  getFrameWorks,
  postAddMySkill,
  putApproveOrRejectSkill,
} from "./services/mySkillService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { dateFnsFormat, requiredField } from "../../Common/Constant";
import useSnackbar from "../../Common/CustomHooks/useSnackbar";
import {
  setTechnology,
  setTechnologyByCategory,
} from "../../Common/store/metaDataSlice/metaDataSlice";
import {
  getTechnology,
  getTechnologyByCategory,
} from "../Login/LoginApiServices/LoginApi";

const MySkillForm = (props) => {
  const { onClose, fetchMySkills } = props;
  const [approveLoading, setApproveLoading] = useState(false);
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const mySkillsForm = useSelector((state) => state?.mySkillsForm?.data);
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    frameWork,
    currentLevel,
    targetLevel,
    comments,
    targetDate,
    skillId,
    // fromDate,
    // status,
  } = mySkillsForm || {};

  const { status } = mySkillsForm || "";

  const [frameWorkOptions, setFrameWork] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);

  const isTechnicalOrQualityAssurance =
    categoryValue === "Process" ||
    categoryValue === "Communication" ||
    categoryValue === "Management";

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const metaData = useSelector((state) => state?.metaData);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const isMySkillsForm = useSelector(
    (state) => state?.mySkillsForm?.isMySkillsForm
  );

  const [technologyValue, setTechnologyValue] = useState(
    mySkillsForm?.technology
  );

  const { category, skillLevel, technologyByCategory } = metaData || {};

  const technologyName = technologyByCategory?.find(
    (item) => item.name === technologyValue
  )?.name;

  const categoryOptionsList = dropDownOptions(category);
  const skillLevelOptionsList = dropDownOptions(skillLevel);
  const technologyByCategoryOptionsList = dropDownOptions(technologyByCategory);
  const frameworkOptionsList = dropDownOptions(frameWorkOptions);

  const skillSchema = yup.object().shape({
    category: yup.string().required(requiredField),
    currentLevel: yup.string().required(requiredField),
    targetlevel: yup.string().required(requiredField),
    targetDate: yup.string().required(requiredField),
    frameWork: !isTechnicalOrQualityAssurance
      ? yup.string().required(requiredField)
      : "",
    technology: !isTechnicalOrQualityAssurance
      ? yup.string().required(requiredField)
      : "",
  });

  const defaultValue = {
    category: mySkillsForm?.category,
    technology: mySkillsForm?.technology,
    frameWork: frameWork,
    currentLevel: currentLevel,
    targetlevel: targetLevel,
    comments: comments,
    targetDate: targetDate ? new Date(formatedZoneDate(targetDate)) : null,
  };

  const { control, register, formState, handleSubmit, resetField } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(skillSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (technologyValue) {
      fetchFrameWork();
    } else {
      setFrameWork([]);
    }
  }, [technologyValue]);

  const fetchFrameWork = async () => {
    try {
      setApproveLoading(true);
      const frameData = await getFrameWorks(technologyName);
      setFrameWork(frameData);
      setApproveLoading(false);
    } catch (error) {
      setApproveLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const onSubmit = async (data) => {
    const {
      category,
      frameWork,
      currentLevel,
      targetlevel,
      comments,
      targetDate,
    } = data;
    if (currentLevel >= targetlevel) {
      setOpenNotification(true);
      setNotificationMessage(
        "Target Level must be greater than Current Level."
      );
      return;
    }
    const payload = {
      userId: Number(employeeId),
      employeeId: Number(employeeId),
      category: category,
      ...(frameWork && { frameWork: frameWork }),
      ...(technologyName && { technology: technologyName }),
      currentLevel: currentLevel,
      targetLevel: targetlevel,
      comments: comments ? comments : "",
      targetDate: format(new Date(targetDate), dateFnsFormat),
    };
    try {
      setApproveLoading(true);
      const MyskillMessage = isMySkillsForm
        ? await postAddMySkill(payload)
        : await putApproveOrRejectSkill(loginUserId, skillId, payload);
      fetchMySkills();
      setApproveLoading(false);
      onClose();
      showSnackbar(MyskillMessage || "Skill Submited successfully");
    } catch (error) {
      setApproveLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const handleCategory = async (e) => {
    const selectedCategory = e.target.value;
    setCategoryValue(selectedCategory);
    const categoriesToFetch = ["Technical", "Quality Assurance"];
    if (categoriesToFetch.includes(selectedCategory)) {
      try {
        const technologyByCategory = await getTechnologyByCategory(
          selectedCategory
        );
        dispatch(setTechnologyByCategory(technologyByCategory));
      } catch (error) {
        setOpenNotification(true);
        setNotificationMessage(
          error.response?.data?.message || "An error occurred"
        );
      }
    }
  };

  return (
    <>
      <LeaveStyledGrid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDropDown
              name="category"
              label="Category"
              control={control}
              errors={errors?.category}
              helperText={errors?.category?.message}
              options={categoryOptionsList}
              readOnly={!isMySkillsForm && !(status?.toLowerCase() === "draft")}
              register={register("category", {
                onChange: (e) => {
                  handleCategory(e);
                },
              })}
            />
            {!isTechnicalOrQualityAssurance && (
              <FormDropDown
                name="frameWork"
                label="Framework"
                control={control}
                errors={errors?.frameWork}
                helperText={errors?.frameWork?.message}
                options={frameworkOptionsList}
                readOnly={
                  !isMySkillsForm && !(status?.toLowerCase() === "draft")
                }
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {!isTechnicalOrQualityAssurance && (
              <FormDropDown
                name="technology"
                label="Technology"
                control={control}
                errors={errors?.technology}
                helperText={errors?.technology?.message}
                options={technologyByCategoryOptionsList}
                register={register("technology", {
                  onChange: (e) => {
                    setTechnologyValue(e.target.value);
                    resetField("frameWork");
                  },
                })}
                readOnly={
                  !isMySkillsForm && !(status?.toLowerCase() === "draft")
                }
              />
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormDropDown
                  name="currentLevel"
                  label="Current Level"
                  control={control}
                  errors={errors?.currentLevel}
                  helperText={errors?.currentLevel?.message}
                  options={skillLevelOptionsList}
                  readOnly={
                    !isMySkillsForm && !(status?.toLowerCase() === "draft")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDropDown
                  name="targetlevel"
                  label="Target Level"
                  control={control}
                  errors={errors?.targetlevel}
                  helperText={errors?.targetlevel?.message}
                  options={skillLevelOptionsList}
                  readOnly={
                    !isMySkillsForm && !(status?.toLowerCase() === "draft")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="Comments"
              name="comments"
              type="reason"
              rows={3}
              multiline
              control={control}
              errors={!!errors?.comments}
              helperText={errors?.comments?.message}
              readOnly={!isMySkillsForm && !(status?.toLowerCase() === "draft")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputDate
              name="targetDate"
              control={control}
              label="Target Date"
              helperText={errors?.targetDate?.message}
              errors={!!errors.targetDate}
              minDate={new Date()}
              readOnly={!isMySkillsForm && !(status?.toLowerCase() === "draft")}
            />
          </Grid>
        </Grid>
      </LeaveStyledGrid>

      <EmployeePortalActionDailog>
        {!(
          (status && status?.toLowerCase() === "approved") ||
          status?.toLowerCase() === "rejected" ||
          status?.toLowerCase() === "pending" ||
          status?.toLowerCase() === "request"
        ) && (
          <RenderSaveCancelButtons
            handleCancel={onClose}
            handleSubmit={handleSubmit(onSubmit)}
            buttonLoading={approveLoading}
          />
        )}
      </EmployeePortalActionDailog>
      <NotificationPopup />
    </>
  );
};

export default MySkillForm;
