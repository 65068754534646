import React, { useEffect, useState } from "react";
import {
  useSideNav,
  useNotification,
  useLoader,
} from "../../../Common/CommonUtils";
import { getGrowthData } from "../services/PerformanceServices";
import { useDispatch, useSelector } from "react-redux";
import { formattedGrowth } from "./MyGrowthDatagridUtils";
import {
  Dates,
  DateText,
  LabelText,
  NoDataText,
  ResponsiveTitle,
  RowText,
  TimelineSection,
} from "../../MyAssignment/Components/MyAssignment.styled";
import {
  GrowthContainer,
  CircleContainer,
  GrowIcon,
  IconGrid,
  GrowthGrid,
  MyGrowthGrid,
} from "./MyPerformance.styled";
import Grid from "@mui/material/Grid";

const MyGrowth = () => {
  useSideNav();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const { setLoading, LoaderPopup } = useLoader();
  const [growthData, setGrowthData] = useState([]);
  const userId = useSelector((state) => state.user?.data?.employeeId);
  const employeeId = useSelector((state) => state.user?.data?.employeeId);

  useEffect(() => {
    fetchGrowthData();
  }, []);

  const fetchGrowthData = async () => {
    try {
      setLoading(true);
      const growthHistory = await getGrowthData(employeeId, userId);
      const growthInfo = formattedGrowth(growthHistory?.data);
      setGrowthData(growthInfo);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };
  const sortedGrowthData = growthData.sort((a, b) => {
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    return dateB - dateA;
  });

  return (
    <>
      {sortedGrowthData.length > 0 ? (
        <>
          <ResponsiveTitle gutterBottom>Growth Timeline</ResponsiveTitle>
          <GrowthContainer mt={4}>
            {sortedGrowthData.map((project, index) => (
              <GrowthGrid container key={project.id}>
                <IconGrid
                  container
                  xs={2}
                  sm={1}
                  index={index}
                  length={sortedGrowthData.length}
                >
                  <CircleContainer>
                    <GrowIcon />
                  </CircleContainer>
                </IconGrid>

                <Grid container xs={10} sm={11}>
                  <Grid container>
                    <MyGrowthGrid item xs={12} sm={6} md={4} container>
                      <DateText>Growth Revision</DateText>
                      <Dates>Effective {project.startDate}</Dates>
                    </MyGrowthGrid>
                  </Grid>

                  <GrowthGrid container mt={1}>
                    <TimelineSection
                      container
                      xs={10}
                      sm={6}
                      md={4}
                      lg={4}
                      isIndented={true}
                    >
                      <Grid item xs={12}>
                        <LabelText>Designation:</LabelText>
                        <RowText>{project.promotion}</RowText>
                      </Grid>
                    </TimelineSection>
                  </GrowthGrid>
                </Grid>
              </GrowthGrid>
            ))}
          </GrowthContainer>
        </>
      ) : (
        <GrowthGrid container content="center" mt={34}>
          <NoDataText>No growth records found</NoDataText>
        </GrowthGrid>
      )}
      <LoaderPopup />
      <NotificationPopup />
    </>
  );
};

export default MyGrowth;
