import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import LensIcon from "@mui/icons-material/Lens";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

export const PerformanceContainer = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 4px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  }
`;

export const PerformanceTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(2.5)}px;
  font-family: "Roboto", sans-serif;
  font-weight: lighter;
  margin-right: 8px;

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    font-size: 12px;
  }
`;

export const PerformanceValue = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(2.2)}px;
  font-family: "Roboto", sans-serif;
  font-weight: 800;

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    font-size: 12px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.xl}px) {
    font-size: 15.5px;
  }
`;

export const PerformanceColumn = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(2.2)}px;
  font-family: "Roboto", sans-serif;
  font-weight: 480;
`;

export const Performance = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PerformanceNameContainer = styled(PerformanceContainer)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 9}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) {
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.values.xl}px) {
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
  }
`;

export const PerformanceListItem = styled(Grid)`
  display: flex;
  // justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;

  // @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
`;

export const QuaterText = styled(Grid)`
  color: #01008a;
  font-weight: 700;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2.5}px`};
`;
export const DateText = styled(Typography)`
  color: #b0b0b0;
  font-weight: 500;
  font-size: 12px;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2.5}px`};
`;

export const OverviewGrid = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
  padding-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const StatusGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledLensIcon = styled(LensIcon)(({ color }) => ({
  color: color,
  fontSize: "10px",
}));

export const StyledTypography = styled(Typography)(({ color }) => ({
  marginLeft: "8px",
  color: color,
}));

export const GrowthContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(1),
}));

export const CircleContainer = styled(Grid)`
  width: 40px;
  height: 40px;
  background-color: #0000a0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const GrowIcon = styled(TrendingUpIcon)`
  color: white;
  font-size: 20px;
`;

export const IconGrid = styled(Grid)(({ theme, index, length }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&::after": {
    content: '""',
    position: "absolute",
    width: "1px",
    backgroundColor: theme.palette.grey[400],
    height: "calc(150% + 16px)",
    top: "70%",
    zIndex: -1,
    display: index === length - 1 ? "none" : "block",
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    marginTop: theme.spacing(4),
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    marginTop: theme.spacing(3),
  },
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    marginTop: theme.spacing(2),
  },
  [`@media (min-width: ${theme.breakpoints.values.xs}px)`]: {
    marginTop: theme.spacing(3),
  },
}));

export const GrowthGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: ${({ content }) => content || "flex-start"};
  margin-left: "16px";
`;

export const MyGrowthGrid = styled(Grid)`
  display: flex;
  align-items: center;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
