import React, { useState } from "react";
import { getHolidays } from "../services/LeaveApi";
import {
  useLoader,
  useNotification,
  usePermissionCustomHook,
} from "../../../Common/CommonUtils";
import { Grid, ListItemText } from "@mui/material";
import {
  StyledHolidayListItem,
  HolidayTitle,
  NoHolidaysContainer,
  NoHolidaysTitle,
  StyledGrid,
  HolidayListItem,
  HolidayListContainer,
} from "./Leave.styled";
import FormDropDown from "../../../Common/FormDropDown";
import { generateYearsOptions } from "../../../Common/Constant";
import { FormInputSearch } from "../../../Common/FormInputSearch";
import { useForm } from "react-hook-form";
import useDebounceEffect from "../../../Common/CustomHooks/useDebounceEffect";
import { AttendanceMuiCard } from "../../Dashboard/Components/Dashboard.styled";
import { useSelector } from "react-redux";

const HolidayList = () => {
  const { NotificationPopup } = useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const location = useSelector((state) => state.user?.data?.location);
  const [searchQuery, setSearchQuery] = useState(location || "Bangalore");
  const [year, setYear] = useState(new Date().getFullYear());
  const yearOptions = generateYearsOptions();

  const [holidayDetails, setHolidayDeatils] = useState({
    isLoading: false,
    rows: [],
  });
  const isHR = usePermissionCustomHook("HR");

  const defaultValue = {
    year: new Date().getFullYear(),
  };
  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { control, formState, register } = useFunction;
  const { errors } = formState;

  useDebounceEffect(
    () => {
      fetchHolidayData();
    },
    500,
    [year, searchQuery]
  );

  const fetchHolidayData = async () => {
    try {
      setLoading(true);
      setHolidayDeatils({ ...holidayDetails, isLoading: true });
      const holidays = await getHolidays(year, searchQuery);
      setHolidayDeatils({ rows: holidays, isLoading: false });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setHolidayDeatils({ ...holidayDetails, rows: [], isLoading: false });
    }
  };

  const isPastHoliday = (holidayDate) => {
    const today = new Date();
    const holiday = new Date(holidayDate);
    return holiday < today;
  };

  return (
    <>
      <AttendanceMuiCard isHR={isHR}>
        <HolidayListItem container spacing={2}>
          <Grid item xs={12} sm={5}>
            <HolidayTitle>Holiday List</HolidayTitle>
          </Grid>
          <Grid item xs={12} sm={7}>
            <HolidayListContainer container spacing={1}>
              <Grid item xs={12} sm={7} md={7}>
                <FormInputSearch
                  searchQuery={searchQuery}
                  fullWidth
                  setSearchQuery={setSearchQuery}
                  placeholder="Search"
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <FormDropDown
                  name="year"
                  label="Year"
                  control={control}
                  options={yearOptions}
                  errors={!!errors.year}
                  helperText={errors.year?.message}
                  register={register("year", {
                    onChange: (e) => {
                      setYear(e.target.value);
                    },
                  })}
                />
              </Grid>
            </HolidayListContainer>
          </Grid>
        </HolidayListItem>
        {holidayDetails.rows.length > 0 ? (
          <>
            <StyledGrid className="dark-scrollbar" dense>
              {holidayDetails.rows.map((holiday) => (
                <StyledHolidayListItem
                  key={holiday.holiday_id}
                  isPast={isPastHoliday(holiday.date)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <ListItemText primary={holiday.day} />
                    </Grid>
                    <Grid item xs={3}>
                      <ListItemText primary={holiday.date} />
                    </Grid>
                    <Grid item xs={3}>
                      <ListItemText primary={holiday.holiday_name} />
                    </Grid>
                    <Grid item xs={3}>
                      <ListItemText primary={holiday.description} />
                    </Grid>
                  </Grid>
                </StyledHolidayListItem>
              ))}
            </StyledGrid>
          </>
        ) : (
          <NoHolidaysContainer>
            <NoHolidaysTitle variant="h6">No Holiday's Found</NoHolidaysTitle>
          </NoHolidaysContainer>
        )}
      </AttendanceMuiCard>
      <LoaderPopup />
      <NotificationPopup />
    </>
  );
};

export default HolidayList;
