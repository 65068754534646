import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import { items } from "./SideNavbarConfig";
import { SideNavbarItem } from "./SideNavbarItem";
import IconButton from "@mui/material/IconButton";
import HynivaLogo from "../../Assets/Images/HynivaLogo.png";
import HynivaArrowLogo from "../../Assets/Images/HynivaLogoArrow.png";
import {
  HynivaArrowLogoContainer,
  HynivaNavLogo,
  HynivaNavLogoStyle,
  LogoArrowContainer,
  LogoContainer,
  NavigationContainer,
  SideGridNav,
  SideNavGrid,
} from "./SideNavbar.Styled";
import { usePermissionCustomHook } from "../../Common/CommonUtils";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export const SideNavbar = (props) => {
  const {
    activePath,
    handleActivePath,
    isMobileNavbar,
    setIsMobileNavbar,
    isMiniNavbar,
    setIsMiniNavbar,
  } = props;
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");
  const isAccountant = usePermissionCustomHook("Accountant");
  const hideInvoice = isHR || isDeveloper || isManager;

  let filteredItems = items;

  if (hideInvoice) {
    filteredItems = items.filter((item) => item.title !== "Invoice");
  }
  if (isHR) {
    filteredItems = items.filter(
      (item) => item.title !== "Attendance" && item.title !== "Performance"
    );
  } else if (isAccountant) {
    filteredItems = items.filter((item) => item.title === "Invoice");
  }

  filteredItems = filteredItems.filter((item) => {
    if (
      item.title === "Employees" ||
      item.title === "Employee CheckIn" ||
      item.title === "Attendance Request" ||
      item.title === "My Action"
    ) {
      return isHR;
    }
    return true;
  });

  const handleMenu = () => {
    setIsMiniNavbar((prve) => !prve);
  };

  const handleCloseNavebar = () => {
    setIsMobileNavbar(false);
  };

  const content = (
    <SideGridNav container direction="column">
      {isWeb ? (
        <Grid style={{ padding: "0px 16px" }}>
          <IconButton
            color="#284495"
            aria-label="open drawer"
            edge="start"
            onClick={handleMenu}
          >
            {isMiniNavbar ? (
              <LogoArrowContainer item>
                <HynivaArrowLogoContainer src={HynivaArrowLogo} />
              </LogoArrowContainer>
            ) : (
              <LogoContainer item>
                <HynivaNavLogo src={HynivaLogo} />
              </LogoContainer>
            )}
          </IconButton>
        </Grid>
      ) : (
        <LogoContainer item>
          <HynivaNavLogoStyle src={HynivaLogo} />
        </LogoContainer>
      )}
      <NavigationContainer item flexGrow={1}>
        <SideNavGrid component="ul" spacing={0.5}>
          {filteredItems.map((item) => {
            const active = item.path ? activePath === item.path : false;
            return (
              <SideNavbarItem
                active={active}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.path}
                title={item.title}
                handleActivePath={handleActivePath}
                isMiniNavbar={isMiniNavbar}
                setIsMiniNavbar={setIsMiniNavbar}
                isMobileNavbar={isMobileNavbar}
                setIsMobileNavbar={setIsMobileNavbar}
              />
            );
          })}
        </SideNavGrid>
      </NavigationContainer>
    </SideGridNav>
  );

  return (
    <>
      {isWeb && (
        <Drawer
          anchor="left"
          open={isMiniNavbar}
          onClose={handleMenu}
          PaperProps={{
            sx: {
              color: "#345196",
              width: isMiniNavbar ? 69 : 250,
              overflowY: "hidden",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              transition: "width 0.3s ease",
            },
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      )}
      {isMobile && (
        <Drawer
          anchor="left"
          open={isMobileNavbar}
          onClose={handleCloseNavebar}
          PaperProps={{
            sx: {
              backgroundColor: "common.white",
              color: "#345196",
              width: 250,
              overflowY: "unset",
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      )}
    </>
  );
};
