import { Popover } from "@mui/material";
import { useState } from "react";
import { DayCalendar } from "./CalenderMonthWeek";

const useWeekDetails = (tempData) => {
  const [weekData, setWeekData] = useState([]);
  const [weekDetails, setWeekDetails] = useState("");
  const [weekStartDate, setWeekStartDate] = useState("");
  const [weekEndDate, setWeekEndDate] = useState("");
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const getWeekDates = (selectedDate) => {
    const weekDates = [];
    const date = new Date(selectedDate);
    const dayOfWeek = date.getDay();
    date.setDate(date.getDate() - ((dayOfWeek + 6) % 7));

    for (let i = 0; i < 7; i++) {
      const current = new Date(date);
      current.setDate(date.getDate() + i);
      const formatted = `${current.getDate()}-${
        current.getMonth() + 1
      }-${current.getFullYear()}`;
      weekDates.push(formatted);
    }
    return weekDates;
  };

  const parseDate = (dateString) => {
    const [month, day, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };

  const getWorkingHoursForWeek = (start, end, tempData) => {
    const result = [];
    const startDate = parseDate(start);
    const endDate = parseDate(end);

    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      const formattedDate = `${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()}`;

      const match = tempData.find((item) => item.date === formattedDate);
      result.push({
        date: formattedDate,
        workingHours:
          match?.workedHours === null
            ? 0
            : match?.workedHours === undefined
            ? 0
            : match?.workedHours,
      });
    }

    return result;
  };

  const formatWeekRange = (dates) => {
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[dates.length - 1]);
    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}-${day}-${year}`;
    };
    const formattedStart = formatDate(startDate);
    const formattedEnd = formatDate(endDate);
    setWeekStartDate(formattedStart);
    setWeekEndDate(formattedEnd);
    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleDateChange = (date) => {
    const weekDates = getWeekDates(date);
    const MMDDYYYYDates = weekDates.map((date) => {
      const [day, month, year] = date.split("-");
      const paddedDay = day.padStart(2, "0");
      const paddedMonth = month.padStart(2, "0");
      return `${paddedMonth}-${paddedDay}-${year}`;
    });

    const workingHoursForWeek = getWorkingHoursForWeek(
      MMDDYYYYDates[0],
      MMDDYYYYDates[MMDDYYYYDates.length - 1],
      tempData
    );

    const weekHours = workingHoursForWeek.map((item) => {
      const workingHours = item.workingHours;

      if (typeof workingHours === "string" && workingHours.includes(":")) {
        const [hours, minutes] = workingHours.split(":").map(Number);
        if (!isNaN(hours) && !isNaN(minutes)) {
          const decimalMinutes = (minutes / 60) * 100;
          return Number((hours + decimalMinutes / 100).toFixed(2));
        }
        return 0;
      }
      return typeof workingHours === "number" ? workingHours : 0;
    });

    setWeekData(weekHours);
    const weekRange = formatWeekRange(MMDDYYYYDates);
    setWeekDetails(weekRange);
  };

  const handleCalendarIconClick = (event) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchor(null);
  };

  // const open = Boolean(calendarAnchor);

  // const WeekDetailsPopover = () => (
  //   <Popover
  //     open={open}
  //     anchorEl={calendarAnchor}
  //     onClose={handleCalendarClose}
  //   >
  //     <DayCalendar onDateSelect={() => handleDateChange()} />
  //   </Popover>
  // );

  return {
    weekData,
    weekDetails,
    weekStartDate,
    weekEndDate,
    calendarAnchor,
    setWeekStartDate,
    setWeekEndDate,
    handleDateChange,
    // WeekDetailsPopover,
    handleCalendarIconClick,
    handleCalendarClose,
  };
};

export default useWeekDetails;
