import React from "react";
import { useSideNav } from "../../../../Common/CommonUtils";
import HrManagerTimesheetTab from "./HrManagerTimesheetTab";
import HrAdminView from "./HrAdminView";
import { useSelector, useDispatch } from "react-redux";
const HrManagerTimesheet = () => {
  useSideNav();

  const designation = useSelector((state) => state.user?.data?.designation);
  const role = useSelector((state) => state.user?.data?.role);
  return (
    <>
      {designation === "HR Admin" && role === "HR" ? (
        <HrAdminView />
      ) : (
        <HrManagerTimesheetTab />
      )}
    </>
  );
};

export default HrManagerTimesheet;
