import styled from "@emotion/styled";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

export const SidenavMainGrid = styled(Grid)`
  display: flex;
  align-items: center;
  min-height: 64px;
  padding: 0px 24px;
  margin-bottom: 16px;
  justify-content: space-between;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const StyledGridItem = styled(Grid)`
  padding: 0 24px;
`;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  height: 40px;
  width: 40px;
`;

export const StyledButtonBase = styled(ButtonBase)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  ${(props) =>
    props.isMiniNavbar && props.isWeb ? `width: 54px` : `width: 100%`};
  transition: width 0.3s ease;
  &:hover {
    background-color: neutral.400;
  }
`;

export const IconContainer = styled(Box)`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-right: 16px;
`;

export const TitleContainer = styled(Box)`
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  margin-left: 5px;
`;

export const LogoContainer = styled(Grid)`
  // padding: 24px;
  marign: 8px;
`;

export const LogoArrowContainer = styled(Grid)`
  padding-right: 14px;
  marign-bottom: 18px;
  // margin-right: 20px;
`;

export const NavigationContainer = styled(Grid)`
  flex-grow: 1;
  padding: 0px 8px;
`;
export const HynivaNavLogoStyle = styled("img")`
  height: 75px;
`;

export const HynivaArrowLogoContainer = styled("img")`
  height: 50px;
`;

export const HynivaNavLogo = styled("img")`
  height: 55px;
`;

export const SideGridNav = styled(Grid)`
  height: 100%;
`;
export const SideNavGrid = styled(Grid)`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const BackButtonGrid = styled(Grid)`
  display: flex;
`;

export const MenuIconButton = styled(Grid)`
  display: flex;
  align-items: start;
  justify-content: start;
`;
