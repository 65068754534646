import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { SegmentedProgressBar } from "../../../../Common/SegmentedProgressBar";
import { NoHolidaysContainer } from "../../../Leave/components/Leave.styled";
import {
  CardHeading,
  EmployeeStaffTpo,
  JobHeading,
  NumTypo,
  CommonGrid,
  StyledBox,
  RightGrid,
  ValueHeading,
  StyledTypography,
  ProgressGrid,
  MiddleGrid,
  LastGrid,
  JobMuiCard,
  DetailedIcon,
  InvalidData,
} from "../Dashboard.styled";

const headcounts = {
  staff: 120,
  InternshipAndContract: 20,
  CeoAndDirector: 10,
};

const colors = {
  staff: "#00C24E",
  InternshipAndContract: "#849CD5",
  CeoAndDirector: "#01008A",
};

const data = [
  {
    bgColor: "#4CAF50",
    jobTitle: "Staff",
    value: "120",
    percentage: "70%",
  },
  {
    bgColor: "#FF9800",
    jobTitle: "Internship and Contract",
    value: "20",
    percentage: "20%",
  },
  {
    bgColor: "#2196F3",
    jobTitle: "CEO and Director",
    value: "10",
    percentage: "10%",
  },
];

const HrAdminJobLevel = () => {
  const totalHeadcount = Object.values(headcounts).reduce(
    (sum, count) => sum + count,
    0
  );

  const isDataAvailable = totalHeadcount > 0 && data.length > 0;

  return (
    <JobMuiCard>
      <CommonGrid>
        <CardHeading>Company Job Level</CardHeading>
        <DetailedIcon />
      </CommonGrid>
      {isDataAvailable ? (
        <>
          <ProgressGrid>
            <Grid item xs={12}>
              <SegmentedProgressBar
                value={100}
                data={headcounts}
                colorMapping={colors}
              />
            </Grid>
          </ProgressGrid>

          <MiddleGrid>
            <NumTypo>{totalHeadcount}</NumTypo>
            <EmployeeStaffTpo>Total employer and staff</EmployeeStaffTpo>
          </MiddleGrid>

          <Divider />

          {data.map((item, index) => (
            <LastGrid key={index}>
              <CommonGrid>
                <StyledBox bgColor={item.bgColor} />
                <JobHeading>{item.jobTitle}</JobHeading>
              </CommonGrid>
              <RightGrid>
                <ValueHeading>{item.value}</ValueHeading>
                <StyledTypography>{item.percentage}</StyledTypography>
              </RightGrid>
            </LastGrid>
          ))}
        </>
      ) : (
        <NoHolidaysContainer>
          <InvalidData>No organizational data available.</InvalidData>
        </NoHolidaysContainer>
      )}
    </JobMuiCard>
  );
};

export default HrAdminJobLevel;
