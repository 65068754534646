import React, { useState } from "react";
import TabPanel, { a11yProps } from "../../../../Common/TabPanel/TabPanel";
import {
  StickyTab,
  TabContainer,
  MuiCompactMarginTab,
  MuiLargeSpacingTab,
} from "../../../../Common/Common.styled";
import MyGrowth from "../MyGrowth";
import PerformanceOverview from "../PerformanceOverview";
import TeamPerformance from "./TeamPerformance";
import { usePermissionCustomHook } from "../../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import { resetPage } from "../../../../Common/store/paginationSlice/paginationSlice";
import { setTabValue } from "../../../../Common/store/tabSlice/tabSlice";

const HrManagerPerformanceTab = () => {
  const isManager = usePermissionCustomHook("Manager");
  const dispatch = useDispatch();
  const tabValue = useSelector((state) => state.tabInfo?.tabValue);

  const handleTabChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
    dispatch(resetPage());
  };

  return (
    <>
      <TabContainer>
        <StickyTab
          value={tabValue}
          variant="scrollable"
          scrollButtons={false}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          <MuiCompactMarginTab label="Overview " {...a11yProps(0)} />
          {isManager && (
            <MuiLargeSpacingTab label="Team Performance" {...a11yProps(1)} />
          )}

          <MuiLargeSpacingTab label="My Growth" {...a11yProps(2)} />
        </StickyTab>
      </TabContainer>

      <TabPanel value={tabValue} index={0}>
        <PerformanceOverview />
      </TabPanel>
      {isManager && (
        <TabPanel value={tabValue} index={1}>
          <TeamPerformance />
        </TabPanel>
      )}

      <TabPanel value={tabValue} index={isManager ? 2 : 1}>
        <MyGrowth />
      </TabPanel>
    </>
  );
};

export default HrManagerPerformanceTab;
