import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  BoxButtonGrid,
  BoxHeading,
  EditIconStyle,
  LeftSectionMainBox,
  LeftSectionMainGrid,
} from "./CreateInvoice.Styled";
import { useDispatch, useSelector } from "react-redux";
import { setCustomer } from "../store/invoiceInfoFormSlice/invoiceInfoFormSlice";
import {
  renderButtonSpinner,
  useLoader,
  useNotification,
} from "../../../Common/CommonUtils";
import { getCustomer } from "../services/invoiceApi";

const CreateInvoiceLeftSection = (props) => {
  const { customers } = props;

  const dispatch = useDispatch();

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const [loadingButton, setLoadingButton] = useState(false);

  const customerDetails = useSelector(
    (state) => state.invoiceInfoForm?.customer?.data?.[0]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddCustomerClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCustomerClick = async (event, customer) => {
    try {
      setLoading(true);
      setLoadingButton(true);
      const customerData = await getCustomer(customer?.customerId);
      dispatch(setCustomer(customerData));
      setLoadingButton(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      setLoadingButton(false);
    }

    setAnchorEl(null);
  };

  const handleResetCustomer = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <LeftSectionMainGrid
        container
        direction="column"
        alignItems="flex-start"
        data-testId="invoice-customer-grid"
      >
        <LeftSectionMainBox
          customerdetails={customerDetails}
          data-testId="invoice-customer-box"
        >
          {customerDetails && (
            <Grid data-testId="invoice-customer-details">
              <BoxHeading variant="subtitle1">
                {customerDetails?.customerName}
              </BoxHeading>
              <Typography variant="body2">
                {customerDetails?.organizationName}
              </Typography>
              <Typography variant="body2">
                {customerDetails?.addressLine_1}
              </Typography>
              <Typography variant="body2">
                {customerDetails.addressLine_2}
              </Typography>

              <Typography variant="body2">{`${customerDetails?.city}, ${customerDetails?.state} - ${customerDetails?.zipCode}`}</Typography>
              <Typography variant="body2">{customerDetails?.email}</Typography>
              <Typography variant="body2">
                {customerDetails?.gstNumber}
              </Typography>
              <BoxButtonGrid
                container
                data-testId="invoice-choose-customer-grid"
              >
                {renderButtonSpinner(
                  loadingButton,
                  "Choose a different Customer",
                  handleResetCustomer,
                  "text",
                  <EditIconStyle data-testId="invoice-customer-edit-icon" />
                )}
              </BoxButtonGrid>
            </Grid>
          )}
          {!customerDetails &&
            renderButtonSpinner(
              loadingButton,
              "Choose Customer+",
              handleAddCustomerClick,
              "text"
            )}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            data-testId="invoice-customer-menu"
          >
            {customers?.data?.map((customer, index) => (
              <MenuItem
                key={index}
                onClick={(event) => handleCustomerClick(event, customer)}
                data-testId={`invoice-customer-options-${customer.customerName}`}
              >
                {customer.customerName}
              </MenuItem>
            ))}
          </Menu>
        </LeftSectionMainBox>
      </LeftSectionMainGrid>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default CreateInvoiceLeftSection;
