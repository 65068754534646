import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { StyledAvatar } from "../../SideNavbar/SideNavbar.Styled";
import { Button, Typography } from "@mui/material";

export const ViewDetailsPageGrid = styled(Grid)`
  display: flex;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const ViewDetailsPageAvatar = styled(StyledAvatar)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-top: 10px;
`;

export const ViewDetailsPageCard = styled(Card)`
  padding: 16px 24px;
  height: fit-content;
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;

export const EmployeeNameStyling = styled(Typography)`
  display: flex;
  justify-content: flex-start;
`;

export const EmployeeCodeStyling = styled(Typography)`
  color: ${(props) => props.theme.palette.text.Tertiory};
`;

export const ViewDetailsPageNameGrid = styled(Grid)`
  padding: 4px;
  display: flex;
`;

export const ViewDetailsPageButtonGrid = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const EmployeeNameMainGrid = styled(Grid)`
  display: flex;
`;

export const ViewDetailsPageEditButton = styled(Button)`
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  margin-right: ${(props) => `${props.theme.palette.spacing[0] * 0.5}px`};
`;

export const ViewDetailsPageActionButton = styled(Button)`
  height: 26px;
  width: 20px;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
`;

export const ViewDtailsCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(2)}`};
`;

export const HorizontalLineStyling = styled("hr")`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 8.5}px`};
`;
