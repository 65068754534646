import React from "react";
import PropTypes from "prop-types";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import HynivaLogo from "../../Assets/Images/HynivaLogo.png";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import EmployeeAvatar from "../../Assets/Images/avatar-anika-visser.png";
import { usePopover } from "../../Hooks/usePopover";
import { AccountPopover } from "./AccountPopover";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import {
  HynivaNavLogoStyle,
  LogoContainer,
  MenuIconButton,
  SidenavMainGrid,
  StyledAvatar,
  StyledGridItem,
} from "./SideNavbar.Styled";
import BackButtonComponent from "../../Common/BackButtonComponent/BackButtonComponent";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { LayoutRoot } from "../../Routes/EmployeePortalConfig.Styled";

const TopNav = (props) => {
  const { setIsMobileNavbar } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("md"));
  const EmployeeDetails = useSelector(
    (state) => state?.EmployeeDetails?.isViewDetailsPage
  );
  const accountPopover = usePopover();

  const handleMenu = () => {
    setIsMobileNavbar((prve) => !prve);
  };

  return (
    <>
      <SidenavMainGrid container>
        <MenuIconButton item xs={6} spacing={2}>
          {/* <LogoContainer item>
        <HynivaNavLogoStyle src={HynivaLogo} />
      </LogoContainer> */}
          {isMobile && (
            <IconButton
              color="#284495"
              aria-label="open drawer"
              edge="start"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
          )}
          {EmployeeDetails ? (
            <LayoutRoot item xs={3} isSideNav={true} isTab={!isTab}>
              <BackButtonComponent />
            </LayoutRoot>
          ) : (
            <Grid item xs={3}></Grid>
          )}
        </MenuIconButton>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <StyledGridItem item spacing={2}>
            <Tooltip title="Notifications">
              <IconButton>
                <Badge badgeContent={4} color="success" variant="dot">
                  <SvgIcon fontSize="small">
                    <NotificationsIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
          </StyledGridItem>
          <StyledAvatar
            onClick={accountPopover.handleOpen}
            ref={accountPopover.anchorRef}
            src={EmployeeAvatar}
          />
        </Grid>
      </SidenavMainGrid>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

TopNav.propTypes = {
  onNavOpen: PropTypes.func,
};

export default TopNav;
