import React, { useState } from "react";
import DataGridComponent from "../../../.././Common/DataGridComponent";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useLoader,
  useNotification,
  useSideNav,
} from "../../../.././Common/CommonUtils";
import { generateYearsOptions } from "../../../../Common/Constant";
import {
  getMyPerformanceOverview,
  getTeamPerformance,
} from "../../services/PerformanceServices";
import { getMTeamPerformanceDataGridUtils } from "./TeamPerformanceDatagridUtils";
import {
  QuaterText,
  DateText,
  OverviewGrid,
  StatusGrid,
  StyledLensIcon,
  StyledTypography,
} from "../MyPerformance.styled";
import {
  setMyPerformance,
  setPerformanceId,
  setIsTeamPerformance,
} from "../../store/PerformanceSlice";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { FormInputSearch } from "../../../../Common/FormInputSearch";
import FormDropDown from "../../../../Common/FormDropDown";
import { useTheme } from "@emotion/react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ClearFilter } from "../../../MyAssignment/Components/MyAssignment.styled";
import ClearFilterButton from "../../../Employees/ClearFilterButton";
import useDebounceEffect from "../../../../Common/CustomHooks/useDebounceEffect";
import { useNavigate } from "react-router-dom";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";
const TeamPerformance = () => {
  useSideNav();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [teamPerformance, setTeamPerformance] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const userId = useSelector((state) => state.user?.data?.employeeId);
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const yearOptions = generateYearsOptions();
  const [optionDropdown, setOptionDropdown] = useState(
    yearOptions[yearOptions.length - 1]?.value
  );
  const metaData = useSelector((state) => state?.metaData);

  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = teamPerformance;
  const { teamQuarterList } = metaData || [];

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: item.quarter,
      value: item.quarter,
      quarterlyId: item.quarterlyId,
    })) || [];

  const teamData = [...teamQuarterList].sort(
    (a, b) => a.quarterlyId - b.quarterlyId
  );
  const quarter = mapToOptionsList(teamData);

  const [quarterData, setQuarterData] = useState(quarter[0]?.value);

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: {
      quarter: quarter[0]?.value,
      optionDropdown: yearOptions[yearOptions.length - 1]?.value,
    },
  });

  const { control, reset, register } = useFunction;

  const columnData = [
    {
      field: "name",
      headerName: "Employee Name",
      width: 210,
      renderCell: (params) => (
        <Grid container direction="column">
          <QuaterText>{params.row.employeeName}</QuaterText>
          <DateText>{params.row.designation}</DateText>
        </Grid>
      ),
    },
    { field: "projects", headerName: "Project", width: 200 },
    {
      field: "quarter",
      headerName: "Quarter",
      width: 200,
      renderCell: (params) => (
        <Grid container direction="column">
          <QuaterText>{params.row.quarter}</QuaterText>
          <DateText>{params.row.quarterMonth + " " + params.row.year}</DateText>
        </Grid>
      ),
    },
    { field: "employeeAverage", headerName: "Employee rating", width: 200 },
    { field: "managerAverage", headerName: "Manager rating", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const formattedStatus = capitalize(params?.row?.status);
        const value =
          formattedStatus === "Reviewed"
            ? theme.palette.success.main
            : theme.palette.warning.main;
        return (
          <StatusGrid>
            <StyledLensIcon color={value} />
            <StyledTypography color={value}>{formattedStatus}</StyledTypography>
          </StatusGrid>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const { status } = params.row;

        const statusValue = status.toLowerCase();

        const iconAction =
          statusValue === "pending" ? (
            <EditIcon onClick={() => handleEditOverview(params)} />
          ) : statusValue === "reviewed" ? (
            <VisibilityIcon onClick={() => handleEditOverview(params)} />
          ) : null;
        return <Button variant="text">{iconAction}</Button>;
      },
    },
  ];

  useDebounceEffect(
    () => {
      fetchTeamPerformance();
    },
    500,
    [searchQuery, optionDropdown, quarterData, page, pageSize]
  );

  const fetchTeamPerformance = async () => {
    try {
      setLoading(true);
      setTeamPerformance({ ...teamPerformance, isLoading: true });
      const params = {
        userId: userId,
        ...(searchQuery && { employeeName: searchQuery }),
        quarter: quarterData,
        year: optionDropdown,
        page: page,
        limit: pageSize,
      };

      const getPerformanceDetails = getTeamPerformance(params);
      const employeeDetailsList = await getPerformanceDetails;
      const formattedPerformanceData = getMTeamPerformanceDataGridUtils(
        employeeDetailsList?.data
      );
      setTeamPerformance((prev) => ({
        ...prev,
        rows: formattedPerformanceData,
        totalItems: employeeDetailsList?.pagination?.totalItems,
        isLoading: false,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setTeamPerformance({ ...teamPerformance, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
      setTeamPerformance((prev) => ({
        rows: [],
      }));
    }
  };

  const isFilterActive =
    searchQuery !== "" ||
    optionDropdown !== yearOptions[yearOptions.length - 1]?.value ||
    quarterData !== quarter[0]?.value;

  const clearSelection = () => {
    setSearchQuery("");
    setOptionDropdown(yearOptions[yearOptions.length - 1]?.value);
    setQuarterData(quarter[0]?.value);
    reset({
      quarter: quarter[0]?.value,
      optionDropdown: yearOptions[yearOptions.length - 1]?.value,
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleEditOverview = async (params) => {
    const { performanceId } = params.row;
    try {
      setLoading(true);
      const teamEmployeePerformance = await getMyPerformanceOverview(
        employeeId,
        performanceId
      );
      dispatch(setMyPerformance(teamEmployeePerformance?.data));
      setLoading(false);
      navigate(`./overview-performance/${performanceId}`);
      dispatch(setIsTeamPerformance(true));
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
    dispatch(setPerformanceId(performanceId));
  };

  return (
    <>
      <OverviewGrid container spacing={2}>
        <Grid item xs={6} sm={3} md={2}>
          <FormInputSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            placeholder="Search"
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <FormDropDown
            label="Quarter"
            name="quarter"
            options={quarter}
            control={control}
            register={register("quarter", {
              onChange: (e) => {
                setQuarterData(e.target.value);
              },
            })}
            defaultValue={quarter[0]?.value}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <FormDropDown
            label="Year"
            name="optionDropdown"
            options={yearOptions}
            control={control}
            register={register("optionDropdown", {
              onChange: (e) => {
                setOptionDropdown(e.target.value);
              },
            })}
          />
        </Grid>
        {isFilterActive && (
          <ClearFilter item xs={4} sm={2}>
            <ClearFilterButton clearSelection={clearSelection} />
          </ClearFilter>
        )}
      </OverviewGrid>
      <DataGridComponent
        columnsData={columnData}
        rowData={teamPerformance?.rows}
        rowCount={totalItems}
        isLoading={isLoading}
        page={page - 1}
        editable={true}
        handlePaginationChange={handlePaginationChange}
      />
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};
export default TeamPerformance;
