import React from "react";
import {
  useSideNav,
} from "../../../../Common/CommonUtils";
import HrManagerMyAssignmentTabs from "./HrManagerMyAssignmentTabs";
import { useSelector } from "react-redux";
import HrAdminManagerMyAssignment from "./HrAdminManagerMyAssignment";

const HrManagerMyAssignment = () => {
  useSideNav();

  const designation = useSelector((state) => state.user?.data?.designation);
  const role = useSelector((state) => state.user?.data?.role);

  return (
    <>
      {designation === "HR Admin" && role === "HR" ? (
        <HrAdminManagerMyAssignment />
      ) : (
        <HrManagerMyAssignmentTabs />
      )}
    </>
  );
};

export default HrManagerMyAssignment;
