import React, { useState } from "react";
import TabPanel, { a11yProps } from "../../../Common/TabPanel/TabPanel";
import {
  MuiCompactMarginTab,
  MuiLargeSpacingTab,
  StickyTab,
  TabContainer,
} from "../../../Common/Common.styled";
import MySkill from "../MySkill";
import HrManagerViewDataGrid from "./HrManagerViewDataGrid";
import { usePermissionCustomHook } from "../../../Common/CommonUtils";
import { resetPage } from "../../../Common/store/paginationSlice/paginationSlice";
import { useDispatch, useSelector } from "react-redux";
import { setTabValue } from "../../../Common/store/tabSlice/tabSlice";

const HrManagerSkillTabs = () => {
  const isManager = usePermissionCustomHook("Manager");
  const isHR = usePermissionCustomHook("HR");
  const dispatch = useDispatch();
  const tabValue = useSelector((state) => state.tabInfo?.tabValue);

  const handleTabChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
    dispatch(resetPage());
  };

  return (
    <>
      <TabContainer>
        <StickyTab
          value={tabValue}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          {isManager && (
            <MuiCompactMarginTab label="My Skills" {...a11yProps(0)} />
          )}

          {isManager && (
            <MuiLargeSpacingTab label={"Team Skills"} {...a11yProps(1)} />
          )}

          {isHR && (
            <MuiCompactMarginTab label="Employee Skills" {...a11yProps(0)} />
          )}
        </StickyTab>
      </TabContainer>
      {isManager && (
        <TabPanel value={tabValue} index={0}>
          <MySkill />
        </TabPanel>
      )}
      <TabPanel value={tabValue} index={isManager ? 1 : 0}>
        <HrManagerViewDataGrid />
      </TabPanel>
    </>
  );
};

export default HrManagerSkillTabs;
