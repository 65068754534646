import React, { useState } from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import {
  CheckboxCloumn,
  CheckboxRow,
} from "../../Timesheet/Components/MyTimesheet.styled";
import { HeadGrid, ButtonGrid } from "./HrAction.styled";
import { teamApprovalData } from "../../../Common/Constant";
import {
  setPage,
  setPageSize,
} from "../../../Common/store/paginationSlice/paginationSlice";
import { CheckboxContainer } from "../../MyAssignment/Components/MyAssignment.styled";
import DataGridComponent from "../../../Common/DataGridComponent";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useSideNav } from "../../../Common/CommonUtils";
import { DataMainGrid } from "../../Employees/Employee.Styled";
import { FormInputSearch } from "../../../Common/FormInputSearch";
import HrActionDailog from "./HrActionDailog";
import {
  BackButtonGrid,
  BackButtonStyle,
  RequestTypo,
} from "../../Attendance/Components/LogAttendance.styled";
import { useNavigate } from "react-router-dom";
import { setNavigationRoute } from "../../SideNavbar/Store/navigationSlice/navigationSlice";

const HrAction = () => {
  useSideNav();
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [requestData, setRequestData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [teamApprovals, setTeamApprovals] = useState({
    isLoading: false,
    rows: teamApprovalData.map((row) => ({ ...row, isSelected: false })),
    totalItems: teamApprovalData.length,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = teamApprovals;

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setIsAllSelected(isChecked);
    setTeamApprovals((prev) => ({
      ...prev,
      rows: prev.rows.map((row) => ({
        ...row,
        isSelected: isChecked,
      })),
    }));
  };

  const handleRowCheckboxChange = (id, isChecked) => {
    setTeamApprovals((prev) => ({
      ...prev,
      rows: prev.rows.map((row) =>
        row.id === id ? { ...row, isSelected: isChecked } : row
      ),
    }));
  };

  const handleBulkApprove = () => {
    const selectedRows = teamApprovals.rows.filter((row) => row.isSelected);
    setTeamApprovals((prev) => ({
      ...prev,
      rows: prev.rows.map((row) =>
        row.isSelected ? { ...row, status: "Approved", isSelected: false } : row
      ),
    }));
    setIsAllSelected(false);
  };

  const isApproveDisabled = !teamApprovals.rows.some((row) => row.isSelected);

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleViewRequest = (params) => {
    setRequestData(params.row);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleGoBack = () => {
    navigate(`/dashboard`);
    dispatch(setNavigationRoute("/dashboard"));
  };

  const columnsData = [
    {
      field: "employeeRequested",
      headerName: "Employee Requested",
      width: 400,
      renderHeader: (params) => {
        return (
          <CheckboxCloumn>
            <FormControlLabel
              control={
                <CheckboxContainer
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  indeterminate={
                    teamApprovals.rows.some((row) => row.isSelected) &&
                    !isAllSelected
                  }
                />
              }
              label="Employee Requested"
            />
          </CheckboxCloumn>
        );
      },

      renderCell: (params) => {
        const { employeeRequested, id, isSelected } = params.row;
        return (
          <CheckboxRow>
            <FormControlLabel
              value={employeeRequested}
              control={
                <CheckboxContainer
                  checked={isSelected || false}
                  onChange={(event) =>
                    handleRowCheckboxChange(id, event.target.checked)
                  }
                />
              }
              label={employeeRequested}
            />
          </CheckboxRow>
        );
      },
    },

    {
      field: "dateRequested",
      headerName: "Date Requested",
      width: 300,
    },
    {
      field: "type",
      headerName: "Type",
      width: 300,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 300,
      align: "center",
      getActions: (params) => {
        return [
          <GridActionsCellItem label="Approve" showInMenu />,
          <GridActionsCellItem label="Reject" showInMenu />,
          <GridActionsCellItem
            label="View"
            showInMenu
            onClick={() => handleViewRequest(params)}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <DataMainGrid>
        <BackButtonGrid>
          <RequestTypo>My Action Pending</RequestTypo>
          <BackButtonStyle variant="h6" onClick={handleGoBack}>
            Back to Dashboard
          </BackButtonStyle>
        </BackButtonGrid>
        <HeadGrid container xs={12} sm={12}>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <FormInputSearch
              searchQuery={searchQuery}
              fullWidth
              setSearchQuery={setSearchQuery}
              placeholder="Search"
            />
          </Grid>

          <ButtonGrid xs={12} sm={2}>
            <Button
              variant="contained"
              onClick={handleBulkApprove}
              disabled={isApproveDisabled}
            >
              Approve
            </Button>
          </ButtonGrid>
        </HeadGrid>
        <DataGridComponent
          autoHeight
          columnsData={columnsData}
          rowData={teamApprovals.rows}
          editable={true}
          rowCount={totalItems}
          isLoading={isLoading}
          page={page - 1}
          handlePaginationChange={handlePaginationChange}
        />
        <HrActionDailog
          onClose={handleDialogClose}
          onOpen={isDialogOpen}
          requestData={requestData}
        />
      </DataMainGrid>
    </>
  );
};

export default HrAction;
