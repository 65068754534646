import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  dropDownOptions,
  RenderRejectApproveButtons,
  useLoader,
  useNotification,
  useSideNav,
} from "../../../Common/CommonUtils";
import { MuiCard } from "../../../Common/Common.styled";
import FormDropDown from "../../../Common/FormDropDown";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  PerformanceContainer,
  Performance,
  PerformanceTitle,
  PerformanceValue,
  PerformanceNameContainer,
  PerformanceListItem,
  PerformanceColumn,
} from "./MyPerformance.styled";
import {
  currentFormattedDate,
  generateYearsOptions,
} from "../../../Common/Constant";
import { DataMainGrid } from "../../Employees/Employee.Styled";
import { FormTextField } from "../../../Common/FormTextField";
import { AddeItemTableHeadingGrid } from "../../Invoice/CreateInvoice/CreateInvoice.Styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";
import {
  getMyPerformanceOverview,
  putMyPerformanceOverview,
} from "../services/PerformanceServices";
import { FieldSelect } from "../../../Common/Dropdown.styled";
import { StyledButton } from "../../../Common/Ui/styled";

const InfoItem = ({ title, value }) => (
  <PerformanceContainer>
    <PerformanceTitle>{title}</PerformanceTitle>
    <PerformanceValue>{value}</PerformanceValue>
  </PerformanceContainer>
);

const MyPerformance = () => {
  useSideNav();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const performance = useSelector((state) => state.performance);
  const metaData = useSelector((state) => state?.metaData);
  const isTeamPerformance = useSelector(
    (state) => state.performance?.isTeamPerformance
  );
  const userId = useSelector((state) => state.user?.data?.employeeId);
  const performanceSchema = yup.object().shape({
    goalsForNextReviewPeriod: yup.string().required("requiredField"),
    fields: yup.array().of(
      yup.object().shape({
        suggestionByManager: yup.string().when([], {
          is: () => isTeamPerformance,
          then: (schema) => schema.required("requiredField"),
          otherwise: (schema) => schema.optional().nullable(),
        }),
      })
    ),
  });

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const { showSnackbar, AlertSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const {
    employeeName,
    managerName,
    reviewedDate,
    performanceId,
    employeeAverage,
    managerAverage,
    goalsForNextReviewPeriod,
    comments,
    year,
    quarterMonth,
    projects,
    status,
    employeeId,
    // suggestionByManager,
  } = performance || {};
  const averageSelfRating = performance?.subPerformances?.reduce(
    (sum, field) => sum + (parseFloat(field.selfRating) || 0),
    0
  );

  const initialAverageSelfRating =
    averageSelfRating / performance?.subPerformances.length;

  const averageManagerRating = performance?.subPerformances?.reduce(
    (sum, field) => sum + (parseFloat(field.managerRating) || 0),
    0
  );

  const initialAverageManagerRating =
    averageManagerRating / performance?.subPerformances.length;

  const defaultValue = {
    fields: [
      ...(performance?.subPerformances?.map((params, index) => ({
        id: params?.subPerformanceId || index,
        parameter: params?.parameter,
        selfRating: parseInt(params?.selfRating) || 1,
        managerRating: parseInt(params?.managerRating) || 1,
        currentLevel: params?.currentLevel || "L1",
        targetLevel: params?.targetLevel || "L1",
        suggestionByManager: params?.suggestionByManager || "",
      })) || []),
    ],

    quarter: quarterMonth,
    year: year,
    goalsForNextReviewPeriod: goalsForNextReviewPeriod || "",
    comments: comments || "",
    employeeAverage: employeeAverage || initialAverageSelfRating,
    managerAverage: managerAverage || initialAverageManagerRating,
  };

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(performanceSchema),
  });

  const {
    handleSubmit,
    control,
    formState,
    // reset,
    setValue,
    register,
    getValues,
  } = useFunction;
  const { errors } = formState;

  const { fields } = useFieldArray({ control, name: "fields" });

  const { skillLevel, quarterMonthList } = metaData || {};
  const skillLevelOptionsList = dropDownOptions(skillLevel);
  const quarterMonthOptionsList = dropDownOptions(quarterMonthList);
  const yearOptions = generateYearsOptions();

  const projectList =
    projects.length > 0
      ? `${projects.map((each) => each)}`
      : "No Projects Assigned";

  const performanceData = {
    name: employeeName,
    department: "Engineering",
    manager: managerName,
    lastReviewDate: reviewedDate,
    id: performanceId,
    project: projectList,
    todayDate: currentFormattedDate,
  };

  const columnsData = [
    {
      field: "parameter",
      headerName: "Parameters",
      width: 2.5,
    },
    {
      field: "selfRating",
      headerName: "Self Rating",
      width: 1.5,
    },
    {
      field: "managerRating",
      headerName: "Manager Rating",
      width: 1.5,
    },
    {
      field: "currentLevel",
      headerName: "Current Level",
      width: 1.5,
    },
    {
      field: "targetLevel",
      headerName: "Target Level",
      width: 1.5,
    },
    {
      field: "suggestionByManager",
      headerName: "Suggestion By Manager",
      width: 3.5,
    },
  ];
  const handleSubmitData = (data) => {
    setRejectLoading(false);
    setApproveLoading(true);
    onSubmit(data, "submit");
  };

  const handleSaveAndDraft = (data) => {
    setRejectLoading(true);
    setApproveLoading(false);
    onSubmit(data, "draft");
  };

  const onSubmit = async (data, handleStatus) => {
    const {
      fields,
      goalsForNextReviewPeriod,
      comments,
      employeeAverage,
      managerAverage,
    } = data;

    const subPerformance = fields
      ?.filter((params) => params?.parameter !== "Average")
      .map((params) => ({
        subPerformanceId: params?.id,
        selfRating: parseFloat(params?.selfRating, 10),
        ...(isTeamPerformance && {
          ...(params?.managerRating && {
            managerRating: params?.managerRating,
          }),
          ...(params?.currentLevel && { currentLevel: params?.currentLevel }),
          ...(params?.targetLevel && { targetLevel: params?.targetLevel }),
          ...(params?.suggestionByManager && {
            suggestionByManager: params?.suggestionByManager,
          }),
        }),
      }));
    const payload = {
      employeeId: employeeId,
      goalsForNextReviewPeriod: goalsForNextReviewPeriod,
      ...(handleStatus === "submit" && {
        status: isTeamPerformance ? "Reviewed" : "Pending",
      }),

      employeeAverage: employeeAverage,
      ...(isTeamPerformance && { managerAverage: managerAverage }),
      subPerformance: subPerformance,
      ...(comments && { comments: comments }),
    };

    try {
      setLoading(true);
      const MyPerformanceOverview = await putMyPerformanceOverview(
        userId,
        performanceId,
        payload
      );

      await getMyPerformanceOverview(userId, performanceId);
      setApproveLoading(false);
      setRejectLoading(false);
      showSnackbar(
        MyPerformanceOverview?.message || "Performance Submited successfully"
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setApproveLoading(false);
      setRejectLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleRatingChange = (index, selfRating, column) => {
    if (
      selfRating === "" ||
      (Number(selfRating) >= 1 && Number(selfRating) <= 5)
    ) {
      setValue(`fields.${index}.${column.field}`, selfRating);
    } else {
      setValue(`fields.${index}.${column.field}`, "1");
    }

    const fields = getValues("fields");
    const ratingAverage = fields.reduce(
      (sum, field) => sum + (parseFloat(field?.[column.field]) || 0),
      0
    );

    if (column.field === "selfRating") {
      setValue("employeeAverage", `${ratingAverage / fields.length}`);
    } else if (column.field === "managerRating") {
      setValue("managerAverage", `${ratingAverage / fields.length}`);
    }
  };

  return (
    <>
      <DataMainGrid>
        <StyledButton variant="outlined" onClick={handleBack}>
          <KeyboardArrowLeftIcon />
          Back
        </StyledButton>
        <MuiCard>
          <Performance container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <InfoItem title="Name:" value={performanceData.name} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <PerformanceNameContainer>
                <InfoItem
                  title="Department:"
                  value={performanceData.department}
                />
              </PerformanceNameContainer>
            </Grid>
            <Grid item xs={12} md={3}>
              <PerformanceNameContainer>
                <InfoItem title="Manager:" value={performanceData.manager} />
              </PerformanceNameContainer>
            </Grid>
            <Grid item xs={12} md={3}>
              <InfoItem
                title="Last Review Date:"
                value={performanceData.lastReviewDate}
              />
            </Grid>
          </Performance>

          <Performance container spacing={2}>
            <Grid item xs={12} md={2}>
              <InfoItem title="ID:" value={performanceData.id} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <PerformanceContainer container spacing={1}>
                <Grid item xs={12} sm={3} md={7}>
                  <FormDropDown
                    name="quarter"
                    label="Quarter"
                    control={control}
                    options={quarterMonthOptionsList}
                    errors={!!errors.quarter}
                    helperText={errors.quarter?.message}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={5}>
                  <FormDropDown
                    name="year"
                    label="Year"
                    control={control}
                    options={yearOptions}
                    errors={!!errors.year}
                    helperText={errors.year?.message}
                    readOnly={true}
                  />
                </Grid>
              </PerformanceContainer>
            </Grid>
            <Grid item xs={12} md={2} lg={2.5}>
              <InfoItem title="Project:" value={performanceData.project} />
            </Grid>
            <Grid item xs={12} md={3}>
              <InfoItem title="Today Date:" value={performanceData.todayDate} />
            </Grid>
          </Performance>
        </MuiCard>

        <MuiCard>
          <Grid item xs={12}>
            <AddeItemTableHeadingGrid container spacing={2}>
              {columnsData.map((column) => (
                <Grid item xs={12} sm={column.width} key={column.field}>
                  <PerformanceColumn variant="body2">
                    {column.headerName}
                  </PerformanceColumn>
                </Grid>
              ))}
            </AddeItemTableHeadingGrid>

            {fields?.map((field, index) => (
              <PerformanceListItem container spacing={2} key={field.id}>
                {columnsData.map((column) => (
                  <Grid item xs={12} sm={column.width} key={column.field}>
                    {column.field === "parameter" ? (
                      <Typography>{field[column.field]}</Typography>
                    ) : (column.field === "selfRating" ||
                        column.field === "managerRating") &&
                      field[column.field] ? (
                      <Controller
                        name={`fields[${index}].${column.field}`}
                        control={control}
                        defaultValue={field[column.field]}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            multiline
                            register={register(
                              `fields.${index}.${column.field}`,
                              {
                                onChange: (e) =>
                                  handleRatingChange(
                                    index,
                                    e.target.value,
                                    column
                                  ),
                              }
                            )}
                            InputProps={{
                              inputProps: { min: 1, max: 5 },

                              readOnly:
                                column.field === "managerRating"
                                  ? status !== "Pending"
                                    ? true
                                    : !isTeamPerformance
                                  : status !== "Draft"
                                  ? true
                                  : false,
                            }}
                          />
                        )}
                      />
                    ) : (column.field === "currentLevel" ||
                        column.field === "targetLevel") &&
                      field[column.field] ? (
                      <Controller
                        name={`fields[${index}].${column.field}`}
                        control={control}
                        defaultValue={field[column.field]}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <FieldSelect
                              {...field}
                              disableFocus={
                                !(isTeamPerformance && status === "Pending")
                              }
                              inputProps={{
                                readOnly: !(
                                  isTeamPerformance && status === "Pending"
                                ),
                              }}
                            >
                              {skillLevelOptionsList.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </FieldSelect>
                          </FormControl>
                        )}
                      />
                    ) : column.field === "suggestionByManager" &&
                      isTeamPerformance ? (
                      <Controller
                        key={index}
                        control={control}
                        name={`fields[${index}].${column.field}`}
                        defaultValue={field[column.field] || ""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            InputProps={{
                              readOnly: status !== "Pending",
                            }}
                            error={
                              !!errors?.fields?.[index]?.suggestionByManager
                            }
                            helperText={
                              errors?.fields?.[index]?.suggestionByManager
                                ?.message
                            }
                          />
                        )}
                      />
                    ) : (
                      <Typography>{field[column.field]}</Typography>
                    )}
                  </Grid>
                ))}
              </PerformanceListItem>
            ))}
            <PerformanceListItem container spacing={2}>
              <Grid item xs={12} sm={2.5}>
                <Typography>Average</Typography>
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <FormTextField
                  fullWidth
                  name="employeeAverage"
                  type="text"
                  control={control}
                  errors={!!errors?.employeeAverage}
                  helperText={errors?.employeeAverage?.message}
                  defaultValue={defaultValue?.employeeAverage}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <FormTextField
                  fullWidth
                  name="managerAverage"
                  type="text"
                  control={control}
                  errors={!!errors?.managerAverage}
                  helperText={errors?.managerAverage?.message}
                  defaultValue={defaultValue?.managerAverage}
                  readOnly
                />
              </Grid>
            </PerformanceListItem>
          </Grid>
          <Performance container spacing={2} marginTop={"16px"}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Goals For Next Review Period"
                name="goalsForNextReviewPeriod"
                rows={3}
                multiline
                control={control}
                errors={!!errors?.goalsForNextReviewPeriod}
                helperText={errors?.goalsForNextReviewPeriod?.message}
                readOnly={status !== "Draft"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Comments"
                name="comments"
                rows={3}
                multiline
                control={control}
                errors={!!errors?.comments}
                helperText={errors?.comments?.message}
                readOnly={!(isTeamPerformance && status === "Pending")}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {((isTeamPerformance && status === "Pending") ||
                status === "Draft") && (
                <RenderRejectApproveButtons
                  handleReject={handleSubmit(handleSaveAndDraft)}
                  handleApproveReject={handleSubmit(handleSubmitData)}
                  rejectLoading={rejectLoading}
                  approveLoading={approveLoading}
                  saveOrRejectButtonLabel={
                    isTeamPerformance ? "Save" : "Save & Draft"
                  }
                  submitButtonLabel="Submit"
                  width={"150px"}
                  color={"primary"}
                />
              )}
            </Grid>
          </Performance>
        </MuiCard>
      </DataMainGrid>
      <AlertSnackbar />
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default MyPerformance;
