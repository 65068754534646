export const getMTeamPerformanceDataGridUtils = (teamPerformanceData) => {
  if (!Array.isArray(teamPerformanceData) || teamPerformanceData.length === 0) {
    return [];
  }
  return teamPerformanceData?.map((performance, index) => ({
    ...performance,
    id: performance.performanceId || index,
    performanceId: performance.performanceId,
    employeeId: performance.employeeId,
    quarter: performance.quarter,
    year: performance.year,
    goalsForNextReviewPeriod: performance.goalsForNextReviewPeriod,
    comments: performance.comments,
    manager: performance.manager,
    reviewedDate: performance.reviewedDate,
    managerReviewedDate: performance.managerReviewedDate,
    projects:
      Array.isArray(performance.projects) && performance.projects.length > 0
        ? performance.projects[0]
        : null,
    employeeAverage: performance.employeeAverage,
    managerAverage: performance.managerAverage,
    status: performance.status,
    submissionDate: performance.submissionDate,
    isActive: performance.isActive,
    createdBy: performance.createdBy,
    createdDateTime: performance.createdDateTime,
    updatedBy: performance.updatedBy,
    updatedDateTime: performance.updatedDateTime,
    employeeId: performance.employee_id,
    subPerformances:
      Array.isArray(performance.subPerformances) &&
      performance.subPerformances.length > 0
        ? performance.subPerformances
        : null,
    employeeName: performance.employeeName,
    managerName: performance.managerName,
    managerId: performance.managerId,
    quarterMonth: performance.quarterMonth,
    designation: performance.designation,
  }));
};
