import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { DoughnutChartContainer, DoughnutContainer, LeaveTotal } from "../Dashboard.styled";
import { DoughnutWrapper } from "../../../Leave/components/Leave.styled";

ChartJS.register(ArcElement, Tooltip, Legend);

const colorMapping = {
  "At Work": "#284495",
  Absent: "#97A5EB",
  "On Leave": "#EB1E24",
};

const DoughnutChartAttendance = ({ AttendanceCounts }) => {
  const labels = Object.keys(colorMapping);

  const generateChartData = () => ({
    labels: labels,
    datasets: [
      {
        data: AttendanceCounts,
        backgroundColor: labels.map((level) => colorMapping[level]),
        borderColor: labels.map((level) => colorMapping[level]),
        borderWidth: 1,
      },
    ],
  });

  const options = {
    responsive: true,
    cutout: "76%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <DoughnutChartContainer>
      <DoughnutWrapper>
        <Doughnut data={generateChartData()} options={options} />
       <DoughnutContainer>
         <LeaveTotal variant="h2">{AttendanceCounts.reduce((acc, count) => acc + count, 0)}</LeaveTotal>
        </DoughnutContainer>
      </DoughnutWrapper>
    </DoughnutChartContainer>
  );
};

export default DoughnutChartAttendance;
