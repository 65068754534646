import React from "react";
import EmployeePortalDialog from "../../../Common/EmployeePortalDialog";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import HrActionForm from "./HrActionForm";
const HrActionDailog = (props) => {
  const { onOpen, requestData, onClose } = props;
  const { type } = requestData;
  const dispatch = useDispatch();
  const handleCloseDialog = () => {
    onClose();
  };

  const dialogTitle =
    type === "Leave"
      ? "Leave Request"
      : type === "Attendance"
      ? "Attendance Request"
      : "Doument Rquest";

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <EmployeePortalDialog
          open={onOpen}
          onClose={handleCloseDialog}
          title={dialogTitle}
          customWidth={"520px"}
        >
          <HrActionForm onCancel={onClose} requestData={requestData} />
        </EmployeePortalDialog>
      </LocalizationProvider>
    </>
  );
};

export default HrActionDailog;
