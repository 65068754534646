import React from "react";
import { MuiCard } from "../../../../Common/Common.styled";
import {
  AttendanceContainer,
  AttendanceMuiCard,
  AttendanceTotal,
  DoughnutChartLeaveType,
  DoughnutContainerChat,
  LeaveOverview,
  LeaveOverviewContainer,
  Title,
} from "../Dashboard.styled";
import {
  ListItemTextContainer,
  StyledListItem,
} from "../../../Leave/components/Leave.styled";
import { setNavigationRoute } from "../../../SideNavbar/Store/navigationSlice/navigationSlice";
import { useDispatch } from "react-redux";
import DoughnutChartAttendance from "./DoughnutChartAttendance";
import { useNavigate } from "react-router-dom";

const HrAdminAttendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const LeaveList = () => {
    return (
      <AttendanceContainer>
        <StyledListItem color="#284495">
          <ListItemTextContainer primary={`At Work- 60`} />
        </StyledListItem>
        <StyledListItem color="#97A5EB">
          <ListItemTextContainer primary={`Absent - 33`} />
        </StyledListItem>
        <StyledListItem color="#EB1E24">
          <ListItemTextContainer primary={`On Leave - 07`} />
        </StyledListItem>
      </AttendanceContainer>
    );
  };

  const AttendanceData = [
    {
      priorityLevel: "At Work",
      noOFComplaints: "60",
    },
    {
      priorityLevel: "Absent",
      noOFComplaints: "33",
    },
    {
      priorityLevel: "On Leave",
      noOFComplaints: "07",
    },
  ];

  const AttendanceCounts = AttendanceData.map((task) =>
    parseInt(task.noOFComplaints.split(" ")[0])
  );

  const handleViewAttendance = () => {
    navigate("/employee-checkin");
    dispatch(setNavigationRoute("/employee-checkin"));
  };

  return (
    <>
      <AttendanceMuiCard>
        <AttendanceContainer>
          <Title variant="h2">
            Headcounts <AttendanceTotal component="span">100</AttendanceTotal>
          </Title>
          <DoughnutChartLeaveType variant="h2">Today</DoughnutChartLeaveType>
        </AttendanceContainer>
        <LeaveList />
        <DoughnutContainerChat>
          <DoughnutChartAttendance AttendanceCounts={AttendanceCounts} />
        </DoughnutContainerChat>
        <LeaveOverviewContainer>
          <LeaveOverview underline="always" onClick={handleViewAttendance}>
            View Attendance
          </LeaveOverview>
        </LeaveOverviewContainer>
      </AttendanceMuiCard>
    </>
  );
};

export default HrAdminAttendance;
