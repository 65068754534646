import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const LeaveTotal = styled(Typography)`
  font-family: Roboto;
  font-size: 26px;
  font-weight: bold;
  margin: 4px 0 0 0;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  align-self: center;
  font-weight: 500;
  margin: 0;
  font-family: Roboto;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin: 15px;
    font-size: 15px;
  }
`;

export const DoughnutContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DoughnutWrapper = styled(Grid)`
  position: relative;
  width: 75px;
  height: 75px;
  align-self: center;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    width: 90px;
    height: 90px;
    margin-left: 5px;
  }
`;

export const DoughnutChartContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "10px 0px",
  },

  [`@media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl}px)`]:
    {
      padding: "1px 0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
}));

export const DoughnutChartContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-left: ${(props) => `${props.theme.spacing(1.5)}`};
  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.xs}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    align-items: center;
    justify-content: center;
  }
`;

export const DoughnutChartLeaveType = styled(Typography)`
  color: gray;
  font-size: 12px;
  font-family: "Roboto";
`;

export const LeaveChartContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18px;
  width: 100%;
`;

export const MuiCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(2)}`};
  border-radius: 8px;
`;
export const LeaveChartCard = styled(MuiCard)`
  height: 188px;
  display: flex;
  flex-direction: column;
  padding: 1px 0px;
  margin-top: ${(props) => `${props.theme.spacing(2)}`};
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    height: 53vh;
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.md}px) {
    height: 30vh;
  }
`;

export const HrPoliciesCard = styled(MuiCard)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  border-radius: 8px;
  height: 168px;
  display: flex;
  padding: 0px;
  flex-direction: column;
  margin-top: ${(props) => `${props.theme.spacing(2)}`};
  overflow: hidden;
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${theme.breakpoints.values.sm}px) {
      min-height: 30vh;
    }
  `}
`;

export const LeaveChartCircle = styled(Grid)(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "space-around",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px 10px",
  },
}));

export const DividerContainer = styled(Divider)`
  background-color: gray;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e1ea;
`;

export const LeaveOverview = styled(Link)`
  text-align: center;
  color: #0000b3;
  cursor: pointer;
  font-size: 17px;
  font-family: "'Inter', sans-serif";

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    text-align: right;
    display: flex;
    justify-content: end;
    color: #0000b3;
    font-size: 13px;
    padding-bottom: 16px;
  }
`;

export const ApplyLink = styled(Button)`
  cursor: pointer;
  color: #0000b3;
`;

export const DashboardContainer = styled(Grid)`
  padding: 0px 24px 0px 0px;
`;

export const LeaveOverviewContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 45px 12px 0px;
`;

export const NoLeavesContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-size: 35px;
  font-weight: bold;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: 40px 40px;
  }
`;

export const AttendanceContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto";
  font-size: 35px;
  font-weight: bold;
  padding: 5px 5px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: 5px 5px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const AttendanceMuiCard = styled(MuiCard)`
  height: 285px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    height: 58vh;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.md}px) {
    height: 29vh;
  }
`;

export const JobMuiCard = styled(MuiCard)`
  height: 282px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    height: 40vh;
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.md}px) {
    height: 29vh;
  }
`;

export const DoughnutContainerChat = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AttendanceTotal = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.info.blue};
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  align-self: center;
  font-family: "Roboto";
  font-size: 17px;
  text-align: center;
`;

export const JobHeading = styled(Typography)(({ theme }) => ({
  fontSize: `${theme.typography.fontSize * 1.2}px`,
  fontWeight: 500,
  fontFamily: "'Lato', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.8}px`,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.9}px`,
  },
  [`@media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.xl}px)`]:
    {
      fontSize: `${theme.typography.fontSize * 0.9}px`,
    },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: 0,
  fontSize: `${theme.typography.fontSize * 0.9}px`,
  fontWeight: 400,
  padding: "2px 5px",
  lineHeight: 1.5,
  backgroundColor: "#3fc28a1a",
  borderRadius: "3px",
  color: "#00c24e",
  fontFamily: "'Lexend', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.4}px`,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.6}px`,
  },
  [`@media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${theme.breakpoints.values.xl}px)`]:
    {
      fontSize: `${theme.typography.fontSize * 0.7}px`,
    },
}));

export const ValueHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 550,
  marginRight: theme.spacing(2),
  fontSize: `${theme.typography.fontSize * 1.7}px`,
  fontFamily: "'Lato', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 1.2}px`,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: `${theme.typography.fontSize * 1.4}px`,
  },
}));

export const CardHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: `${theme.typography.fontSize * 1.5}px`,
  fontFamily: "'Inter', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 1.1}px`,
  },
}));

export const StyledBox = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor || "#FF9800"};
  margin-right: 8px;
`;

export const NumTypo = styled(Typography)(({ theme }) => ({
  fontWeight: 550,
  fontSize: `${theme.typography.fontSize * 2.2}px`,
  fontFamily: "'Lato', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 1.7}px`,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: `${theme.typography.fontSize * 1.9}px`,
  },
  [`@media (min-width: ${theme.breakpoints.values.md}px) and @media (min-width: ${theme.breakpoints.values.xl}px) `]:
    {
      fontSize: `${theme.typography.fontSize * 1.4}px`,
    },
}));

export const CommonGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RightGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const EmployeeStaffTpo = styled(Typography)(({ theme }) => ({
  color: "#b0b0b6",
  fontWeight: 500,
  marginLeft: theme.spacing(1),
  fontSize: `${theme.typography.fontSize * 1}px`,
  fontFamily: "'Lato', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.8}px`,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.7}px`,
  },
}));

export const ProgressGrid = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  marginTop: theme.spacing(2.5),
}));

export const MiddleGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  marginTop: theme.spacing(1.5),
}));

export const LastGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: theme.spacing(1),
  justifyContent: "space-between",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    marginTop: theme.spacing(0.5),
  },
}));

export const DetailedIcon = styled(MoreVertIcon)`
  color: "#64676C";
`;

export const InvalidData = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "auto",
  width: "100%",
  fontSize: `${theme.typography.fontSize * 1.2}px`,
  fontWeight: 500,
  fontFamily: "'Lato', sans-serif",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.8}px`,
  },
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    fontSize: `${theme.typography.fontSize * 0.8}px`,
  },
}));

export const EmpTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.info.blue};
  font-size: 13px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1.3}px`};
`;

export const ViewTypography = styled(Typography)`
  color: #a6a8b1;
  font-size: 12px;
  font-weight: 600;

  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1.3}px`};
`;
export const PendingText = styled(Typography)`
  font-size: 18px;
  font-family: "Inter", sans-serif;
`;

export const ViewText = styled(Link)`
  color: #0000b3;
  cursor: pointer;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  padding: ${(props) => `${props.theme.spacing(0.6)} 0`};
`;

export const LeaveGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => `${props.theme.spacing(0.6)} 0`};
`;

export const MtBox = styled(Box)`
  margin-top: ${(props) => props.theme.spacing(2)};
`;

export const DashBoardHeading = styled(Typography)`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 25.3px;
`;
