import React, { useEffect } from "react";
import LeaveDataGrid from "./LeaveDataGrid";
import ManagerViewTabs from "./ManagerViewLeaves/ManagerViewTabs";
import {
  useLoader,
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../../Common/CommonUtils";
import { StyledGridContainer } from "../../Employees/Employee.Styled";
import Grid from "@mui/material/Grid";
import { LeaveChartCard } from "../../../Common/Common.styled";
import LeaveChart from "./LeaveChart";
import ManagerViewDataGrid from "./ManagerViewLeaves/ManagerViewDataGrid";
import { setLeaveType } from "../../../Common/store/metaDataSlice/metaDataSlice";
import { getLeaveType } from "../services/LeaveApi";
import { useDispatch } from "react-redux";
import HolidayList from "./holidayList";
import {
  LeaveBottomContainer,
  LeaveChartContainer,
  LeaveTitle,
} from "./Leave.styled";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";

const Leave = () => {
  useSideNav();
  const dispatch = useDispatch();
  const { AlertSnackbar } = useSnackbar();
  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();

  useEffect(() => {
    fetchLeaveType();
  }, []);

  const fetchLeaveType = async () => {
    try {
      setLoading(true);
      const leaves = await getLeaveType();
      dispatch(setLeaveType(leaves));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  return (
    <>
      {!isHR && (
        <>
          <StyledGridContainer
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <LeaveTitle variant="h3">My Leaves</LeaveTitle>
          </StyledGridContainer>
          <LeaveChartContainer container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <LeaveChartCard>
                <LeaveChart />
              </LeaveChartCard>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <HolidayList />
            </Grid>
          </LeaveChartContainer>
          <LeaveBottomContainer></LeaveBottomContainer>
        </>
      )}
      {isDeveloper && <LeaveDataGrid />}
      {isManager && <ManagerViewTabs />}
      {isHR && <ManagerViewDataGrid />}
      <NotificationPopup />
      <LoaderPopup />
      <AlertSnackbar />
    </>
  );
};

export default Leave;
