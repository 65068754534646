const initialState = {
  branches: [],
  roles: [],
  managers: [],
  departments: [],
  coreTechnologies: [],
  designations: [],
  frameWorks: [],
  projects: [],
  leaveType: [],
  category: [],
  skillLevel: [],
  technology: [],
  managerEmployees: [],
  employmentType: [],
  Utilization: [],
  projectList: [],
  utilizationPercentage: [],
  billableType: [],
  quarterMonthList: [],
  teamQuarterList: [],
  technologyByCategory: [],
  leaveStructure: [],
  attendanceDayStatus: [],
};
export default initialState;
