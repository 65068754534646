import Grid from "@mui/material/Grid";
import {
  dropDownOptions,
  useLoader,
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../../../Common/CommonUtils";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import ClearFilterButton from "../../../Employees/ClearFilterButton";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useDebounceEffect from "../../../../Common/CustomHooks/useDebounceEffect";
import { FormInputSearch } from "../../../../Common/FormInputSearch";
import FormDropDown from "../../../../Common/FormDropDown";
import { downloadOptions } from "../../../../Common/Constant";
import {
  DataMainGrid,
  EmployeeDownloadIconGrid,
  StyledContainerGrid,
} from "../../../Employees/Employee.Styled";
import DownloadDropdown from "../../../../Common/DownloadDropdown";
import { MuiCard } from "../../../../Common/Common.styled";
import DataGridComponent from "../../../../Common/DataGridComponent";
import MyAssignmentDialog from "./MyAssignmentDialog";
import Box from "@mui/material/Box";
import ProgressBar from "../../../../Common/ProgressBar";
import {
  AllocateProject,
  ClearFilter,
  FilterItemsContainer,
  LinearProgressComponent,
  RadioContainer,
} from "../MyAssignment.styled";
import {
  getEditResourceUtiliztion,
  postGetMyAssignment,
  postMyAssignmentDownload,
  putInActiveMyAssignment,
  putSubmitMyAssignment,
} from "../../services/MyAssignmentServices";
import { getMyAssignmentData } from "./MyAssignmentDataGridUtils";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import {
  resetMyAssignmentForm,
  setIsMyAssignmentForm,
  setIsSubmitMyAssignment,
  setMyAssignmentForm,
} from "../../store/MyAssignmentSlice";
import useSnackbar from "../../../../Common/CustomHooks/useSnackbar";
import { usePrompt } from "../../../../Common/UsePrompt";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";

const HrAdminManagerMyAssignment = () => {
  useSideNav();
  const [departmentsDropdown, setDepartmentsDropdown] = useState(null);
  const [projectsDropdown, setProjectsDropdown] = useState(null);
  const [selectedValue, setSelectedValue] = useState({
    employeeName: null,
    employeeId: null,
  });
  const [utailizationDropdown, setUtailizationDropdown] = useState(null);
  const [totalUtilization, setTotalUtilization] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const { showSnackbar, AlertSnackbar } = useSnackbar();
  const { showPrompt, PromptPopup } = usePrompt();
  const [assignmentDetails, setAssignmentDetails] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const loginUserRole = useSelector((state) => state.user?.data?.role);
  const designation = useSelector((state) => state.user?.data?.designation);
  const metaData = useSelector((state) => state?.metaData);
  const isSubmitMyAssignment = useSelector(
    (state) => state?.myAssignmentForm?.isSubmitMyAssignment
  );

  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = assignmentDetails;

  const { departments, projectList, utilization } = metaData || [];

  const isHR = usePermissionCustomHook("HR");
  const isManager = usePermissionCustomHook("Manager");
  const dispatch = useDispatch();

  const SearchResult =
    departmentsDropdown ||
    searchQuery ||
    projectsDropdown ||
    utailizationDropdown;

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: `${item.name}`,
      value: `${item.name}`,
      from: item.from,
      to: item.to,
    })) || [];

  const mapToProjectOptionsList = (items) =>
    items?.map((item) => ({
      label: item.projectName,
      value: item.projectName,
    })) || [];

  const departmentsOptionsList = dropDownOptions(departments);
  const projectsOptionsList = mapToProjectOptionsList(projectList);
  const utailizationOptionsList = mapToOptionsList(utilization);

  const defaultValue = {};
  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const handleRadioChange = (event, employeeId) => {
    setSelectedValue({
      employeeName: event.target.value,
      employeeId,
    });

    const matchingRows = assignmentDetails?.rows?.filter(
      (item) => item.employeeId === employeeId && item.status !== "inactive"
    );
    const totalUtilization = matchingRows?.reduce(
      (total, item) => total + (item.utilization || 0),
      0
    );
    setTotalUtilization(totalUtilization);
  };

  const handleEditAction = async (event, params) => {
    const { row } = params;
    const matchingRows = assignmentDetails?.rows?.filter(
      (item) => item.employeeId === row.employeeId && item.status !== "inactive"
    );
    const totalUtilization = matchingRows?.reduce(
      (total, item) => total + (item.utilization || 0),
      0
    );
    setTotalUtilization(totalUtilization);
    try {
      setLoading(true);
      const editResourceUtiliztion = await getEditResourceUtiliztion(
        loginUserId,
        row?.projectAssignmentId
      );
      setIsDialogOpen(true);
      setLoading(false);
      dispatch(setMyAssignmentForm(editResourceUtiliztion));
      fetchMyAssignment();
    } catch (error) {
      setOpenNotification(true);
      setLoading(false);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };

  const groupRowsByEmployeeName = (data) => {
    const grouped = [];
    const employeeGroups = {};

    data.forEach((row) => {
      const { employeeName, employeeId } = row;

      if (!employeeGroups[employeeId]) {
        employeeGroups[employeeId] = {
          rowSpan: 0,
          isFirstInGroup: true,
        };
      }

      employeeGroups[employeeId].rowSpan += 1;
      grouped.push({
        ...row,
        isFirstInGroup: employeeGroups[employeeId].rowSpan === 1,
        rowSpan: employeeGroups[employeeId].rowSpan,
      });
    });

    return grouped;
  };

  const sorting = {
    sortModel: [{ field: "employeeName", sort: "asc" }],
  };

  const columnsData = [
    {
      field: "employeeName",
      headerName: "Employee Name",
      width: 230,
      ...(isHR &&
        designation === "HR Admin" && {
          renderCell: (params) => {
            const { employeeName, isFirstInGroup, employeeId } = params.row;
            return isFirstInGroup ? (
              <RadioGroup
                value={selectedValue?.employeeName || ""}
                onChange={(event) => handleRadioChange(event, employeeId)}
              >
                <FormControlLabel
                  value={employeeName}
                  control={<RadioContainer />}
                  label={employeeName}
                />
              </RadioGroup>
            ) : null;
          },
        }),
    },
    {
      field: "department",
      headerName: "Department",
      width: 170,
    },
    {
      field: "projectName",
      headerName: "Project Assigned",
      width: 250,
    },
    {
      field: "utilization",
      headerName: "Utilization",
      width: 230,
      renderCell: (params) => {
        const { utilization } = params?.row;
        return (
          <Box sx={{ width: "80%" }}>
            <ProgressBar
              value={utilization}
              LinearProgressComponent={LinearProgressComponent}
            />
          </Box>
        );
      },
    },
    {
      field: "billableType",
      headerName: "Billing Type",
      width: 180,
    },

    ...(isHR && designation === "HR Admin"
      ? [
          {
            field: "managerName",
            headerName: "Managers",
            width: 190,
          },
        ]
      : []),

    ...(isHR && designation === "HR Admin"
      ? [
          {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            width: 130,
            align: "center",
            getActions: (params) => {
              if (params?.row?.projectAssignmentId) {
                return [
                  <GridActionsCellItem
                    label="Edit"
                    showInMenu
                    onClick={(e) => handleEditAction(e, params)}
                  />,
                  <GridActionsCellItem
                    label={"Inactive"}
                    showInMenu
                    onClick={(e) => handleInactive(e, params)}
                  />,
                ];
              }
              return [];
            },
          },
        ]
      : []),
  ];

  const { control, formState, reset, register } = useFunction;
  const { errors } = formState;

  const clearSelection = () => {
    setIsFilterSelected(false);
    reset(defaultValue);
    setDepartmentsDropdown(null);
    setProjectsDropdown(null);
    setUtailizationDropdown(null);
    setSearchQuery("");
  };

  const handleDownloadChange = async (option) => {
    try {
      setLoading(true);
      if (option.value === "excel") {
        const payload = {
          userId: loginUserId,
          ...(searchQuery && { searchBy: searchQuery }),
          ...(departmentsDropdown && { department: departmentsDropdown }),
          ...(projectsDropdown && { projectName: projectsDropdown }),
          ...(utailizationDropdown && {
            from: utailizationDropdown.from,
            to: utailizationDropdown.to,
          }),
          format: "excel",
        };

        const attendanceDownload = await postMyAssignmentDownload(payload);
        const blob = new Blob([attendanceDownload]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Assignment_userId${loginUserId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.error || error?.response?.data?.message
      );
    }
  };

  const handleInactive = async (e, params) => {
    const { projectAssignmentId } = params.row;
    showPrompt(
      "Inactive Project Allocation",
      "Are you sure you want to Inactive the project ? This information will be visible to both the manager and employees.",
      async () => {
        try {
          setLoading(true);
          const inaAtiveMyAssignment = await putInActiveMyAssignment(
            loginUserId,
            projectAssignmentId
          );
          showSnackbar(inaAtiveMyAssignment || "Project Inactive Successfully");
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setOpenNotification(true);
          setNotificationMessage(
            error?.response?.data?.error || error?.response?.data?.message
          );
        }
        fetchMyAssignment();
      },
      () => {
        handleClose();
      }
    );
  };

  const handleAllocate = (params) => {
    setIsDialogOpen(true);
    dispatch(resetMyAssignmentForm());
    dispatch(setIsMyAssignmentForm(true));
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  useDebounceEffect(
    () => {
      fetchMyAssignment();
      setIsFilterSelected(SearchResult);
    },
    500,
    [
      departmentsDropdown,
      searchQuery,
      projectsDropdown,
      utailizationDropdown,
      page,
      pageSize,
    ]
  );

  const fetchMyAssignment = async () => {
    try {
      setAssignmentDetails({ ...assignmentDetails, isLoading: true });
      setLoading(true);

      const payload = {
        userId: loginUserId,
        role: loginUserRole,
        ...(searchQuery && { searchBy: searchQuery }),
        ...(departmentsDropdown && { department: departmentsDropdown }),
        ...(projectsDropdown && { projectName: projectsDropdown }),
        ...(utailizationDropdown && {
          from: utailizationDropdown.from,
          to: utailizationDropdown.to,
        }),
        page: page,
        limit: pageSize,
      };

      const MyAssignmentList = await postGetMyAssignment(payload);

      if (MyAssignmentList?.data) {
        const MyAssignmentData = getMyAssignmentData(MyAssignmentList?.data);
        const groupedData = groupRowsByEmployeeName(MyAssignmentData);
        setLoading(false);
        setAssignmentDetails((prev) => ({
          ...prev,
          rows: groupedData,
          isLoading: false,
          totalItems: MyAssignmentList?.pagination?.totalItems,
        }));
      } else {
        setNotificationMessage("Invalid API response");
      }
    } catch (error) {
      setAssignmentDetails({ ...assignmentDetails, isLoading: false });
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleSubmit = async () => {
    showPrompt(
      "Project Allocation",
      "Are you sure you want to update the project ? This information will be visible to both the manager and employees.",
      async () => {
        try {
          setLoading(true);
          const submitMyAssignment = await putSubmitMyAssignment(loginUserId);
          showSnackbar(submitMyAssignment || "Project Allocated successfully");
          setLoading(false);
          dispatch(setIsSubmitMyAssignment(false));
        } catch (error) {
          setLoading(false);
          setOpenNotification(true);
          setNotificationMessage(
            error?.response?.data?.error || error?.response?.data?.message
          );
        }
        fetchMyAssignment();
      },
      () => {
        handleClose();
      }
    );
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12}>
          {designation === "HR Admin" && loginUserRole === "HR" && (
            <Typography variant="h3" component="h1">
              Resource Utilization
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <FilterItemsContainer container spacing={1}>
            <Grid item xs={12} sm={2}>
              <FormInputSearch
                searchQuery={searchQuery}
                fullWidth
                setSearchQuery={setSearchQuery}
                placeholder="Search"
              />
            </Grid>

            <Grid item xs={12} sm={10}>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item xs={6} sm={2} order={{ xs: 3, sm: 1, md: 1 }}>
                  <FormDropDown
                    label="Department"
                    name="department"
                    control={control}
                    errors={errors?.department}
                    helperText={errors?.department?.message}
                    options={departmentsOptionsList}
                    register={register("department", {
                      onChange: (e) => {
                        setDepartmentsDropdown(e.target.value);
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={6} sm={2} order={{ xs: 2, sm: 2, md: 2 }}>
                  <FormDropDown
                    label="Projects"
                    name="projects"
                    control={control}
                    errors={errors?.projects}
                    helperText={errors?.projects?.message}
                    options={projectsOptionsList}
                    register={register("projects", {
                      onChange: (e) => {
                        setProjectsDropdown(e.target.value);
                      },
                    })}
                  />
                </Grid>

                <Grid item xs={12} sm={2} order={{ xs: 1, sm: 3, md: 3 }}>
                  <FormDropDown
                    label="Utilization"
                    name="utilization"
                    control={control}
                    errors={errors?.utailization}
                    helperText={errors?.utailization?.message}
                    options={utailizationOptionsList}
                    register={register("utilization", {
                      onChange: (e) => {
                        const selectedOption = utailizationOptionsList.find(
                          (option) => option.value === e.target.value
                        );
                        setUtailizationDropdown(selectedOption);
                      },
                    })}
                  />
                </Grid>

                {isFilterSelected && (
                  <ClearFilter
                    item
                    xs={3}
                    sm={1}
                    order={{ xs: 4, sm: 4, md: 4 }}
                  >
                    <ClearFilterButton clearSelection={clearSelection} />
                  </ClearFilter>
                )}
                {(isHR || isManager) && (
                  <EmployeeDownloadIconGrid
                    item
                    xs={6}
                    sm={0.5}
                    isFilterSelected={isFilterSelected}
                    isRight={true}
                    order={{ xs: 5, sm: 5, md: 5 }}
                  >
                    <DownloadDropdown
                      options={downloadOptions}
                      handleChange={handleDownloadChange}
                    />
                  </EmployeeDownloadIconGrid>
                )}
              </Grid>
            </Grid>
          </FilterItemsContainer>
        </Grid>
        {isHR && designation === "HR Admin" && (
          <AllocateProject xs={12} sm={12}>
            <Button
              variant="contained"
              onClick={handleAllocate}
              disabled={!selectedValue.employeeId}
            >
              Allocate Project
            </Button>
          </AllocateProject>
        )}
      </Grid>
      <DataMainGrid>
        <MuiCard>
          <DataGridComponent
            columnsData={columnsData}
            rowData={assignmentDetails.rows}
            rowCount={totalItems}
            isLoading={isLoading}
            page={page - 1}
            editable={true}
            handlePaginationChange={handlePaginationChange}
          />
          {isHR && designation === "HR Admin" && (
            <Box display="flex" justifyContent="flex-end">
              <StyledContainerGrid item>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!isSubmitMyAssignment}
                >
                  Submit
                </Button>
              </StyledContainerGrid>
            </Box>
          )}
        </MuiCard>
      </DataMainGrid>
      <MyAssignmentDialog
        onClose={handleClose}
        onOpen={isDialogOpen}
        selectedValue={selectedValue}
        fetchMyAssignment={fetchMyAssignment}
        totalUtilization={totalUtilization}
      />
      <NotificationPopup />
      <AlertSnackbar />
      <LoaderPopup />
      <PromptPopup />
    </>
  );
};

export default HrAdminManagerMyAssignment;
