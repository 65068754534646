import { api } from "../../../Common/AxoisConfig";

export const getEmployees = async (payload) => {
  const employees = await api("NODE_API_URL").get(`employee`, {
    params: payload,
  });
  return employees?.data;
};

export const postEmployeesDownload = async (userId) => {
  const employeesDownload = await api("NODE_API_URL").post(
    `employee/download?userId=${userId}`,
    {},
    { responseType: "arraybuffer" }
  );
  return employeesDownload?.data;
};

export const postEmployeeApi = async (payload) => {
  await api("NODE_API_URL").post(`employee`, payload);
};

export const getEmployee = async (userId, employeeId) => {
  const employee = await api("NODE_API_URL").get(
    `employee/get?userId=${userId}&employeeId=${employeeId}`
  );
  return employee?.data;
};

export const putPersonalInfo = async (userId, employeeUserId, payload) => {
  await api("NODE_API_URL").put(
    `employee?employeeId=${employeeUserId}&userId=${userId}`,
    payload
  );
};

export const getRole = async () => {
  const getByRole = await api("NODE_API_URL").get(
    `metadata/type?type=Role&status=active`
  );
  return getByRole?.data?.data;
};

export const getBranch = async () => {
  const getByBranch = await api("NODE_API_URL").get(
    `metadata/type?type=Branch&status=active`
  );
  return getByBranch?.data?.data;
};

export const getAttendanceDayStatus = async () => {
  const attendanceDayStatus = await api("NODE_API_URL").get(
    `metadata/type?type=Attendance Day Status&status=active`
  );
  return attendanceDayStatus?.data?.data;
};

export const getDesignationByRole = async (type) => {
  const designationByRole = await api("NODE_API_URL").get(
    `metadata/type?type=${type}&status=active`
  );
  return designationByRole?.data?.data;
};

export const getManagers = async (userId) => {
  const getByManagers = await api("NODE_API_URL").get(
    `employee/role?role=Manager&userId=${userId}`
  );
  return getByManagers?.data?.data;
};

export const getDepartments = async () => {
  const getByDepartments = await api("NODE_API_URL").get(
    `metadata/type?type=Departments&status=active`
  );
  return getByDepartments?.data?.data;
};
export const getUtilization = async () => {
  const getByDepartments = await api("NODE_API_URL").get(
    `metadata/type?type=utilization&status=active`
  );
  return getByDepartments?.data?.data;
};

export const getProjectList = async (userId) => {
  const projects = await api("NODE_API_URL").get(
    `project/all?userId=${userId}`
  );
  return projects?.data;
};

export const getEmploymentType = async () => {
  const getEmploymentType = await api("NODE_API_URL").get(
    `metadata/type?type=EmploymentType&status=active`
  );
  return getEmploymentType?.data?.data;
};

export const getCoreTechnology = async () => {
  const getByManagers = await api("NODE_API_URL").get(
    `metadata/type?type=Core Technology&status=active`
  );
  return getByManagers?.data?.data;
};

export const getUtilizationPercentage = async () => {
  const utilization = await api("NODE_API_URL").get(
    `metadata/type?type=UtilizationPercentage&status=active`
  );
  return utilization?.data?.data;
};

export const getBillableType = async () => {
  const billableType = await api("NODE_API_URL").get(
    `metadata/type?type=BillableType&status=active`
  );
  return billableType?.data?.data;
};

export const getLeaveStructure = async () => {
  const leaveStructure = await api("NODE_API_URL").get(
    `metadata/type?type=Leave Structure&status=active`
  );
  return leaveStructure?.data?.data;
};

export const getQuarterMonthList = async () => {
  const quarterMonthList = await api("NODE_API_URL").get(
    `metadata/type?type=Quarter&status=active`
  );
  return quarterMonthList?.data?.data;
};

export const getTeamQuarterList = async () => {
  const quarterData = await api("NODE_API_URL").get(
    `quartertype?type=Performance`
  );
  return quarterData?.data?.data;
};

export const getFrameWork = async (type) => {
  const getByDesignations = await api("NODE_API_URL").get(
    `metadata/type?type=${type}&status=active`
  );
  return getByDesignations?.data?.data;
};

export const getProjects = async (type) => {
  const getByDesignations = await api("NODE_API_URL").get(
    `metadata/type?type=Assigned projects&status=active`
  );
  return getByDesignations?.data?.data;
};

export const putAssignmentInfo = async (userId, assignmentId, payload) => {
  await api("NODE_API_URL").put(
    `assignment?userId=${userId}&assignmentId=${assignmentId}`,
    payload
  );
};

export const postAssetInfo = async (payload) => {
  await api("NODE_API_URL").put(`asset`, payload);
};

export const getAsset = async (userId, employeeId) => {
  const assets = await api("NODE_API_URL").get(
    `asset/employee?userId=${userId}&employeeId=${employeeId}`
  );
  return assets?.data;
};

export const putAssetInfo = async (userId, assetId, payload) => {
  await api("NODE_API_URL").put(
    `asset?userId=${userId}&assetId=${assetId}`,
    payload
  );
};
