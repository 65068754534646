import DataGridComponent from "../../../Common/DataGridComponent";
import Typography from "@mui/material/Typography";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLoader, useNotification } from "../../../Common/CommonUtils";
import { DataMainGrid } from "../../Employees/Employee.Styled";
import { intialPageSize } from "../../../Common/Constant";
import { getLeaveDataGridUtils } from "./LeaveDataGridUtils";
import { getLeaveDetails, getLeaves } from "../services/LeaveApi";
import { useDispatch, useSelector } from "react-redux";
import { AddButtonGrid } from "../../Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/EducationAndCertification.Styled";
import ApplyLeave from "./ApplyLeave";
import { MuiCard } from "../../../Common/Common.styled";
import {
  setApplyLeaveForm,
  setIsMyLeave,
  setIsNewApplyLeaveForm,
} from "../store/applyLeavesSlice/applyLeaveSlice";
import { Box } from "@mui/system";
import LensIcon from "@mui/icons-material/Lens";
import { ApplyLeaveButton } from "./Leave.styled";
import {
  setPage,
  setPageSize,
} from "../../../Common/store/paginationSlice/paginationSlice";
import useDebounceEffect from "../../../Common/CustomHooks/useDebounceEffect";

const LeaveDataGrid = () => {
  const dispatch = useDispatch();
  const [popUpActions, setPopUpActions] = useState("");
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const [leaveDetails, setLeaveDeatils] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = leaveDetails;

  const columnsData = [
    {
      field: "applied_date_time",
      headerName: "Request Date",
      width: 160,
    },
    {
      field: "request_type",
      headerName: "Leave Type",
      width: 160,
    },
    {
      field: "from_date",
      headerName: "From Date ",
      width: 160,
    },
    {
      field: "to_date",
      headerName: " To Date",
      width: 160,
    },
    {
      field: "total_days_leave_applied",
      headerName: "Days",
      width: 100,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 160,
    },
    {
      field: "request_status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        const status = params.value.toLowerCase().includes("pending")
          ? "Pending"
          : params.value;

        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LensIcon
              sx={{
                color:
                  status.toLowerCase() === "approved"
                    ? "green"
                    : status.toLowerCase() === "pending"
                    ? "orange"
                    : "red",
                fontSize: "10px",
              }}
            />
            <Typography
              sx={{
                marginLeft: "8px",
                color:
                  status.toLowerCase() === "approved"
                    ? "green"
                    : status.toLowerCase() === "pending"
                    ? "orange"
                    : "red",
              }}
            >
              {status}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "commment",
      headerName: "Comments",
      width: 160,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 160,
      align: "center",
      getActions: (params) => {
        const { request_status } = params.row;
        const actionsButtons =
          request_status.toLowerCase() === "pending" ||
          request_status.toLowerCase() === "pending by manager"
            ? "Edit"
            : "View";
        const actions = [];
        actions.push(
          <GridActionsCellItem
            label={actionsButtons}
            onClick={() => handleEditLeave(params, actionsButtons)}
            showInMenu
          />
        );
        !(
          request_status.toLowerCase() === "cancelled" ||
          request_status.toLowerCase() === "rejected"
        ) &&
          actions.push(
            <GridActionsCellItem
              label={"Cancel"}
              onClick={() => handleCancelLeave(params, "cancel")}
              showInMenu
            />
          );
        return actions;
      },
    },
  ];

  const handleEditLeave = async (params, action = "") => {
    const { row } = params;
    try {
      dispatch(setIsMyLeave(true));
      const leaveDetails = await getLeaveDetails(row.applied_leave_id);
      dispatch(setApplyLeaveForm(leaveDetails));
      if (
        row.request_status === "Pending" ||
        row.request_status === "Pending By Manager" ||
        action === "cancel"
      ) {
        dispatch(setIsMyLeave(true));
      }
      setIsDialogOpen(true);

      setPopUpActions(
        action === "Edit" ? "Edit" : action === "cancel" ? "Cancel" : "View"
      );
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleApplyNewLeave = () => {
    dispatch(setIsNewApplyLeaveForm(true));
    dispatch(setIsMyLeave(true));
    setIsDialogOpen(true);
  };

  const handleCancelLeave = async (params, action) => {
    handleEditLeave(params, action);
    setPopUpActions("Cancel");
  };

  const handleApplyLeaveCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useDebounceEffect(
    () => {
      fetchLeavesData();
    },
    500,
    [page, pageSize]
  );

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const fetchLeavesData = async () => {
    try {
      setLoading(true);
      setLeaveDeatils({ ...leaveDetails, isLoading: true });
      const payload = {
        page: page,
        limit: pageSize,
      };

      const leavesData = await getLeaves(loginUserId, payload);

      const leavesListData = getLeaveDataGridUtils(leavesData?.leave_history);
      setLeaveDeatils((prev) => ({
        ...prev,
        rows: leavesListData,
        isLoading: false,
        totalItems: leavesListData?.pagination?.totalItems,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLeaveDeatils({ ...leaveDetails, isLoading: false });
    }
  };

  return (
    <>
      <AddButtonGrid
        container
        data-testid="certification-accordion-add-button-grid"
      >
        <ApplyLeaveButton
          variant="contained"
          onClick={() => handleApplyNewLeave()}
        >
          Apply Leave
        </ApplyLeaveButton>
      </AddButtonGrid>
      <ApplyLeave
        onClose={handleApplyLeaveCloseDialog}
        onOpen={isDialogOpen}
        popUpActions={popUpActions}
        fetchLeavesData={fetchLeavesData}
      />
      <DataMainGrid>
        <MuiCard>
          <DataGridComponent
            autoHeight
            columnsData={columnsData}
            rowData={leaveDetails.rows}
            rowCount={totalItems}
            isLoading={isLoading}
            page={page - 1}
            editable={true}
            handlePaginationChange={handlePaginationChange}
          />
        </MuiCard>
      </DataMainGrid>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};
export default LeaveDataGrid;
