import React from "react";
import Typography from "@mui/material/Typography";
import { useSideNav } from "../../../../Common/CommonUtils";
const HrAdminManagerPerformance = () => {
  useSideNav();
  return (
    <>
      <Typography>HrAdmin view is updating</Typography>
    </>
  );
};

export default HrAdminManagerPerformance;
