import Grid from "@mui/material/Grid";
import {
  abscondedOptions,
  genderOptions,
  maritalStatusOptions,
  nationalityOptions,
  statusOptions,
} from "../../../../../Common/Constant";
import { useSelector } from "react-redux";
import { FormTextField } from "../../../../../Common/FormTextField";
import { FormInputDate } from "../../../../../Common/FormDatePicker";
import FormDropDown from "../../../../../Common/FormDropDown";
import {
  usePermissionCustomHook,
  dropDownOptions,
} from "../../../../../Common/CommonUtils";
const PersonalInformationForm = ({
  control,
  errors,
  defaultValue,
  register,
  handleChange,
  editable,
}) => {
  const isManager = usePermissionCustomHook("Manager");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isReadOnly = isManager || isDeveloper;
  const metaData = useSelector((state) => state?.metaData);
  const { managers, departments, designations, branches, leaveStructure } =
    metaData || {};
  const departmentOptions = dropDownOptions(departments);
  const leaveStructureOptions = dropDownOptions(leaveStructure);
  const designationOptions = dropDownOptions(designations);
  const personalInformationForm = useSelector(
    (state) => state?.personalInformationForm
  );
  const { branchOffice } = personalInformationForm || {};
  const location = branchOffice?.split(", ");
  const findLocation = location?.[1]?.toLowerCase();

  const branchesOptions = dropDownOptions(branches);
  const managerOptionsList = managers?.map((item) => ({
    label: item.name,
    value: item.employeeId,
  }));

  return (
    <Grid container spacing={2} sx={{ paddingY: 2 }}>
      <Grid item xs={12} sm={4}>
        <FormTextField
          fullWidth
          name="employeeId"
          label="Employee ID"
          control={control}
          defaultValue={defaultValue?.employeeId}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputDate
          label="Joining Date"
          name="joiningDate"
          type="date"
          control={control}
          errors={!!errors?.joiningDate}
          helperText={errors?.joiningDate?.message}
          register={register("joiningDate", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormDropDown
          label="Status"
          name="status"
          control={control}
          options={statusOptions}
          errors={!!errors?.status}
          helperText={errors?.status?.message}
          register={register("status", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormTextField
          fullWidth
          name="name"
          label="Name"
          type="textOnly"
          control={control}
          errors={!!errors?.name}
          helperText={errors?.name?.message}
          defaultValue={defaultValue?.name}
          register={register("name", {
            onChange: handleChange,
          })}
          readOnly
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputDate
          name="dateOfBirth"
          label="Date Of Birth"
          type="date"
          control={control}
          errors={!!errors?.dateOfBirth}
          helperText={errors?.dateOfBirth?.message}
          defaultValue={defaultValue?.dateOfBirth}
          register={register("dateOfBirth", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormDropDown
          label="Gender"
          name="gender"
          control={control}
          options={genderOptions}
          errors={!!errors?.gender}
          helperText={errors?.gender?.message}
          register={register("gender", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormDropDown
          label="Nationality"
          name="nationality"
          control={control}
          errors={!!errors?.nationality}
          helperText={errors?.nationality?.message}
          options={nationalityOptions}
          register={register("nationality", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormDropDown
          label="Martial Status"
          name="maritalStatus"
          control={control}
          errors={!!errors?.maritalStatus}
          helperText={errors?.maritalStatus?.message}
          options={maritalStatusOptions}
          register={register("maritalStatus", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormTextField
          name="mobileNumber"
          label="Mobile Number"
          type="number"
          control={control}
          errors={!!errors?.mobileNumber}
          helperText={errors?.mobileNumber?.message}
          register={register("mobileNumber", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>

      {findLocation === "india" ? (
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="aadhaarNumber"
            label="Aadhar Number"
            control={control}
            errors={!!errors?.aadhaarNumber}
            helperText={errors?.aadhaarNumber?.message}
            register={register("aadhaarNumber", {
              onChange: handleChange,
            })}
            readOnly={!editable || isReadOnly}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <FormTextField
            name="ssnNumber"
            label="SSN Number"
            control={control}
            errors={!!errors?.ssnNumber}
            helperText={errors?.ssnNumber?.message}
            register={register("ssnNumber", {
              onChange: handleChange,
            })}
            readOnly={!editable || isReadOnly}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <FormTextField
          fullWidth
          name="passportNumber"
          label="Passport Number"
          control={control}
          errors={!!errors?.passportNumber}
          helperText={errors?.passportNumber?.message}
          register={register("passportNumber", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          label="Personal Email ID"
          name="personalEmailId"
          type="email"
          control={control}
          errors={!!errors?.personalEmailId}
          helperText={errors?.personalEmailId?.message}
          register={register("personalEmailId", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          fullWidth
          name="officialEmailId"
          type="email"
          label="Official Email ID"
          control={control}
          errors={!!errors?.officialEmailId}
          helperText={errors?.officialEmailId?.message}
          register={register("officialEmailId", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          fullWidth
          multiline
          name="presentAddress"
          label="Present Address"
          control={control}
          errors={!!errors?.presentAddress}
          helperText={errors?.presentAddress?.message}
          register={register("presentAddress", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          fullWidth
          multiline
          name="permanentAddress"
          label="Permanent Address"
          control={control}
          errors={!!errors?.permanentAddress}
          helperText={errors?.permanentAddress?.message}
          register={register("permanentAddress", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormTextField
          fullWidth
          name="panNumber"
          label="Pan Number "
          control={control}
          errors={!!errors?.panNumber}
          helperText={errors?.panNumber?.message}
          register={register("panNumber", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormTextField
          fullWidth
          name="emergencyContactPerson"
          label="Emergency Contact Person"
          type="text"
          control={control}
          errors={!!errors?.emergencyContactPerson}
          helperText={errors?.emergencyContactPerson?.message}
          register={register("emergencyContactPerson", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormTextField
          fullWidth
          multiline
          name="emergencyContactNumber"
          label="Emergency Contact Number"
          type="number"
          control={control}
          errors={!!errors?.emergencyContactNumber}
          helperText={errors?.emergencyContactNumber?.message}
          register={register("emergencyContactNumber", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropDown
          label="Designation"
          name="designation"
          control={control}
          options={designationOptions}
          errors={!!errors?.designation}
          helperText={errors?.designation?.message}
          register={register("designation", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropDown
          label="Department"
          name="department"
          control={control}
          options={departmentOptions}
          errors={!!errors?.department}
          helperText={errors?.department?.message}
          register={register("department", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropDown
          name="reportingManager"
          label="Reporting Manager"
          control={control}
          errors={!!errors?.reportingManager}
          helperText={errors?.reportingManager?.message}
          options={managerOptionsList}
          register={register("reportingManager", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>

      <Grid item xs={4}>
        <FormDropDown
          label="Branch Office"
          name="branchOffice"
          control={control}
          options={branchesOptions}
          errors={!!errors?.branchOffice}
          helperText={errors?.branchOffice?.message}
          register={register("branchOffice", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormDropDown
          fullWidth
          name="leaveStructure"
          label="Leave Structure"
          control={control}
          options={leaveStructureOptions}
          errors={!!errors?.leaveStructure}
          helperText={errors?.leaveStructure?.message}
          register={register("leaveStructure", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormDropDown
          label="Isabsconded"
          name="isAbsconded"
          control={control}
          options={abscondedOptions}
          errors={!!errors?.isAbsconded}
          helperText={errors?.isAbsconded?.message}
          register={register("isAbsconded", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputDate
          name="resignedDate"
          label="Resignation Date"
          type="date"
          control={control}
          errors={!!errors?.resignedDate}
          helperText={errors?.resignedDate?.message}
          register={register("resignedDate", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputDate
          name="relievedDate"
          label="Relieved Date"
          type="date"
          control={control}
          errors={!!errors?.relievedDate}
          helperText={errors?.relievedDate?.message}
          register={register("relievedDate", {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
          minDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputDate
          label="Created Date Time"
          name="createdDateTime"
          type="date"
          control={control}
          errors={!!errors?.createdDateTime}
          helperText={errors?.createdDateTime?.message}
          register={register("createdDateTime", {
            onChange: handleChange,
          })}
          readOnly
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputDate
          label="Updated Date Time"
          name="updatedDateTime"
          control={control}
          errors={!!errors?.updatedDateTime}
          helperText={errors?.updatedDateTime?.message}
          type="date"
          register={register("updatedDateTime", {
            onChange: handleChange,
          })}
          readOnly
        />
      </Grid>
    </Grid>
  );
};
export default PersonalInformationForm;
