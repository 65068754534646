import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import {
  DataMainGrid,
  StyledGridContainer,
} from "../../../Employees/Employee.Styled";
import { intialPageSize } from "../../../../Common/Constant";
import { AddButtonGrid } from "../../../Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/EducationAndCertification.Styled";
import DataGridComponent from "../../../../Common/DataGridComponent";
import {
  useLoader,
  useNotification,
  usePermissionCustomHook,
} from "../../../../Common/CommonUtils";
import { getLeaveDetails, getLeavesRequests } from "../../services/LeaveApi";
import ApplyLeave from "../ApplyLeave";
import LensIcon from "@mui/icons-material/Lens";
import { useDispatch, useSelector } from "react-redux";
import { MuiCard } from "../../../../Common/Common.styled";
import { getLeaveRequestDataGridUtils } from "./LeavesRequestDataUtils";
import {
  ApplyLeaveButton,
  HolidayGrid,
  LeaveTypography,
} from "../Leave.styled";
import {
  setApplyLeaveForm,
  setIsMyTeamLeave,
} from "../../store/applyLeavesSlice/applyLeaveSlice";
import { Box } from "@mui/system";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";
import useDebounceEffect from "../../../../Common/CustomHooks/useDebounceEffect";

const ManagerViewDataGrid = () => {
  const dispatch = useDispatch();
  const [teamsLeaves, setTeamsLeaves] = useState(false);
  const [teamsLeavesApply, setTeamsLeavesApply] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const [leaveDetails, setLeaveDeatils] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isHR = usePermissionCustomHook("HR");

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = leaveDetails;

  const columnsData = [
    {
      field: "employeeName",
      headerName: "Name",
      width: 140,
    },
    {
      field: "appliedDate",
      headerName: "Request Date",
      width: 130,
    },
    {
      field: "leaveName",
      headerName: "Leave Type",
      width: 140,
    },
    {
      field: "fromDate",
      headerName: "From Date",
      width: 140,
    },
    {
      field: "toDate",
      headerName: " To Date",
      width: 140,
    },
    {
      field: "totalDays",
      headerName: "Days",
      width: 130,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 170,
    },
    {
      field: "status",
      headerName: "Status",
      width: 140,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LensIcon
            sx={{
              color:
                params.value.toLowerCase() === "approved"
                  ? "green"
                  : params.value.toLowerCase() === "pending" ||
                    params.value.toLowerCase() === "pending by manager"
                  ? "orange"
                  : "red",
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              marginLeft: "8px",
              color:
                params.value.toLowerCase() === "approved"
                  ? "green"
                  : params.value.toLowerCase() === "pending" ||
                    params.value.toLowerCase() === "pending by manager"
                  ? "orange"
                  : "red",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actionTakenBy",
      headerName: "Action By",
      width: 130,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return (
          <Button variant="text" onClick={() => handleViewLeave(params)}>
            View
          </Button>
        );
      },
    },
  ];

  const handleViewLeave = async (params) => {
    const { row } = params;
    try {
      setLoading(true);
      setTeamsLeaves(true);
      const leaveDetails = await getLeaveDetails(row.leaveId);
      dispatch(setApplyLeaveForm(leaveDetails));
      dispatch(setIsMyTeamLeave(row.status.toLowerCase() === "pending"));
      setIsDialogOpen(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleApplyNewLeave = () => {
    // setSnackbarOpen(false);
    //dispatch(resetApplyLeaveForm());
    //dispatch(setIsNewApplyLeaveForm(true));
    setIsDialogOpen(true);
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleApplyLeaveCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useDebounceEffect(
    () => {
      fetchLeavesData();
      setTeamsLeavesApply(true);
    },
    500,
    [page, pageSize]
  );

  const fetchLeavesData = async () => {
    try {
      setLeaveDeatils({ ...leaveDetails, isLoading: true });
      const payload = {
        user_id: loginUserId,
        page: page,
        limit: pageSize,
      };

      const leavesData = await getLeavesRequests(payload);

      const leavesListData = getLeaveRequestDataGridUtils(leavesData?.data);
      setLeaveDeatils((prev) => ({
        ...prev,
        rows: leavesListData,
        isLoading: false,
        totalItems: leavesListData?.pagination?.totalItems,
      }));
    } catch (error) {
      setLeaveDeatils({ ...leaveDetails, isLoading: false });
    }
  };

  return (
    <>
      {isHR && (
        <>
          <StyledGridContainer
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <LeaveTypography variant="h3">Leave Requests</LeaveTypography>
          </StyledGridContainer>
          <HolidayGrid style={{ marginTop: "16px" }}></HolidayGrid>
        </>
      )}
      {!isHR ||
        (!teamsLeavesApply && (
          <AddButtonGrid
            container
            data-testid="certification-accordion-add-button-grid"
          >
            <ApplyLeaveButton
              variant="contained"
              onClick={() => handleApplyNewLeave()}
            >
              Apply Leave
            </ApplyLeaveButton>
          </AddButtonGrid>
        ))}
      <ApplyLeave
        onClose={handleApplyLeaveCloseDialog}
        onOpen={isDialogOpen}
        teamsLeaves={teamsLeaves}
        setTeamsLeaves={setTeamsLeaves}
        teamsLeavesApply={teamsLeavesApply}
        setTeamsLeavesApply={setTeamsLeavesApply}
        fetchLeavesData={fetchLeavesData}
      />
      <DataMainGrid>
        <MuiCard>
          <DataGridComponent
            autoHeight
            columnsData={columnsData}
            rowData={leaveDetails.rows}
            rowCount={totalItems}
            isLoading={isLoading}
            page={page - 1}
            editable={true}
            handlePaginationChange={handlePaginationChange}
          />
        </MuiCard>
      </DataMainGrid>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};
export default ManagerViewDataGrid;
