import React, { useState } from "react";
import DataGridComponent from "../../../Common/DataGridComponent";
import { useSideNav } from "../../../Common/CommonUtils";
import FormDropDown from "../../../Common/FormDropDown";
import Grid from "@mui/material/Grid";
import { useTheme } from "@emotion/react";
import { useForm } from "react-hook-form";
import {
  TimeSheetText,
  TimeSheetGrid,
  RightGid,
  CalenderGrid,
  CalenderIcon,
} from "./ViewTimesheet.styled";
import {
  StatusGrid,
  StyledLensIcon,
  StyledTypography,
} from "../../Performance/Components/MyPerformance.styled";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DayCalendar } from "../../../Common/CalenderMonthWeek";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { CalendarMonthIconContainer } from "../../Attendance/Components/LogAttendance.styled";

const ViewTimesheet = () => {
  useSideNav();
  const theme = useTheme();
  const [optionDropdown, setOptionDropdown] = useState("");
  const [calendarAnchor, setCalendarAnchor] = useState(null);

  const useFunction = useForm({
    mode: "onChange",
  });
  const { control, reset, register } = useFunction;

  const weekData = [{ id: 1, value: "week", label: "Week" }];

  const columnData = [
    {
      field: "project",
      headerName: "Project Name",
      width: 250,
    },
    { field: "date", headerName: "Date", width: 180 },
    {
      field: "totalHours",
      headerName: " Total Hours worked for week",
      width: 230,
      align: "center",
    },
    { field: "manager", headerName: "Manager", width: 260 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => {
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const formattedStatus = capitalize(params?.row?.status);
        const value =
          formattedStatus === "Approved"
            ? theme.palette.success.main
            : theme.palette.warning.main;
        return (
          <StatusGrid>
            <StyledLensIcon color={value} />
            <StyledTypography color={value}>{formattedStatus}</StyledTypography>
          </StatusGrid>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 160,
      align: "center",
      getActions: (params) => {
        return [
          <GridActionsCellItem label="View" showInMenu />,
          <GridActionsCellItem label="Edit" showInMenu />,
          <GridActionsCellItem label="Send Reminder" showInMenu />,
        ];
      },
    },
  ];

  const rowData = [
    {
      id: 1,
      project: "Hyper",
      date: "12-19-2024",
      score: 10,
      manager: "Vamsi",
      status: "Approved",
      totalHours: "6",
    },
    {
      id: 2,
      project: "Employee Portal",
      date: "12-20-2024",
      score: 40,
      manager: "Ramarao",
      status: "Pending",
      totalHours: "6",
    },
    {
      id: 3,
      project: "All ways track",
      date: "12-21-2024",
      score: 30,
      manager: "Ramarao",
      status: "Pending",
      totalHours: "6",
    },
    {
      id: 4,
      project: "Victory capital",
      date: "12-22-2024",
      score: 10,
      manager: "Ramarao",
      status: "Approved",
      totalHours: "8",
    },
    {
      id: 5,
      project: "Hyper",
      date: "12-23-2024",
      score: 11,
      manager: "Ramarao",
      status: "Approved",
      totalHours: "6",
    },
    {
      id: 6,
      project: "Hyper",
      date: "12-24-2024",
      score: 11,
      manager: "Ramarao",
      status: "Approved",
      totalHours: "9",
    },
    {
      id: 7,
      project: "Hyper",
      date: "12-25-2024",
      score: 11,
      manager: "Ramarao",
      status: "Approved",
      totalHours: "8",
    },
    {
      id: 8,
      project: "Victory capital",
      date: "12-26-2024",
      score: 10,
      manager: "Ramarao",
      status: "Approved",
      totalHours: "8",
    },
  ];

  const handleCalendarIconClick = (event) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchor(null);
  };

  const handleDateChange = (params) => {
    console.log(params);
  };
  const open = Boolean(calendarAnchor);

  return (
    <>
      <TimeSheetGrid container spacing={2} mt={1}>
        <Grid item xs={12} sm={6}>
          <TimeSheetText>Weekly Timesheet</TimeSheetText>
        </Grid>
        <RightGid item xs={12} sm={6} container spacing={1}>
          <Grid item xs={6} sm={4} md={3}>
            <FormDropDown
              label="Week"
              name="optionDropdown"
              options={weekData}
              control={control}
              register={register("optionDropdown", {
                onChange: (e) => {
                  setOptionDropdown(e.target.value);
                },
              })}
            />
          </Grid>
          <CalenderGrid item xs={4} sm={4} md={2}>
            <IconButton size="small" onClick={handleCalendarIconClick}>
              <CalendarMonthIconContainer />
            </IconButton>
            <Popover
              open={open}
              anchorEl={calendarAnchor}
              onClose={handleCalendarClose}
            >
              <DayCalendar onDateSelect={handleDateChange} />
            </Popover>
          </CalenderGrid>
        </RightGid>
      </TimeSheetGrid>

      <DataGridComponent
        columnsData={columnData}
        rowData={rowData}
        autoHeight
        pageSizeValue={10}
        editable={true}
      />
    </>
  );
};

export default ViewTimesheet;
