import DataGridComponent from "../../Common/DataGridComponent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
// import EmployeeBranchDropdown from "./EmployeeBranchDropdown";
import { useEffect, useState } from "react";
import ClearFilterButton from "./ClearFilterButton";
import EmployeeDesignationDropdown from "./EmployeeDesignationDropdown";
import {
  useLoader,
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../Common/CommonUtils";
import {
  DataMainGrid,
  EmployeeDownloadIconGrid,
  EmployeeSearchGrid,
  NameValue,
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledContainer,
  StyledContainerGrid,
  StyledGridContainer,
} from "./Employee.Styled";
import { useNavigate } from "react-router-dom";
import CreateEmployee from "./CreateEmployee";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployees,
  getEmployee,
  postEmployeesDownload,
} from "./Services/service";
import { getEmployeeListData } from "./EmployeeDataGridUtils";
import {
  setEmployeeId,
  setEmployeeRole,
} from "./store/employeeIdSlice/employeeIdSlice";
import { useForm } from "react-hook-form";
import useDebounceEffect from "../../Common/CustomHooks/useDebounceEffect";
import { FormInputSearch } from "../../Common/FormInputSearch";
import {
  setPage,
  setPageSize,
} from "../../Common/store/paginationSlice/paginationSlice";
import { downloadOptions } from "../../Common/Constant";
import { DownloadAttendence } from "../Attendance/Components/LogAttendance.styled";
import DownloadDropdown from "../../Common/DownloadDropdown";

const Employee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useSideNav();
  const { setLoading, LoaderPopup } = useLoader();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [searchQuery, setSearchQuery] = useState("");
  const [openCreateEmployeeDialog, setOpenCreateEmployeeDialog] =
    useState(false);
  const [branchSelection, setBranchSelection] = useState([]);
  const [designationSelection, setDesignationSelection] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [employeeDetails, setEmployeeDeatils] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });

  const { isLoading, totalItems } = employeeDetails;

  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;

  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");
  const isParticularEmployee = isDeveloper || isManager;
  // const isEmployeeInfo = true;
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const defaultValue = {
    designation: [],
    branch: [],
  };

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  useEffect(() => {
    setIsFilterSelected(
      searchQuery.length > 0 || designationSelection.length > 0
    );
  }, [searchQuery, designationSelection]);

  const handleViewDetails = async (params) => {
    const { row } = params;
    navigate(`./view-details-page/${row.employeeId}`);
    dispatch(setEmployeeId(row.employeeId));
    dispatch(setEmployeeRole(row.role));
  };

  const columnsData = [
    {
      field: "employeeId",
      headerName: "Employee ID",
      width: 160,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        const fullName = `${params.row.firstName} ${params.row.lastName}`;
        return <NameValue>{fullName}</NameValue>;
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
    },
    {
      field: "department",
      headerName: "Department",
      width: 200,
    },
    {
      field: "branchOffice",
      headerName: "Branch",
      width: 200,
    },

    {
      width: 200,

      renderCell: (params) => {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => handleViewDetails(params)}
            >
              View Details
            </Button>
          </>
        );
      },
    },
  ];

  const clearSelection = () => {
    reset(defaultValue);
    setBranchSelection([]);
    setSearchQuery("");
    setDesignationSelection([]);
  };

  const handleCloseCreateEmployeeDialog = () => {
    setOpenCreateEmployeeDialog(false);
  };

  useDebounceEffect(
    () => {
      fetchEmployeeData();
    },
    500,
    [searchQuery, page, pageSize, designationSelection, branchSelection]
  );

  const fetchEmployeeData = async () => {
    try {
      setLoading(true);
      const payload = {
        userId: loginUserId,
        page: page,
        limit: pageSize,
        ...(searchQuery && { search: searchQuery }),
        ...(designationSelection.length > 0 && {
          designation: designationSelection.join(", "),
        }),
        ...(branchSelection.length > 0 && {
          branchOffice: branchSelection.join(", "),
        }),
      };
      setEmployeeDeatils({ ...employeeDetails, isLoading: true });

      const getEmploeeDetails = isParticularEmployee
        ? getEmployee(loginUserId, loginUserId)
        : getEmployees(payload);

      const employeeDetailsList = await getEmploeeDetails;

      const employeeDetailsData = isParticularEmployee
        ? [employeeDetailsList?.data]
        : employeeDetailsList?.data;

      const formattedData = getEmployeeListData(employeeDetailsData);
      setEmployeeDeatils((prev) => ({
        ...prev,
        rows: formattedData,
        totalItems: employeeDetailsList?.pagination?.totalItems,
        isLoading: false,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
      setEmployeeDeatils({ ...employeeDetails, isLoading: false });
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleDesignation = (e) => {
    setDesignationSelection(e.target.value);
  };

  const handleDownloadChange = async (option) => {
    try {
      setLoading(true);
      if (option.value === "excel") {
        const employeesDownload = await postEmployeesDownload(loginUserId);
        const blob = new Blob([employeesDownload]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Employee_Report_${new Date().toISOString().split("T")[0]}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "An error occurred while fetching employee data."
      );
    }
  };

  return (
    <>
      <StyledGridContainer
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" style={{ marginLeft: "16px" }}>
          Employees
        </Typography>
        {isHR && (
          <StyledContainerGrid item>
            <Button
              endIcon={
                <SvgIcon fontSize="small">
                  <AddIcon />
                </SvgIcon>
              }
              variant="contained"
              onClick={() => setOpenCreateEmployeeDialog(true)}
            >
              Add Employee
            </Button>
          </StyledContainerGrid>
        )}
        {openCreateEmployeeDialog && (
          <CreateEmployee
            onClose={handleCloseCreateEmployeeDialog}
            onOpen={openCreateEmployeeDialog}
            fetchEmployeeData={fetchEmployeeData}
          />
        )}
      </StyledGridContainer>
      <StyledCard
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
        }}
      >
        <StyledCardContent container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledCardContainer container spacing={2}>
              <Grid item xs={8} sm={5} mt={1}>
                <FormInputSearch
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  placeholder="Search Employee"
                />
              </Grid>
              <Grid item xs={4} sm={3} textAlign={"center"}>
                {isFilterSelected && (
                  <ClearFilterButton clearSelection={clearSelection} />
                )}
              </Grid>

              {/* <Grid item xs={2}>
            <EmployeeBranchDropdown
              control={control}
              register={register}
              handleBranch={handleBranch}
            />
          </Grid> */}
            </StyledCardContainer>
          </Grid>
          <Grid item xs={12} sm={6} alignItems="center">
            <StyledContainer container spacing={2}>
              <Grid item xs={9} sm={5}>
                <EmployeeDesignationDropdown
                  control={control}
                  register={register}
                  handleDesignation={handleDesignation}
                />
              </Grid>
              <EmployeeDownloadIconGrid item xs={3} sm={3} textAlign="left">
                {isHR && (
                  <DownloadAttendence>
                    <DownloadDropdown
                      options={downloadOptions}
                      handleChange={handleDownloadChange}
                      isTop={true}
                    />
                  </DownloadAttendence>
                )}
              </EmployeeDownloadIconGrid>
            </StyledContainer>
          </Grid>
        </StyledCardContent>
      </StyledCard>
      <DataMainGrid>
        <Card
          style={{
            // padding: '8px 16px',
            boxShadow:
              "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
          }}
        >
          <DataGridComponent
            columnsData={columnsData}
            rowData={employeeDetails?.rows}
            isLoading={isLoading}
            rowCount={totalItems}
            page={page - 1}
            handlePaginationChange={handlePaginationChange}
          />
        </Card>
      </DataMainGrid>
      <LoaderPopup />
      <NotificationPopup />
    </>
  );
};
export default Employee;
