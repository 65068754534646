import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import { Box } from "@mui/material";
import { IconContainer } from "./Common.styled";

const ITEM_HEIGHT = 48;

const DownloadDropdown = (props) => {
  const { options, handleChange ,isTop } = props || "";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChange = (option) => {
    handleChange(option);
    handleClose();
  };

  return (
    <>
      <IconContainer isTop={isTop} onClick={handleClick}>
        <DownloadIcon />
      </IconContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => onChange(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DownloadDropdown;
