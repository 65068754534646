import TabPanel, { a11yProps } from "../../../../Common/TabPanel/TabPanel";
import {
  MuiCompactMarginTab,
  MuiLargeSpacingTab,
  StickyTab,
  TabContainer,
} from "../../../../Common/Common.styled";
import LeaveDataGrid from "../../components/LeaveDataGrid";
import ManagerViewDataGrid from "./ManagerViewDataGrid";
import { ManagerViewGrid } from "../Leave.styled";
import { useDispatch, useSelector } from "react-redux";
import { resetPage } from "../../../../Common/store/paginationSlice/paginationSlice";
import { setTabValue } from "../../../../Common/store/tabSlice/tabSlice";

const ManagerViewTabs = () => {
  const dispatch = useDispatch();
  const tabValue = useSelector((state) => state.tabInfo?.tabValue);

  const handleTabChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
    dispatch(resetPage());
  };

  return (
    <>
      <ManagerViewGrid></ManagerViewGrid>
      <TabContainer>
        <StickyTab
          value={tabValue}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          <MuiCompactMarginTab label="My Leaves" {...a11yProps(0)} />
          <MuiLargeSpacingTab label="Team Leaves" {...a11yProps(1)} />
        </StickyTab>
      </TabContainer>
      <TabPanel value={tabValue} index={0}>
        <LeaveDataGrid />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ManagerViewDataGrid />
      </TabPanel>
    </>
  );
};

export default ManagerViewTabs;
