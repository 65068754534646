import React from "react";
import EmployeePortalDialog from "../../../../Common/EmployeePortalDialog";
import MyAssignmentForm from "./MyAssignmentForm";
import { useSelector } from "react-redux";

const MyAssignmentDialog = (props) => {
  const {
    onClose,
    onOpen,
    selectedValue,
    fetchMyAssignment,
    totalUtilization,
  } = props;
  const isMyAssignmentForm = useSelector(
    (state) => state?.myAssignmentForm?.isMyAssignmentForm
  );

  return (
    <>
      <EmployeePortalDialog
        title={isMyAssignmentForm ? "Allocate Project" : "Edit Project"}
        onClose={onClose}
        open={onOpen}
        customWidth={"690px"}
        customHeight={"50%"}
        selectedValue={selectedValue}
      >
        <MyAssignmentForm
          onCancel={onClose}
          selectedValue={selectedValue}
          totalUtilization={totalUtilization}
          fetchMyAssignment={fetchMyAssignment}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default MyAssignmentDialog;
