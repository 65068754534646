import React from "react";
import HynivaLogo from "../Assets/Images/HynivaLogoArrow.png";
import {
  LoaderContainer,
  SpinnerContainer,
  SpinnerLogo,
  SpinnerProgress,
} from "./Common.styled";

const CustomLoader = () => {
  return (
    <LoaderContainer>
      <SpinnerContainer>
        <SpinnerProgress size={60} thickness={2} />
        <SpinnerLogo component="img" src={HynivaLogo} alt="logo" />
      </SpinnerContainer>
    </LoaderContainer>
  );
};

export default CustomLoader;
