import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import FormDropDown from "../../../Common/FormDropDown";
import { FormTextField } from "../../../Common/FormTextField";
import FormTimePicker from "../../../Common/FormTimePicker ";
import {
  RenderRejectApproveButtons,
  useNotification,
} from "../../../Common/CommonUtils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";
import Grid from "@mui/material/Grid";
import { FormInputDate } from "../../../Common/FormDatePicker";
import { GridForm } from "../../../Common/Ui/styled";
import { requiredField } from "../../../Common/Constant";
import { FormInputSwitch } from "../../../Common/FormInputSwitch";
import { FormTypo } from "../../Attendance/Components/LogAttendance.styled";
import { LeaveText, SwitchGrid } from "./HrAction.styled";

const HrActionForm = (props) => {
  const { requestData, onCancel } = props;
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  // const [categoryOptionsList, setCategoryOptionsList] = useState([]);
  const { showSnackbar } = useSnackbar();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { type } = requestData;

  const ActionSchema = yup.object().shape({
    checkInDate:
      type === "Attendance"
        ? yup.string().required(requiredField).nullable()
        : "",
    checkOutDate:
      type === "Attendance"
        ? yup.string().required(requiredField).nullable()
        : "",
    checkInTime:
      type === "Attendance"
        ? yup.string().required(requiredField).nullable()
        : "",
    checkOutTime:
      type === "Attendance"
        ? yup.string().required(requiredField).nullable()
        : "",
    fromDate:
      type === "Leave" ? yup.string().required(requiredField).nullable() : "",
    toDate:
      type === "Leave" ? yup.string().required(requiredField).nullable() : "",
    reason: yup.string().required(requiredField),
    remarks: yup.string().required(requiredField),
  });

  const { control, formState, handleSubmit, watch, register } = useForm({
    mode: "onChange",
    resolver: yupResolver(ActionSchema),
    defaultValues: {
      type: requestData.type,
    },
  });

  const categoryOptionsList = [
    { value: "Early Check In", label: "Early Check In" },
    { value: "Early Check Out", label: "Early Check Out" },
    { value: "Forgot Check In", label: "Forgot Check In" },
    { value: "Forgot Check Out", label: "Forgot Check Out" },
    { value: "Late Check In", label: "Late Check In" },
    { value: "Late Check Out", label: "Late Check Out" },
    { value: "New Joinee", label: "New Joinee" },
  ];

  const { errors, isValid } = formState;

  const handleApproveReject = () => {
    onCancel();
  };

  const handleReject = () => {
    onCancel();
  };

  const lableText =
    type === "Leave"
      ? "Leave type"
      : type === "Attendance"
      ? "Attendance Type"
      : "Doument";

  return (
    <>
      <GridForm container spacing={2}>
        <Grid item xs={12}>
          <FormDropDown
            name="reason"
            label={lableText}
            control={control}
            errors={!!errors?.reason}
            helperText={errors?.reason?.message}
            options={categoryOptionsList}
          />
        </Grid>
        {type === "Attendance" && (
          <>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name="checkInDate"
                control={control}
                label="Check In Date"
                helperText={errors?.checkInDate?.message}
                errors={!!errors.checkInDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTimePicker
                name="checkInTime"
                control={control}
                label="Check In Time**"
                format="HH:mm"
                errors={!!errors.checkInTime}
                helperText={errors.checkInTime?.message}
                showClockView
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name="checkOutDate"
                control={control}
                label="Check Out Date"
                helperText={errors?.checkOutDate?.message}
                errors={!!errors.checkOutDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTimePicker
                name="checkOutTime"
                control={control}
                label="Check Out Time**"
                format="HH:mm"
                errors={!!errors.checkOutTime}
                helperText={errors.checkOutTime?.message}
                showClockView
              />
            </Grid>
          </>
        )}
        {type === "Leave" && (
          <>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name="fromDate"
                control={control}
                label="From Date"
                helperText={errors?.fromDate?.message}
                errors={!!errors.fromDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputDate
                name="toDate"
                control={control}
                label="To Date"
                helperText={errors?.toDate?.message}
                errors={!!errors.toDate}
              />
            </Grid>
            <SwitchGrid container>
              <FormInputSwitch
                control={control}
                name="hasHalfDay"
                label="Half Day"
              />
            </SwitchGrid>
          </>
        )}

        <Grid item xs={12}>
          <FormTextField
            name="remarks"
            label="Reasons**"
            rows={2}
            multiline
            control={control}
            errors={!!errors?.remarks}
            helperText={errors?.remarks?.message}
          />
        </Grid>
        {type === "Attendance" && (
          <Grid item xs={12}>
            <FormTypo>Time Hours:-</FormTypo>
          </Grid>
        )}

        <Grid item xs={12}>
          <FormTextField
            name="comment"
            label="Comment**"
            rows={2}
            multiline
            control={control}
            errors={!!errors?.comment}
            helperText={errors?.comment?.message}
          />
        </Grid>
        {type === "Leave" && (
          <LeaveText>No. of Days Leave Applicable -</LeaveText>
        )}
      </GridForm>
      <RenderRejectApproveButtons
        handleReject={handleSubmit(handleReject)}
        handleApproveReject={handleSubmit(handleApproveReject)}
        rejectLoading={rejectLoading}
        approveLoading={approveLoading}
      />
      <NotificationPopup />
    </>
  );
};

export default HrActionForm;
