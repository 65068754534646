import { createSlice } from '@reduxjs/toolkit';
import { _setSnackbar } from './snackbarAction';

export const snackbarSlice = createSlice({
  name: 'ep/snackbarSlice',
  initialState: null,
  reducers: {
    setSnackbar: _setSnackbar,
    resetSnackbar: () => null,
  },
});
export const { setSnackbar, resetSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;