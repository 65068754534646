import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export const TimeSheetText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1.3),
  fontSize: theme.spacing(2.3),
  fontWeight: 500,
}));

export const TimeSheetGrid = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const RightGid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CalenderGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(5),
}));

