import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

const MultiControlFormField = ({
  name,
  control,
  defaultValue,
  label,
  multiline,
  type,
  textOnly,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      variant={textOnly ? "standard" : "outlined"}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          type={type}
          fullWidth
          multiline={multiline}
          variant="outlined"
          sx={
            textOnly
              ? {
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none !important",
                    },
                    "&:hover fieldset": {
                      border: "none !important",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none !important",
                    },
                  },
                  "& .MuiInputBase-root": {
                    color: "inherit",
                    fontSize: "1rem",
                    padding: "0px",
                    cursor: "default",
                  },
                  "& .MuiInputLabel-root": {
                    display: "none",
                  },
                }
              : {
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "2px",
                    "& fieldset": {
                      borderWidth: "1px",
                    },
                    "&:hover fieldset": {
                      borderWidth: "1px",
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "1px",
                    },
                  },
                }
          }
          {...rest}
        />
      )}
    />
  );
};

export default MultiControlFormField;
