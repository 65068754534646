import React from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

export const SegmentedProgressBar = ({ value, data, colorMapping }) => {
  const total = Object.values(data).reduce((acc, count) => acc + count, 0);

  const segments = Object.keys(data).map((key) => {
    const percentage = (data[key] / total) * 100;
    const color = colorMapping[key] || "#000000";

    return {
      color,
      percentage,
      label: `${key
        .replace(/([A-Z])/g, " $1")
        .charAt(0)
        .toUpperCase()}${key.replace(/([A-Z])/g, " $1").slice(1)}: ${
        data[key]
      }`,
    };
  });

  return (
    <Grid container display="flex" width="95%" height={45}>
      {segments.map((segment, index) => {
        const segmentWidth = `${(segment.percentage / 100) * value}%`;

        return (
          <Tooltip key={index} title={segment.label} placement="top">
            <Grid
              item
              width={segmentWidth}
              bgcolor={segment.color}
              height="100%"
              style={{
                borderRadius:
                  index === 0
                    ? "5px 0 0 5px"
                    : index === segments.length - 1
                    ? "0 5px 5px 0"
                    : 0,
              }}
            />
          </Tooltip>
        );
      })}
    </Grid>
  );
};
