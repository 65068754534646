import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormDropDown from '../../../../../Common/FormDropDown';
import { FormInputDate } from '../../../../../Common/FormDatePicker';
import UploadDocument from '../EducationAndCertification/UploadDocument';
import { EmployeePortalActionDailog } from '../../../../../Common/EmployeePortalDialog.styled';
import { documentType } from '../../../../../Common/Constant';

const AddDocumentForm = (props) => {
  const {
    control,
    register,
    errors,
    documentNameOptions,
    handleSubmit,
    onSubmit,
    onCancel,
    formState,
    disablingButton,
  } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const isUploaded = selectedFiles.length !== 0 ? false : true;
  return (
    <>
      <Grid container style={{ margin: '0px 16px', width: 'auto' }}>
        <FormDropDown
          label="Document Type"
          name="documentType"
          errors={errors?.documentType}
          helperText={errors?.documentType?.message}
          control={control}
          options={documentType}
        />
        <FormDropDown
          label="Document Name"
          name="documentName"
          errors={errors?.documentName}
          helperText={errors?.documentName?.message}
          control={control}
          options={documentNameOptions}
        />
        <FormInputDate
          name="date"
          control={control}
          label="Update Date"
          readOnly={true}
        />
        <UploadDocument
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </Grid>
      <EmployeePortalActionDailog>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={disablingButton || isUploaded}
        >
          Save
        </Button>
      </EmployeePortalActionDailog>
    </>
  );
};

export default AddDocumentForm;
