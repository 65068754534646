import { styled } from "@mui/system";
import { Grid, Select, Typography, Box } from "@mui/material";

export const AttendanceTypo = styled(Typography)`
  font-size: 13px;
  font-weight: 600;
  width: 150%;
`;

export const AttendanceTypo2 = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;

export const BarContainor = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: ${(props) =>
    `${props.theme.palette.spacing[0] * 0}px ${
      props.theme.palette.spacing[1] * 1
    }px `};
  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    padding: ${(props) =>
      `${props.theme.palette.spacing[0] * 7}px ${
        props.theme.palette.spacing[1] * 1
      }px `};
  }
`;
export const BarHeader = styled(Grid)`
  display: flex;
  justifycontent: space-between;
  alignitems: center;
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;
`;

export const TotalHourBox = styled(Grid)`
  wdith: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  font-size: 0.7rem;
  min-width: 60px;
  height: 30px;
`;

export const StyledGridContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;
export const RightGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;
