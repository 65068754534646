import React from "react";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import {
  StyledHolidayListItem,
  NoHolidaysContainer,
  NoHolidaysTitle,
  StyledGrid,
} from "../../Leave/components/Leave.styled";
import {
  AttendanceMuiCard,
  LeaveGrid,
  ViewText,
} from "../../Dashboard/Components/Dashboard.styled";
import { PolicyGrid, Policytext, ViewTypo } from "./HrPolicy.styled";

const HrPolicy = () => {
  const policyList = [
    {
      id: 1,
      documentName: "Document 1",
      year: "2024.pdf",
    },
    {
      id: 2,
      documentName: "Document 2",
      year: "2024.pdf",
    },
    {
      id: 3,
      documentName: "Document 3",
      year: "2024.pdf",
    },
    {
      id: 4,
      documentName: "Document 4",
      year: "2024.pdf",
    },
    {
      id: 5,
      documentName: "Document 5",
      year: "2024.pdf",
    },
    {
      id: 6,
      documentName: "Document 6",
      year: "2024.pdf",
    },
  ];

  return (
    <>
      <AttendanceMuiCard>
        <LeaveGrid>
          <Policytext>HR Policies</Policytext>
          <ViewText underline="always">View All</ViewText>
        </LeaveGrid>
        {policyList.length > 0 ? (
          <>
            <StyledGrid className="dark-scrollbar" dense>
              {policyList.map((policy) => (
                <StyledHolidayListItem key={policy.id}>
                  <PolicyGrid container spacing={2}>
                    <Grid item xs={3}>
                      <ListItemText primary={policy.documentName} />
                      <ListItemText primary={policy.year} />
                    </Grid>
                    <ViewTypo>View</ViewTypo>
                  </PolicyGrid>
                </StyledHolidayListItem>
              ))}
            </StyledGrid>
          </>
        ) : (
          <NoHolidaysContainer>
            <NoHolidaysTitle>No Hr Policy Found</NoHolidaysTitle>
          </NoHolidaysContainer>
        )}
      </AttendanceMuiCard>
    </>
  );
};

export default HrPolicy;
