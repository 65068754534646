import { createSlice } from "@reduxjs/toolkit";
import initialState from "./paginationInitialState";
import {
  _setPage,
  _setPageSize,
} from "./paginationAction";

export const paginationSlice = createSlice({
  name: "ep/pagination",
  initialState,
  reducers: {
    setPage: _setPage,
    setPageSize: _setPageSize,
    resetPage: () => initialState,
  },
});

export const { setPage, setPageSize, resetPage } =
  paginationSlice.actions;

export default paginationSlice.reducer;
