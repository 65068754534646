import React from "react";
import {
  EmployeePortalDialogBox,
  EmployeePortalDialogCard,
  EmployeePortalDialogHeading,
  EmployeePortalDialogHeadingGrid,
  EmployeePortalDialogIcon,
} from "./EmployeePortalDialog.styled";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { resetApplyLeaveForm } from "../Components/Leave/store/applyLeavesSlice/applyLeaveSlice";

const EmployeePortalDialog = ({
  open,
  onClose,
  title,
  children,
  customWidth,
  customHeight,
  dataTestId,
}) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    dispatch(resetApplyLeaveForm());
  };
  return (
    <EmployeePortalDialogBox
      customwidth={customWidth}
      customHeight={customHeight}
      open={open}
      onClose={onClose}
      data-testid={dataTestId}
    >
      <EmployeePortalDialogCard>
        <EmployeePortalDialogHeadingGrid>
          <EmployeePortalDialogIcon
            onClick={handleClose}
          ></EmployeePortalDialogIcon>
          <EmployeePortalDialogHeading variant="h4">
            {title}
          </EmployeePortalDialogHeading>
        </EmployeePortalDialogHeadingGrid>
        <Grid>{children}</Grid>
      </EmployeePortalDialogCard>
    </EmployeePortalDialogBox>
  );
};

export default EmployeePortalDialog;
