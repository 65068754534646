import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EmployeePortalActionDailog } from "../../../Common/EmployeePortalDialog.styled";
import { FormTextField } from "../../../Common/FormTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import FormDropDown from "../../../Common/FormDropDown";
import { FormInputDate } from "../../../Common/FormDatePicker";
import { LeaveStyledGrid } from "./Leave.styled";
import { FormInputSwitch } from "../../../Common/FormInputSwitch";
import { dateFnsFormat, requiredField } from "../../../Common/Constant";
import { format } from "date-fns";
import {
  RenderRejectApproveButtons,
  RenderSaveCancelButtons,
  useNotification,
  usePermissionCustomHook,
} from "../../../Common/CommonUtils";
import {
  getLeaves,
  postApplyLeave,
  postApproveReject,
  putCancelLeave,
  putLeaveUpdate,
} from "../services/LeaveApi";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";
import { usePrompt } from "../../../Common/UsePrompt";
import {
  resetApplyLeaveForm,
  setIsNewApplyLeaveForm,
} from "../store/applyLeavesSlice/applyLeaveSlice";

const leaveSchema = yup.object().shape({
  leaveTypeId: yup.string().required(requiredField),
  fromDate: yup.string().required(requiredField),
  toDate: yup.string().required(requiredField),
  reason: yup.string().required(requiredField),
  // comment: !isReadOnly
  //     ? yup
  //         .string()
  //         .max(255)
  //         .required('This field is required')
  //     : yup.string().max(255),
});

const ApplyLeaveForm = (props) => {
  const { onCancel, teamsLeaves, fetchLeavesData, popUpActions } = props;

  const dispatch = useDispatch();
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const { showPrompt, PromptPopup } = usePrompt();
  const [reasonMassage, setReasonMassage] = useState("");

  const { showSnackbar } = useSnackbar();
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const metaData = useSelector((state) => state?.metaData);
  const applyLeaveForm = useSelector((state) => state.applyLeaveForm);
  const isNewApplyLeaveForm = useSelector(
    (state) => state?.applyLeaveForm?.isNewApplyLeaveForm
  );
  const isMyLeave = useSelector((state) => state?.applyLeaveForm?.isMyLeave);
  const isMyTeamLeave = useSelector(
    (state) => state.applyLeaveForm?.isMyTeamLeave
  );

  const { leaveType } = metaData;
  const {
    applied_leave_id,
    fromDate,
    toDate,
    reason,
    hasHalfDay,
    comment,
    leaveTypeId,
    request_status,
    total_days_leave_applied,
  } = applyLeaveForm || {};

  const isHR = usePermissionCustomHook("HR");
  const isReadOnly = isHR;
  const [commentForApproveReject, setCommentForApproveReject] =
    useState(comment);

  const defaultValue = {
    leaveTypeId: leaveTypeId,
    fromDate: fromDate ? new Date(fromDate) : null,
    toDate: toDate ? new Date(toDate) : null,
    reason: reason,
    hasHalfDay: hasHalfDay,
    comment: comment,
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(leaveSchema),
  });

  const { errors } = formState;

  const leaveTypeOptions = leaveType?.map((item) => ({
    label: item.leaveDescription,
    value: item.leaveTypeId,
  }));

  const onSubmit = async (data) => {
    const { leaveTypeId, fromDate, toDate, reason, hasHalfDay, comment } = data;
    const payload = {
      leaveTypeId: leaveTypeId,
      reason: reason,
      hasHalfDay: hasHalfDay ? hasHalfDay : false,
      comment: isNewApplyLeaveForm ? "" : comment,
      request_type: "Leave",
      fromDate: fromDate ? format(new Date(fromDate), dateFnsFormat) : null,
      toDate: toDate ? format(new Date(toDate), dateFnsFormat) : null,
    };
    const cancelLeavePayload = {
      from_date: fromDate ? format(new Date(fromDate), dateFnsFormat) : null,
      to_date: toDate ? format(new Date(toDate), dateFnsFormat) : null,
      comment: comment,
    };

    try {
      setApproveLoading(true);
      setRejectLoading(true);
      if (isNewApplyLeaveForm) {
        const applyLeave = await postApplyLeave(employeeId, payload);
        fetchLeavesData();
        onCancel();
        dispatch(setIsNewApplyLeaveForm(false));
        showSnackbar(applyLeave || "Leave Applied Successfully");
        setApproveLoading(false);
        setRejectLoading(false);
      } else if (isCancelLeaveDialog) {
        showPrompt(
          "Cancel Leave",
          "Are you sure you want to Cancel the Leave? This is action cannot be undone",
          async () => {
            try {
              setApproveLoading(true);
              setRejectLoading(true);
              const cancelLeave = await putCancelLeave(
                applied_leave_id,
                cancelLeavePayload
              );
              dispatch(resetApplyLeaveForm());
              onCancel();

              showSnackbar(cancelLeave?.message || "Leave Cancel Successfully");

              setApproveLoading(false);
              setRejectLoading(false);
            } catch (error) {
              setApproveLoading(false);
              setRejectLoading(false);
              const message =
                error?.response?.data?.detail[0]?.msg ||
                error?.response?.data?.detail;
              setOpenNotification(true);
              setReasonMassage(message);
              setNotificationMessage(message);
            }
            fetchLeavesData();
          },
          () => {
            onCancel();
          }
        );
      } else {
        const leaveUpdate = await putLeaveUpdate(applied_leave_id, payload);
        setApproveLoading(false);
        setRejectLoading(false);
        onCancel();
        showSnackbar(leaveUpdate || "Leave Updated successfully");
        fetchLeavesData();
      }
    } catch (error) {
      setApproveLoading(false);
      setRejectLoading(false);
      const message =
        error?.response?.data?.detail[0]?.msg || error?.response?.data?.detail;
      setOpenNotification(true);
      setNotificationMessage(message);
    }
  };

  const handleLeaveAction = async (action) => {
    const payload = {
      request_type: "Leave",
      request_id: applied_leave_id,
      action,
      comment: commentForApproveReject,
    };

    try {
      const approveReject = await postApproveReject(employeeId, payload);
      await getLeaves(employeeId);
      fetchLeavesData();
      setApproveLoading(false);
      setRejectLoading(false);
      onCancel();
      showSnackbar(
        approveReject?.data[0].message || "Leave Request Updated Successfully"
      );
    } catch (error) {
      setApproveLoading(false);
      setRejectLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };

  const handleApproveReject = () => {
    setApproveLoading(true);
    setRejectLoading(false);
    handleLeaveAction("Approved");
  };
  const handleReject = () => {
    setApproveLoading(false);
    setRejectLoading(true);
    handleLeaveAction("Rejected");
  };

  const handleCancel = () => {
    onCancel();
    dispatch(setIsNewApplyLeaveForm(false));
  };


  const isCancelLeaveDialog =
    !isNewApplyLeaveForm &&
    !teamsLeaves &&
    (request_status === "Cancelled" ||
      request_status === "Approved" ||
      request_status === "Rejected" ||
      popUpActions === "Cancel");

  const isReadyOnlyFilleds =
    isReadOnly ||
    (teamsLeaves && !isMyTeamLeave) ||
    (!teamsLeaves && !isMyLeave) ||
    (teamsLeaves &&
      (request_status === "Pending" ||
        request_status === "Pending By Manager")) ||
    isCancelLeaveDialog;

  return (
    <>
      <LeaveStyledGrid container>
        <FormDropDown
          label="Select Leave Type"
          name="leaveTypeId"
          type="select"
          control={control}
          errors={!!errors?.leaveTypeId}
          helperText={errors?.leaveTypeId?.message}
          options={leaveTypeOptions}
          readOnly={isReadyOnlyFilleds}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInputDate
              name="fromDate"
              control={control}
              label="From Date"
              minDate={
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              }
              helperText={errors?.fromDate?.message}
              errors={!!errors.fromDate}
              readOnly={isReadyOnlyFilleds}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputDate
              name="toDate"
              control={control}
              label="To Date"
              minDate={
                new Date(new Date().getFullYear(), new Date().getMonth(), 1)
              }
              helperText={errors?.toDate?.message}
              errors={!!errors.toDate}
              readOnly={isReadyOnlyFilleds}
            />
          </Grid>
        </Grid>
        <Grid container style={{ padding: "0px 8px" }}>
          <FormInputSwitch
            control={control}
            name="hasHalfDay"
            label="Half Day"
            readOnly={isReadyOnlyFilleds}
          />
        </Grid>

        <FormTextField
          label="Reasons"
          name="reason"
          rows={3}
          multiline
          control={control}
          errors={!!errors?.reason}
          helperText={errors?.reason?.message}
          readOnly={isReadyOnlyFilleds}
        />
        {teamsLeaves && (
          <FormTextField
            label="Comment"
            name="comment"
            rows={3}
            multiline
            control={control}
            readOnly={
              (teamsLeaves && !isMyTeamLeave) || (!teamsLeaves && !isMyLeave)
            }
            errors={!!errors?.comment}
            helperText={errors?.comment?.message}
            register={register("comment", {
              onChange: (e) => setCommentForApproveReject(e.target.value),
            })}
          />
        )}
        {!isNewApplyLeaveForm && (
          <Typography variant="labelText">
            No. of Days Leave Applicable - {total_days_leave_applied} days
          </Typography>
        )}
        {isCancelLeaveDialog && (
          <FormTextField
            label="Reason For Cancellation"
            name="comment"
            errors={reasonMassage && reasonMassage}
            helperText={reasonMassage && reasonMassage}
            rows={3}
            multiline
            control={control}
            required={isCancelLeaveDialog}
            readOnly={popUpActions === "View"}
          />
        )}
      </LeaveStyledGrid>

      <EmployeePortalActionDailog>
        {isMyLeave && (
          <RenderSaveCancelButtons
            handleCancel={handleCancel}
            handleSubmit={handleSubmit(onSubmit)}
            buttonLoading={approveLoading}
          />
        )}
        {teamsLeaves && isMyTeamLeave && (
          <RenderRejectApproveButtons
            handleReject={handleReject}
            handleApproveReject={handleApproveReject}
            rejectLoading={rejectLoading}
            approveLoading={approveLoading}
          />
        )}
      </EmployeePortalActionDailog>
      <NotificationPopup />
      <PromptPopup />
    </>
  );
};

export default ApplyLeaveForm;
