import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { MuiCard } from "../../../Common/Common.styled";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import { StyledButton } from "../../../Common/PromptMessage.styled";

export const MyTimesheetContiner = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 6px;
`;

export const MyTimesheetDate = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
`;

export const ArrowButton = styled(StyledButton)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 10px;
  }
`;

export const MyTimesheetMuiCard = styled(MuiCard)`
  padding: 30px 26px;
`;

export const Title = styled(Typography)`
  font-size: 19px;
  font-family: Roboto;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    // font-size: 17px;
    margin: 10px;
    text-align: center;
  }
`;

export const CalendarMonth = styled(IconButton)`
  color: ${({ theme }) => theme.palette.info.blue};
  margin-top: 10px;
  margin-right: 10px;
`;

export const TitleDateFormat = styled(Title)`
  margin: 20px;
  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 15px;
    text-align: center;
  }
`;

export const MyTimesheetListItem = styled(Grid)`
  border-bottom: 1px solid #e0e0e0;
  background: ${({ blockColor }) =>
    blockColor
      ? `linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff 64.3%,
    #E5F9ED 64.3%,
    #E5F9ED 77.8%,
    #ffffff 77.8%,
    #ffffff 100%
  );`
      : "#ffffff"};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    background: #ffffff;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.xl}px) {
    background: ${({ blockColor }) =>
      blockColor
        ? `linear-gradient(
            to right,
            #ffffff 0%,
            #ffffff 62%,
            #E5F9ED 62%,
            #E5F9ED 78.6%,
            #ffffff 78.6%,
            #ffffff 100%
          );`
        : "#ffffff"};
  }
`;

export const MyTimesheetList = styled(Grid)`
  display: flex;
  padding: 5px 16px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

export const AddeMyTimesheetTableHeadingGrid = styled(Grid)`
  border-left-color: white;
  border-right-color: white;
  display: flex;
  align-items: center;
  margin-bottom: ${({ blockColor }) => (blockColor ? "0px" : "30px")};
  padding: 4px 18px;

  background: ${({ blockColor }) =>
    blockColor
      ? `linear-gradient(
          to right,
          #F8FAFB 0%,
          #F8FAFB 64.9%,
          #00C24E6E 64.9%,
          #00C24E6E 78%,
          #F8FAFB 78%,
          #F8FAFB 100%
        );`
      : "#F8FAFB"};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    display: flex;
    flex-direction: column;
    background: #f8fafb;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.xl}px) {
    padding: 4px 18px;
    background: ${({ blockColor }) =>
      blockColor
        ? `linear-gradient(
            to right,
            #F8FAFB 0%,
          #F8FAFB 62.9%,
          #00C24E6E 62.9%,
            #00C24E6E 79%,
            #F8FAFB 79%,
            #F8FAFB 100%
          );`
        : "#F8FAFB"};
  }
`;

export const MyTimesheetColumn = styled(Grid)`
  font-size: ${({ theme }) => theme.spacing(2.2)}px;
  font-family: "Roboto", sans-serif;
  font-weight: 480;
`;

export const AddeMyTimesheetFooterTableHeadingGrid = styled(Grid)`
  border-left-color: white;
  border-right-color: white;
  display: flex;
  align-items: center;
  margin: 5px 0px;
  padding: 0px 28px;
  background: #d5d9de;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) and (max-width: ${({ theme }) =>
      theme.breakpoints.values.xl}px) {
    padding: 10px 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    display: flex;
    flex-direction: column;
    background: #d5d9de;
  }
`;

export const TeamCalendarMonth = styled(IconButton)`
  color: ${({ theme }) => theme.palette.info.blue};
`;

export const FilterContainer = styled(Grid)`
  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    margin-top: 13px;
  }
`;

export const FilterClearContainer = styled(Grid)`
  display: flex;
`;

export const CheckboxCloumn = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: bold;
`;

export const CheckboxRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;
