export const getTeamTimesheetData = (TeamTimesheetData) => {
  if (!TeamTimesheetData || TeamTimesheetData.length === 0) {
    return [];
  }

  return TeamTimesheetData.map((item, index) => ({
    id: item.timesheetId || index,
    employeeId: item.employeeId,
    projectId: item.projectId,
    employeeName: item.employeeName,
    project: item.project,
    comments : item.comments,
    actionBy : item.actionBy,
    status: item.status,
    weekStartDate: item.weekStartDate,
    weekEndDate: item.weekEndDate,
    totalHours: item.totalHours,
    createdDateTime: item.createdDateTime,
    updatedDateTime: item.updatedDateTime,
    projectManagerId: item.projectManagerId,
    projectManagerName: item.projectManagerName,
    subTimesheets:
      item.subTimesheets?.map((subItem) => ({
        subTimesheetId: subItem.subTimesheetId,
        timesheetId: subItem.timesheetId,
        task: subItem.task,
        hours: subItem.hours,
        day: subItem.day,
        date: subItem.date,
        createdDateTime: subItem.createdDateTime,
        updatedDateTime: subItem.updatedDateTime,
      })) || [],
  }));
};
