import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import {
  IconContainer,
  StyledButtonBase,
  TitleContainer,
} from './SideNavbar.Styled';
import List from '@mui/material/List';
import { setIsViewDetailsPage } from '../Employees/store/EmployeeSlice';
import { useDispatch } from 'react-redux';
import { resetPage } from '../../Common/store/paginationSlice/paginationSlice';
import { resetTab } from '../../Common/store/tabSlice/tabSlice';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { useMediaQuery, useTheme } from '@mui/material';

export const SideNavbarItem = (props) => {
  const {
    active,
    disabled,
    icon,
    path,
    title,
    handleActivePath,
    setIsMobileNavbar,
    isMiniNavbar,
  } = props;
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSidebar = () => {
    navigate(path);
    dispatch(resetPage());
    dispatch(resetTab());
    dispatch(setIsViewDetailsPage(false));
    handleActivePath(path);
    setIsMobileNavbar(false);
  };

  return (
    <List>
      <StyledButtonBase
        isMiniNavbar={isMiniNavbar}
        isWeb={isWeb}
        sx={{
          ...(active && {
            // backgroundColor: 'rgba(255, 255, 255, 0.04)',
            backgroundColor: '#345196',
          }),
        }}
        onClick={handleSidebar}
      >
        {icon && (
          <Tooltip
            title={title}
            placement="right"
            slots={{
              transition: Fade,
            }}
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                    {
                      marginLeft: '24px',
                    },
                },
              },
            }}
          >
            <IconContainer
              component="span"
              sx={{
                color: 'neutral.400',
                // color: '#345196',
                ...(active && {
                  color: 'common.white',
                }),
              }}
            >
              {icon}
            </IconContainer>
          </Tooltip>
        )}
        <TitleContainer
          component="span"
          sx={{
            color: 'neutral.400',
            fontFamily: (theme) => theme.typography.fontFamily,
            ...(active && {
              color: 'common.white',
            }),
            ...(disabled && {
              color: 'neutral.500',
            }),
          }}
        >
          {title}
        </TitleContainer>
      </StyledButtonBase>
    </List>
  );
};

SideNavbarItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};