import React from 'react';
import { FormTextField } from '../../../../../Common/FormTextField';
import {
  SalaryAndBankDetailsTextFieldGrid,
  TextFieldGrid,
} from './SalaryAndBankDetails.styled';
import Grid from '@mui/material/Grid';
import FormDropDown from '../../../../../Common/FormDropDown';
import { accountTypeOptions } from '../../../../../Common/Constant';

const BankDetailsFormField = (props) => {
  const { control, errors, defaultValue, register, handleChange, editable } =
    props;

  return (
    <>
      <SalaryAndBankDetailsTextFieldGrid container spacing={4}>
        <TextFieldGrid item xs={12}>
          <Grid item xs={6}>
            <FormTextField
              name="bankName"
              label="Bank Name"
              type="text"
              control={control}
              errors={!!errors?.bankName}
              helperText={errors?.bankName?.message}
              defaultValue={defaultValue?.bankName}
              register={register('bankName', {
                onChange: handleChange,
              })}
              readOnly={!editable}
            />
          </Grid>

          <Grid item xs={6}>
            <FormTextField
              name="bankAddress"
              label="Branch Address"
              type="text"
              control={control}
              errors={!!errors?.bankAddress}
              helperText={errors?.bankAddress?.message}
              defaultValue={defaultValue?.bankAddress}
              register={register('bankAddress', {
                onChange: handleChange,
              })}
              readOnly={!editable}
            />
          </Grid>
        </TextFieldGrid>
        <TextFieldGrid item xs={12}>
          <Grid item xs={6}>
            <FormTextField
              name="accountHolderName"
              label="Account Holder Name"
              type="text"
              control={control}
              errors={!!errors?.accountHolderName}
              helperText={errors?.accountHolderName?.message}
              defaultValue={defaultValue?.accountHolderName}
              register={register('accountHolderName', {
                onChange: handleChange,
              })}
              readOnly={!editable}
            />
          </Grid>

          <Grid item xs={6}>
            <FormTextField
              name="bankAccountNumber"
              label="Account Number"
              type="number"
              control={control}
              errors={!!errors?.bankAccountNumber}
              helperText={errors?.bankAccountNumber?.message}
              defaultValue={defaultValue?.bankAccountNumber}
              register={register('bankAccountNumber', {
                onChange: handleChange,
              })}
              readOnly={!editable}
            />
          </Grid>
        </TextFieldGrid>
        <TextFieldGrid xs={12} item>
          <Grid item xs={6}>
            <FormTextField
              name="ifscCode"
              label="IFSC Code"
              control={control}
              errors={!!errors?.ifscCode}
              helperText={errors?.ifscCode?.message}
              defaultValue={defaultValue?.ifscCode}
              register={register('ifscCode', {
                onChange: handleChange,
              })}
              readOnly={!editable}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDropDown
              label="Account Type"
              name="accountType"
              control={control}
              options={accountTypeOptions}
              register={register('accountType', {
                onChange: handleChange,
              })}
              readOnly={!editable}
            />
          </Grid>
        </TextFieldGrid>
      </SalaryAndBankDetailsTextFieldGrid>
    </>
  );
};

export default BankDetailsFormField;
