import axios from "axios";
import { BASE_URL, EMPLOYEE_PORTAL_API_GATEWAY_URL } from "./Constant";
import { store } from "../Redux/store";
import { setIsSideNav } from "../Components/SideNavbar/Store/slideNavbarSlice";
import { resetPasswordEmial } from "../Components/ForgotPassword/ResetPassword/store/resetPasswordSlice";
import { setIsAuthenticated } from "./store/authenticatedSlice/authenticatedSlice";
import { resetNavigationRoute } from "../Components/SideNavbar/Store/navigationSlice/navigationSlice";
import { postLogout } from "../Components/Login/LoginApiServices/LoginApi";
import { resetUserInfo } from "./store/userSlice/userSlice";
import { resetEmployeeId } from "../Components/Employees/store/employeeIdSlice/employeeIdSlice";

const BASE_URL_1 = BASE_URL;
const BASE_URL_2 = EMPLOYEE_PORTAL_API_GATEWAY_URL;

const AXOIS_FAST_API_URL = axios.create({ baseURL: BASE_URL_1 });
const AXOIS_NODE_API_URL = axios.create({ baseURL: BASE_URL_2 });

const handleDBLogout = async () => {
  store.dispatch(setIsSideNav(false));
  store.dispatch(resetPasswordEmial());
  store.dispatch(setIsAuthenticated(false));
  store.dispatch(resetNavigationRoute());
  store.dispatch(resetUserInfo());
  store.dispatch(resetEmployeeId());
  window.location.href = "/";
};

const addInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      try {
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403) {
            const state = store.getState();
            const token = state.user.accessToken;
            const loginUserId = state.user?.data?.employeeId;

            const authorization = {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            };

            await postLogout(authorization, loginUserId);
            handleDBLogout();
          }
        }
        return Promise.reject(error);
      } catch (e) {
        console.log("error in response middleware", e?.response?.data);
        handleDBLogout();
        return Promise.reject(error);
      }
    }
  );

  axiosInstance.interceptors.request.use(async (config) => {
    const state = store.getState();
    const token = state.user.accessToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return axiosInstance;
};

addInterceptors(AXOIS_FAST_API_URL);
addInterceptors(AXOIS_NODE_API_URL);

const api = (instanceType) => {
  const axiosInstance =
    instanceType === "NODE_API_URL" ? AXOIS_NODE_API_URL : AXOIS_FAST_API_URL;

  return {
    get: (url, config) => axiosInstance.get(url, config),
    post: (url, body, config) => axiosInstance.post(url, body, config),
    put: (url, body, config) => axiosInstance.put(url, body, config),
    patch: (url, body, config) => axiosInstance.patch(url, body, config),
    delete: (url, config) => axiosInstance.delete(url, config),
  };
};

export { AXOIS_FAST_API_URL, AXOIS_NODE_API_URL, api };
