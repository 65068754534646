import { createSlice } from "@reduxjs/toolkit";
import { _setEmployeeAttendanceId } from "./employeeAttendanceIdAction";
import initialState from "./employeeAttendanceIdInitialState";

export const employeeAttendanceIdSlice = createSlice({
  name: "ep/employeeAttendanceId",
  initialState,
  reducers: {
    setEmployeeAttendanceId: _setEmployeeAttendanceId,
    resetEmployeeAttendanceId: () => initialState,
  },
});

export const { setEmployeeAttendanceId, resetEmployeeAttendanceId } =
  employeeAttendanceIdSlice.actions;

export default employeeAttendanceIdSlice.reducer;
