import React from "react";
import { FormControlLabel, FormGroup, InputAdornment } from "@mui/material";
import { GreenSwitch, SmallGreenSwitch } from "./Common.styled";

const CommonSwitch = ({
  label,
  checked,
  onChange,
  disabled = false,
  required = false,
  name,
  inputProps = {},
}) => {
  return (
    <InputAdornment position="end">
      <FormGroup>
        <FormControlLabel
          control={
            <SmallGreenSwitch>
              <GreenSwitch
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                {...inputProps}
              />
            </SmallGreenSwitch>
          }
          label={label}
          name={name}
          required={required}
        />
      </FormGroup>
    </InputAdornment>
  );
};

export default CommonSwitch;
