import React, { useState, useEffect } from "react";
import { useSideNav, useLoader } from "../../../Common/CommonUtils";
import { DataMainGrid } from "../../Employees/Employee.Styled";
import { MuiCard } from "../../../Common/Common.styled";
import DataGridComponent from "../../../Common/DataGridComponent";
import { Box } from "@mui/material";
import ProgressBar from "../../../Common/ProgressBar";
import { getAssignedProjects } from "../services/MyAssignmentServices";
import { LinearProgressComponent, AssignmentGrid } from "./MyAssignment.styled";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../../Common/CommonUtils";
import { formattedAssignment } from "./MyAssignmentDatagridUtils";
import {
  setPage,
  setPageSize,
} from "../../../Common/store/paginationSlice/paginationSlice";
const MyAssignment = () => {
  useSideNav();
  const [assignmentDetails, setAssignmentDetails] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const dispatch = useDispatch();
  const { setLoading, LoaderPopup } = useLoader();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const userId = useSelector((state) => state.user?.data?.employeeId);
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = assignmentDetails;

  const columnsData = [
    {
      field: "projectName",
      headerName: "Project Name",
      width: 280,
    },
    {
      field: "department",
      headerName: "Department",
      width: 280,
    },

    {
      field: "utilization",
      headerName: "Utilization(%)",
      width: 270,
      renderCell: (params) => {
        const { utilization } = params?.row;
        return (
          <Box sx={{ width: "80%" }}>
            <ProgressBar
              value={utilization}
              LinearProgressComponent={LinearProgressComponent}
            />
          </Box>
        );
      },
    },
    {
      field: "billableType",
      headerName: "Billing Type",
      width: 270,
    },
    {
      field: "managerName",
      headerName: "Manager",
      width: 280,
    },
  ];

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const fetchAssignedProjects = async () => {
    try {
      setLoading(true);
      const payload = {
        userId: userId,
        employeeId: employeeId,
        page: page,
        limit: pageSize,
      };
      setAssignmentDetails({ ...assignmentDetails, isLoading: true });
      const projectData = await getAssignedProjects(payload);
      const assignedProjectData = formattedAssignment(
        projectData?.data?.ProjectAssignedList
      );
      setAssignmentDetails((prev) => ({
        ...prev,
        rows: assignedProjectData,
        isLoading: false,
        totalItems: projectData?.pagination?.totalItems,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAssignmentDetails({ ...assignmentDetails, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };

  useEffect(() => {
    fetchAssignedProjects();
  }, [page, pageSize]);

  return (
    <>
      <AssignmentGrid item xs={12} sm={12}>
        <DataMainGrid>
          <MuiCard>
            <DataGridComponent
              columnsData={columnsData}
              rowData={assignmentDetails.rows}
              rowCount={totalItems}
              isLoading={isLoading}
              page={page - 1}
              editable={true}
              handlePaginationChange={handlePaginationChange}
            />
          </MuiCard>
        </DataMainGrid>
      </AssignmentGrid>

      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default MyAssignment;
