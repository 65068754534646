import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  AttendanceOverviewContainer,
  CheckInCheckOutTime,
  CheckInCheckOutTimeContainer,
  MonthDayYearText,
  StyledCardContentLeft,
  StyledCardContentRight,
  StyledCheckInOutText,
  StyledCheckInText,
  StyledCheckInTimeText,
  StyledCheckOutIconContainer,
  StyledFormattedTime,
  StyledGridContainer,
  StyledLoginIcon,
  StyledLogOutIcon,
  StyledSecondsAmPm,
  TimeCheckInCard,
  TimeContainer,
} from "./TimeCheckIn.styled";
import { useNavigate } from "react-router-dom";
import { MuiCard } from "../../Common/Common.styled";
import { useDispatch, useSelector } from "react-redux";
import { postCheckIn, postCheckOut } from "./services/Services";
import { formatToIST, useLoader } from "../../Common/CommonUtils";
import { useNotification } from "../../Common/CommonUtils";
import {
  DividerContainer,
  LeaveOverview,
} from "../Dashboard/Components/Dashboard.styled";
import { setNavigationRoute } from "../SideNavbar/Store/navigationSlice/navigationSlice";

const TimeCheckIn = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchAttendance, checkInCheckOutTimeDetails, linkAttendance } = props;
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [checkInOut, setCheckInOut] = useState(false);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");

  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const updateCheckInOutStates = () => {
    const attendanceHistory =
      checkInCheckOutTimeDetails?.attendanceHistory || [];
    if (attendanceHistory.length > 0) {
      const latestRecord = attendanceHistory[0];
      const checkInTimestamp = latestRecord.checkInTime;
      const checkOutTimestamp = latestRecord.checkOutTime;

      if (checkInTimestamp) {
        setCheckInTime(formatToIST(checkInTimestamp, true));
        setCheckInOut(true);
      } else {
        setCheckInOut(false);
      }

      if (checkOutTimestamp) {
        setCheckOutTime(formatToIST(checkOutTimestamp, true));
      }
    }
  };

  useEffect(() => {
    updateCheckInOutStates();
  }, [checkInCheckOutTimeDetails]);

  const timeRef = useRef(null);

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      let hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      const ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? hours : 12;

      const formattedTime = `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      }:${seconds < 10 ? `0${seconds}` : seconds} ${ampm}`;

      if (timeRef.current) {
        timeRef.current.innerText = formattedTime;
      }

      requestAnimationFrame(updateClock);
    };

    updateClock();

    return () => {
      cancelAnimationFrame(updateClock);
    };
  }, []);

  const formattedDate = currentTime
    .toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .replace(/,/g, "");

  const submitCheckInOut = async () => {
    try {
      setLoading(true);
      if (!checkInOut) {
        await postCheckIn(employeeId);
        updateCheckInOutStates();
      } else {
        await postCheckOut(employeeId);
        updateCheckInOutStates();
      }
      fetchAttendance();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setCheckInOut(false);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };

  const handleAttendanceOverview = () => {
    navigate("/attendance");
    dispatch(setNavigationRoute("/attendance"));
  };

  return (
    <>
      <TimeCheckInCard>
        <StyledGridContainer>
          <Grid item xs={7}>
            <StyledCardContentLeft>
              <TimeContainer>
                <StyledFormattedTime ref={timeRef} />
              </TimeContainer>
              <CheckInCheckOutTimeContainer>
                <CheckInCheckOutTime>
                  <StyledCheckInText>
                    Checked in:
                    <StyledCheckInTimeText>{checkInTime}</StyledCheckInTimeText>
                  </StyledCheckInText>
                </CheckInCheckOutTime>

                <CheckInCheckOutTime>
                  <StyledCheckInText>
                    Checked out:
                    <StyledCheckInTimeText>
                      {checkOutTime}
                    </StyledCheckInTimeText>
                  </StyledCheckInText>
                </CheckInCheckOutTime>
              </CheckInCheckOutTimeContainer>
            </StyledCardContentLeft>
          </Grid>
          <Grid item xs={5}>
            <StyledCardContentRight>
              <MonthDayYearText>{formattedDate}</MonthDayYearText>
              <StyledCheckOutIconContainer>
                <IconButton onClick={submitCheckInOut}>
                  {checkInOut ? <StyledLoginIcon /> : <StyledLogOutIcon />}
                </IconButton>
                <StyledCheckInOutText>
                  {checkInOut ? "Check Out" : "Check In"}
                </StyledCheckInOutText>
              </StyledCheckOutIconContainer>
            </StyledCardContentRight>
          </Grid>
        </StyledGridContainer>
        <AttendanceOverviewContainer>
          {!linkAttendance && (
            <LeaveOverview
              underline="always"
              onClick={handleAttendanceOverview}
            >
              Attendance Overview
            </LeaveOverview>
          )}
        </AttendanceOverviewContainer>
      </TimeCheckInCard>

      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default TimeCheckIn;
