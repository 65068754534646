import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsSideNav } from "../Components/SideNavbar/Store/slideNavbarSlice";
import NotificationDialog from "./NotificationDialog";
import LoadingSpinner from "./Loader/Loader";
import { ButtonSpinnerProgress, ButtonWrapper, Loader } from "./Ui/styled";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { StyledButton } from "./Ui/styled";
import { useNavigate } from "react-router-dom";
import {
  resetIsTimeout,
  setIsLogoutTiming,
} from "./store/timeoutSlice/timeoutSlice";
import PromptMessage from "./PromptMessage";
import CustomLoader from "./Loader";
export const getErrorMessage = () => {
  const errorMessage = {
    noRowsLabel: <Typography variant="h3">No Records Found</Typography>,
  };
  return errorMessage;
};

export const useRemoveSideNav = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsSideNav(false));
  }, []);
};
export const useSideNav = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsSideNav(true));
  }, []);
};

export const formatDateTime = (utcTimestamp) => {
  const date = new Date(utcTimestamp);
  const optionsDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const utcTimestampWithZ = date + "Z";
  const utcTimestampParsed = Date.parse(utcTimestampWithZ);
  const localTimestamp = new Date(utcTimestampParsed);
  const localDateFormatted = localTimestamp.toLocaleDateString(
    undefined,
    optionsDate
  );
  const formattedDateTime = `${localDateFormatted}`;
  return formattedDateTime;
};

export const formatDateRange = (startDate, endDate) => {
  const options = { month: "short", day: "numeric" };
  const start = new Date(startDate).toLocaleDateString("en-US", options);
  const end = new Date(endDate).toLocaleDateString("en-US", options);
  const year = new Date(startDate).getFullYear();

  return `${start} - ${end}, ${year}`;
};

export function formatDate(date) {
  const dateObject = new Date(date);
  const formattedDate =
    (dateObject.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    dateObject.getDate().toString().padStart(2, "0") +
    "/" +
    dateObject.getFullYear();
  return formattedDate;
}

export const useNotification = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const NotificationPopup = () => (
    <NotificationDialog
      openPopUp={openNotification}
      message={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      setOpenNotification={setOpenNotification}
    />
  );
  return {
    openNotification,
    notificationMessage,
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
  };
};

export const useLoader = () => {
  const [loading, setLoading] = useState(false);

  const LoaderPopup = () => {
    return loading ? <CustomLoader /> : null;
  };

  return {
    loading,
    setLoading,
    LoaderPopup,
  };
};

export const useAuthentication = () => {
  const authorization = useSelector((state) => state.user?.accessToken);

  return {
    headers: {
      Authorization: `Bearer ${authorization}`,
      "Content-Type": "application/json",
    },
  };
};

export const useSnackBar = () => {
  const [isOpenSnackBar, setIsOpenSanckBar] = useState(false);
  const [snackbarLabel, setSnackbarLabel] = useState("");

  const showSnackBar = (label) => {
    setSnackbarLabel(label);
    setIsOpenSanckBar(true);
  };

  return {
    isOpenSnackBar,
    snackbarLabel,
    showSnackBar,
    setIsOpenSanckBar,
  };
};

export const hynivaAddressBangalore = () => {
  return (
    <>
      <Typography># 8,1st Floor, HBR Layout, 4th Block </Typography>
      <Typography>Outer Ring Road, Nagawara </Typography>
      <Typography>Bengaluru, Karnataka - 560043</Typography>
    </>
  );
};

export const showLoader = () => (
  <Loader data-testid="loading">
    <LoadingSpinner />
  </Loader>
);
export const calculateAverageRating = (certifications, ratingType) => {
  let totalRating = 0;
  const totalCountOfParameters = certifications.length - 1;
  certifications.forEach((item) => {
    totalRating += parseFloat(item[ratingType]);
  });
  return totalRating / totalCountOfParameters;
};

export const formatedZoneDate = (dateOfBirth) => {
  let originalDate;
  if (dateOfBirth.includes("T")) {
    originalDate = format(utcToZonedTime(dateOfBirth, "UTC"), "MM-dd-yyyy");
  } else {
    originalDate = format(new Date(dateOfBirth), "MM-dd-yyyy");
  }
  return originalDate;
};

export const renderButtonSpinner = (
  loadingButton,
  buttonName,
  handleButtonClick,
  variant,
  rightIcon
) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Button
        fullWidth
        size="large"
        type="submit"
        variant={variant}
        disabled={loadingButton}
        onClick={handleButtonClick}
      >
        {buttonName}
        {rightIcon}
      </Button>
      {loadingButton && <ButtonSpinnerProgress size={24} />}
    </Box>
  );
};

export const dropDownOptions = (options) => {
  return (
    options?.map((item) => ({
      label: item.name,
      value: item.name,
    })) || []
  );
};

export const usePermissionCustomHook = (permissionName) => {
  const myPermission = useSelector((state) => state.user?.data?.role);
  return hasPermission(permissionName, myPermission);
};
export const hasPermission = (permissionName, myPermission) => {
  if (myPermission === permissionName) {
    return true;
  } else {
    return false;
  }
};

export const RenderSaveCancelButtons = (props) => {
  const { handleCancel, handleSubmit, buttonLoading, buttonName = "" } = props;
  return (
    <ButtonWrapper container>
      <StyledButton
        marginRight={"16px"}
        variant="outlined"
        onClick={handleCancel}
      >
        Cancel
      </StyledButton>
      <Box sx={{ position: "relative" }}>
        <StyledButton
          variant="contained"
          onClick={handleSubmit}
          disabled={buttonLoading}
        >
          {buttonName ? buttonName : "Submit"}
        </StyledButton>
        {buttonLoading && (
          <ButtonSpinnerProgress size={20} sx={{ marginTop: "-4px" }} />
        )}
      </Box>
    </ButtonWrapper>
  );
};
export const RenderRejectApproveButtons = (props) => {
  const {
    handleReject,
    handleApproveReject,
    rejectLoading,
    approveLoading,
    width,
    height,
    color = "error",
    saveOrRejectButtonLabel = "Reject",
    submitButtonLabel = "Approve",
  } = props;
  return (
    <ButtonWrapper container>
      <Box sx={{ position: "relative" }}>
        <StyledButton
          marginRight={"16px"}
          variant="outlined"
          width={width}
          height={height}
          color={color}
          onClick={handleReject}
          disabled={rejectLoading}
        >
          {saveOrRejectButtonLabel}
        </StyledButton>
        {rejectLoading && (
          <ButtonSpinnerProgress size={20} sx={{ marginTop: "-4px" }} />
        )}
      </Box>
      <Box sx={{ position: "relative" }}>
        <StyledButton
          variant="contained"
          onClick={handleApproveReject}
          disabled={approveLoading}
          width={width}
          height={height}
        >
          {submitButtonLabel}
        </StyledButton>
        {approveLoading && (
          <ButtonSpinnerProgress size={20} sx={{ marginTop: "-4px" }} />
        )}
      </Box>
    </ButtonWrapper>
  );
};

export const getYesNo = (handleNo, handleYes, loading) => {
  return (
    <ButtonWrapper container>
      <Button variant="text" onClick={handleNo}>
        No
      </Button>
      <Button variant="text" onClick={handleYes} disabled={loading}>
        Yes
      </Button>
    </ButtonWrapper>
  );
};

export const TimeOutPopup = ({ seconds, setSeconds, signOut }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLogoutTiming = useSelector((state) => state.timeout.isLogoutTiming);
  const handleYes = () => {
    dispatch(setIsLogoutTiming(false));
    setSeconds(60);
  };

  const handleNo = () => {
    dispatch(setIsLogoutTiming(false));
    setSeconds(60);
    navigate("/");
    signOut();
    setTimeout(() => {
      dispatch(resetIsTimeout());
    }, 2000);
  };

  return (
    <PromptMessage
      open={isLogoutTiming}
      title={"Session Logout"}
      message={`Your session will expire in ${seconds} seconds. Do you want to extend
          the session ?`}
      confirmLabel={"Yes"}
      cancelLabel={"No"}
      onConfirm={handleYes}
      onClose={handleNo}
    />
  );
};
export const formatToIST = (timestamp, includeSeconds = false) => {
  const utcDate = new Date(timestamp);
  const options = {
    timeZone: "Asia/Kolkata",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  if (includeSeconds) {
    options.second = "2-digit";
  }
  return utcDate.toLocaleTimeString("en-IN", options).replace(" ", "");
};
export const formatTimeFromUTC = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${hours}:${minutes} ${ampm}`;
};
export const formatTimeFromUTCToIST = (dateString) => {
  const date = new Date(dateString);
  const istOffset = 5.5 * 60;
  const utcOffset = date.getTimezoneOffset();
  const istTime = new Date(
    date.getTime() + (istOffset + utcOffset) * 60 * 1000
  );
  const hours = istTime.getHours().toString().padStart(2, "0");
  const minutes = istTime.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const convertToContinuousDigits = (aadhaarNumber) => {
  if (!/^\d{4}-\d{4}-\d{4}$/.test(aadhaarNumber)) {
    return "Invalid Aadhaar number format. It should follow XXXX-XXXX-XXXX.";
  }
  return aadhaarNumber.replace(/-/g, "");
};

export const formatAadhaarNumber = (aadhaarNumber) => {
  if (!/^\d{12}$/.test(aadhaarNumber)) {
    return "Invalid Aadhaar number. It should be exactly 12 digits.";
  }
  return aadhaarNumber.replace(/(\d{4})(\d{4})(\d{4})/, "$1-$2-$3");
};
