import React, { useEffect, useState } from "react";
import {
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../Common/CommonUtils";
import Grid from "@mui/material/Grid";
import LeaveChart from "./Components/LeaveChart";
import {
  DashBoardHeading,
  HrPoliciesCard,
  MtBox,
} from "./Components/Dashboard.styled";
import TimeCheckIn from "../Attendance/TimeCheckIn";
import { getAttendanceSummary } from "../Attendance/services/Services";
import { useSelector } from "react-redux";
import HrAdminDashboard from "./Components/HrAdminDashboard/HrAdminDashboard";
import HrPolicy from "./Components/HrPolicy";
import HolidayList from "../Leave/components/holidayList";

const Dashboard = () => {
  useSideNav();
  const { setOpenNotification, setNotificationMessage } = useNotification();
  const isHR = usePermissionCustomHook("HR");
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const [checkInCheckOutTimeDetails, setCheckInCheckOutTimeDetails] =
    useState(null);
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const monthYear = `${month}-${year}`;

  useEffect(() => {
    fetchAttendance();
  }, []);

  const fetchAttendance = async () => {
    try {
      const payload = {
        user_id: employeeId,
        month_year: monthYear,
      };
      const attendanceSummary = await getAttendanceSummary(payload);
      setCheckInCheckOutTimeDetails(attendanceSummary?.data);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(
        error.response?.data?.message || "Failed to fetch attendance."
      );
    }
  };

  return (
    <>
      {!isHR ? (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={5} xl={5} sm={12}>
            <DashBoardHeading>Dashboard</DashBoardHeading>
            <MtBox />
            <TimeCheckIn
              fetchAttendance={fetchAttendance}
              checkInCheckOutTimeDetails={checkInCheckOutTimeDetails}
            />
            <LeaveChart />
            <MtBox />
            <HolidayList />
            <HrPoliciesCard>
              <HrPolicy />
            </HrPoliciesCard>
            <MtBox />
          </Grid>
        </Grid>
      ) : (
        <HrAdminDashboard />
      )}
    </>
  );
};

export default Dashboard;
