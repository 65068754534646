import React, { useState } from "react";
import TabPanel, { a11yProps } from "../../../../Common/TabPanel/TabPanel";
import {
  MuiCompactMarginTab,
  MuiLargeSpacingTab,
  StickyTab,
  TabContainer,
} from "../../../../Common/Common.styled";
import MyAssignment from "../MyAssignment";
import { usePermissionCustomHook } from "../../../../Common/CommonUtils";
import HrAdminManagerMyAssignment from "./HrAdminManagerMyAssignment";
import ProjectHistory from "../ProjectHistory";
import { useDispatch, useSelector } from "react-redux";
import { resetPage } from "../../../../Common/store/paginationSlice/paginationSlice";
import { setTabValue } from "../../../../Common/store/tabSlice/tabSlice";

const HrManagerMyAssignmentTabs = () => {
  const isManager = usePermissionCustomHook("Manager");
  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const tabValue = useSelector((state) => state.tabInfo?.tabValue);
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
   dispatch(setTabValue(newValue));
    dispatch(resetPage());
  };

  return (
    <>
      <TabContainer>
        <StickyTab
          value={tabValue}
          variant="scrollable"
          scrollButtons={false}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          <MuiCompactMarginTab label="My Assignment" {...a11yProps(0)} />

          {isManager && (
            <MuiLargeSpacingTab
              label="Resource Utilization"
              {...a11yProps(1)}
            />
          )}

          <MuiLargeSpacingTab label="Project History" {...a11yProps(2)} />
        </StickyTab>
      </TabContainer>
      {(isManager || isDeveloper) && (
        <TabPanel value={tabValue} index={0}>
          <MyAssignment />
        </TabPanel>
      )}
      {isManager && (
        <TabPanel value={tabValue} index={1}>
          <HrAdminManagerMyAssignment />
        </TabPanel>
      )}
      {!isHR && (
        <TabPanel value={tabValue} index={isManager ? 2 : 1}>
          <ProjectHistory />
        </TabPanel>
      )}
    </>
  );
};

export default HrManagerMyAssignmentTabs;
