import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import EmployeePortalDialog from '../../../Common/EmployeePortalDialog';
import VerifyOtpForm from './VerifyOtpForm';
import {
  sendInstructionApi,
  VerifyOtpApi,
} from '../ForgotPasswordApiServices/ForgotPasswordApi';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const schema = yup.object().shape({
  otp: yup.string().required('You must enter an OTP'),
});

const VerifyOtp = (props) => {
  const { email, onClose } = props;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [resendButtonDisabled, setResendButtonDisabled] = useState(true);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setResendButtonDisabled(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  

  const handleResendOtp = async () => {
    setMinutes(1);
    setSeconds(30);
    setResendButtonDisabled(true);
    

    sendInstructionApi(email)
      .then(() => {
        setSnackbarMessage('OTP has been sent successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        const errorMessage = error.response.data.detail;
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleComponentSubmit = async (data) => {
    const { otp } = data;
    const payload = {
      otp: otp,
      email: email,
    };
    VerifyOtpApi(payload)
      .then(() => {
        setSnackbarMessage('OTP has verified successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          handleCloseDialog();
          navigate('/reset-password');
        }, 2000);
      })
      .catch((error) => {
        const errorMessage = error.response.data.detail;
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <>
      <EmployeePortalDialog
        open={openDialog}
        onClose={onClose}
        title={'Verify OTP'}
        customWidth={'400px'}
      >
        <VerifyOtpForm
          onSubmit={handleComponentSubmit}
          onCancel={handleCancel}
          onResend={handleResendOtp}
          resendButtonDisabled={resendButtonDisabled}
          minutes={minutes}
          seconds={seconds}
        />
      </EmployeePortalDialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default VerifyOtp;
