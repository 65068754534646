import { api } from "../../../Common/AxoisConfig";

export const postCheckIn = async (userId) => {
  await api("FAST_API_URL").patch(`attendance/check-in/${userId}`);
};

export const postCheckOut = async (userId) => {
  await api("FAST_API_URL").patch(`attendance/check-out/${userId}`);
};

export const getAttendanceSummary = async (payload) => {
  const attendanceSummary = await api("FAST_API_URL").get(
    `attendance/summary`,
    { params: payload }
  );
  return attendanceSummary?.data;
};

export const postAttendance = async (userId, payload) => {
  const editAttendance = await api("FAST_API_URL").post(
    `attendance/${userId}`,
    payload
  );
  return editAttendance?.data;
};

export const getAttendance = async () => {
  const Attendance = await api("NODE_API_URL").get(
    `metadata/type?type=Attendance&status=active`
  );
  return Attendance?.data;
};

export const getAttendanceRequests = async (payload) => {
  const Attendance = await api("FAST_API_URL").get(`attendance/requests`, {
    params: payload,
  });
  return Attendance?.data;
};

export const postApproveReject = async (userId, payload) => {
  const approveReject = await api("FAST_API_URL").patch(
    `notifications/${userId}`,
    payload
  );
  return approveReject?.data;
};

export const getAttendanceDowndload = async (userId, monthYear) => {
  const attendanceDowndload = await api("FAST_API_URL").get(
    `attendance/download-excel/${userId}?month_year=${monthYear}`,
    { responseType: "blob" }
  );
  return attendanceDowndload?.data;
};
