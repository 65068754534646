export const formattedAssignment = (assignmentData) => {
  if (!Array.isArray(assignmentData) || assignmentData.length === 0) {
    return [];
  }

  return assignmentData.map((assignment) => {
    return {
      id: assignment.projectAssignmentId,
      projectName: assignment.projectName,
      department: assignment.department,
      utilization: assignment.utilization,
      billableType: assignment.billableType,
      managerName: assignment.managerName,
      createdDateTime: assignment.createdDateTime,
      startDate: assignment.startDate,
      relievedDate: assignment.relievedDate,
      status: assignment.status,
      comments: assignment.comments,
      employeeId: assignment.employeeId || assignment.employee_id,
      projectId: assignment.projectId || assignment.project_id,
    };
  });
};
