import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CreateEmployeeLeftTextGrid = styled(Grid)`
  padding-left: ${(props) =>
    `${props.theme.palette.spacing[1] * 5}px`}!important;
  padding-right: ${(props) => `${props.theme.palette.spacing[0] * 2.5}px`};
`;

export const CreateEmployeeRightTextGrid = styled(Grid)`
  padding-left: ${(props) =>
    `${props.theme.palette.spacing[0] * 2.5}px`}!important;
  padding-right: ${(props) =>
    `${props.theme.palette.spacing[1] * 2}px`}!important;
  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    padding-left: ${(props) =>
      `${props.theme.palette.spacing[1] * 5}px`}!important;
  }
`;
