import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const StyledGridContainer = styled(Grid)`
  margin: 12px 0px;
`;

export const StyledContainerGrid = styled(Grid)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
`;

export const StyledCard = styled(Card)`
  margin: 8px 24px 8px 0px;
`;

export const StyledCardContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledCardContent = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 1
    }px`};
`;

export const EmployeeSearchGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
`;

export const EmployeeDownloadIconGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: ${(props) =>
    `${props?.isFilterSelected ? "20px" : props?.isRight ? "10px" : "0px"}`};
  margin-left: ${(props) =>
    `${props?.isRight ? `${props.theme.palette.spacing[1] * 2}px` : "0px"}`};
  margin-top: ${(props) =>
    `${props?.isTop ? `${props.theme.palette.spacing[1] * 2}px` : "0px"}`};
  align-self: center;
`;

export const DataMainGrid = styled(Grid)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const NameValue = styled(Typography)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
