import React, { useState } from "react";
import TabPanel, { a11yProps } from "../../../../Common/TabPanel/TabPanel";
import {
  StickyTab,
  TabContainer,
  MuiCompactMarginTab,
  MuiLargeSpacingTab,
} from "../../../../Common/Common.styled";
import { usePermissionCustomHook } from "../../../../Common/CommonUtils";
import TeamTimesheet from "./TeamTimesheet";
import MyTimesheet from "../MyTimesheet";
import ViewTimesheet from "../ViewTimesheet";
import { useDispatch, useSelector } from "react-redux";
import { setTabValue } from "../../../../Common/store/tabSlice/tabSlice";
import { resetPage } from "../../../../Common/store/paginationSlice/paginationSlice";

const HrManagerTimesheetTab = () => {
  const dispatch = useDispatch();
  const isManager = usePermissionCustomHook("Manager");
  const tabValue = useSelector((state) => state.tabInfo?.tabValue);

  const handleTabChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
    dispatch(resetPage())
  };

  return (
    <>
      <TabContainer>
        <StickyTab
          value={tabValue}
          variant="scrollable"
          scrollButtons={false}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          <MuiCompactMarginTab label="My Timesheet " {...a11yProps(0)} />
          <MuiLargeSpacingTab label="View Timesheet" {...a11yProps(1)} />
          {isManager && (
            <MuiLargeSpacingTab label="Team Timesheet" {...a11yProps(2)} />
          )}
        </StickyTab>
      </TabContainer>

      <TabPanel value={tabValue} index={0}>
        <MyTimesheet />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <ViewTimesheet />
      </TabPanel>
      {isManager && (
        <TabPanel value={tabValue} index={2}>
          <TeamTimesheet />
        </TabPanel>
      )}
    </>
  );
};

export default HrManagerTimesheetTab;
