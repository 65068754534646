import React, { useState } from "react";
import DataGridComponent from "../../../Common/DataGridComponent";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useSideNav,
  useLoader,
  useNotification,
} from "../../../Common/CommonUtils";
import { generateYearsOptions } from "../../../Common/Constant";
import {
  QuaterText,
  DateText,
  OverviewGrid,
  StatusGrid,
  StyledLensIcon,
  StyledTypography,
} from "./MyPerformance.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyPerformanceOverview,
  getOverview,
} from "../services/PerformanceServices";
import { useForm } from "react-hook-form";
import FormDropDown from "../../../Common/FormDropDown";
import { useTheme } from "@emotion/react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { ClearFilter } from "../../MyAssignment/Components/MyAssignment.styled";
import ClearFilterButton from "../../Employees/ClearFilterButton";
import { formattedOverView } from "./OverviewDatagridUtils";
import { useNavigate } from "react-router-dom";
import {
  setIsTeamPerformance,
  setMyPerformance,
  setPerformanceId,
} from "../store/PerformanceSlice";
import useDebounceEffect from "../../../Common/CustomHooks/useDebounceEffect";
import {
  setPage,
  setPageSize,
} from "../../../Common/store/paginationSlice/paginationSlice";

const PerformanceOverview = () => {
  useSideNav();
  const theme = useTheme();
  const [overViewData, setOverViewData] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const [projectName, setProjectName] = useState("");
  const { setLoading, LoaderPopup } = useLoader();
  const userId = useSelector((state) => state.user?.data?.employeeId);
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const metaData = useSelector((state) => state?.metaData);
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = overViewData;

  const { projectList } = metaData || [];

  const yearOptions = generateYearsOptions();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [optionDropdown, setOptionDropdown] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: item.projectName,
      value: item.projectName,
    })) || [];

  const projectsOptionsList = mapToOptionsList(projectList);

  const useFunction = useForm({
    mode: "onChange",
  });

  const { control, reset, register } = useFunction;

  const columnData = [
    {
      field: "quarter",
      headerName: "Quarter",
      width: 170,
      renderCell: (params) => (
        <Grid container direction="column">
          <QuaterText>{params.row.quarter}</QuaterText>
          <DateText>{params.row.quarterMonth + " " + params.row.year}</DateText>
        </Grid>
      ),
    },
    { field: "projects", headerName: "Project", width: 170 },
    { field: "employeeAverage", headerName: "Employee Rating", width: 170 },
    { field: "managerAverage", headerName: "Manager Rating", width: 170 },
    { field: "submissionDate", headerName: "Submission Date", width: 180 },
    { field: "comments", headerName: "Comments", width: 180 },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
        const formattedStatus = capitalize(params?.row?.status);
        const value =
          formattedStatus === "Reviewed"
            ? theme.palette.success.main
            : theme.palette.warning.main;
        return (
          <StatusGrid>
            <StyledLensIcon color={value} />
            <StyledTypography color={value}>{formattedStatus}</StyledTypography>
          </StatusGrid>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      renderCell: (params) => {
        const { status } = params.row;
        const statusValue = status.toLowerCase();
        const iconAction =
          statusValue === "draft" ? (
            <EditIcon onClick={() => handleEditOverview(params)} />
          ) : (
            <VisibilityIcon onClick={() => handleEditOverview(params)} />
          );

        return <Button variant="text">{iconAction}</Button>;
      },
    },
  ];
  useDebounceEffect(
    () => {
      fetchOverviewData();
    },
    500,
    [projectName, optionDropdown, page, pageSize]
  );

  const fetchOverviewData = async () => {
    try {
      setLoading(true);
      setOverViewData({ ...overViewData, isLoading: true });
      const params = {
        userId: userId,
        employeeId: employeeId,
        ...(projectName && { project: projectName }),
        ...(optionDropdown && { year: optionDropdown }),
        page: page,
        limit: pageSize,
      };
      const getOvervieweDetails = getOverview(params);
      const growData = await getOvervieweDetails;
      const overviewDetails = formattedOverView(growData?.data);
      setOverViewData((prev) => ({
        ...prev,
        rows: overviewDetails,
        totalItems: growData?.pagination?.totalItems,
        isLoading: false,
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOverViewData({ ...overViewData, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }
  };

  const isFilterActive = projectName !== "" || optionDropdown !== "";

  const clearSelection = () => {
    setProjectName("");
    setOptionDropdown("");
    reset({ optionDropdown: "" });
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  const handleEditOverview = async (params) => {
    const { performanceId } = params.row;
    try {
      setLoading(true);
      const myPerformanceOverview = await getMyPerformanceOverview(
        employeeId,
        performanceId
      );
      dispatch(setMyPerformance(myPerformanceOverview?.data));
      setLoading(false);
      navigate(`./overview-performance/${performanceId}`);
      dispatch(setIsTeamPerformance(false));
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error?.response?.data?.message || error?.response?.data?.error
      );
    }

    dispatch(setPerformanceId(performanceId));
  };

  return (
    <>
      <OverviewGrid container spacing={2}>
        <Grid item xs={6} sm={3} md={2}>
          <FormDropDown
            label="Project"
            name="projectName"
            options={projectsOptionsList}
            control={control}
            register={register("projectName", {
              onChange: (e) => {
                setProjectName(e.target.value);
              },
            })}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <FormDropDown
            label="Year"
            name="optionDropdown"
            options={yearOptions}
            control={control}
            register={register("optionDropdown", {
              onChange: (e) => {
                setOptionDropdown(e.target.value);
              },
            })}
          />
        </Grid>
        {isFilterActive && (
          <ClearFilter item xs={4} sm={2}>
            <ClearFilterButton clearSelection={clearSelection} />
          </ClearFilter>
        )}
      </OverviewGrid>

      <DataGridComponent
        columnsData={columnData}
        rowData={overViewData?.rows}
        rowCount={totalItems}
        isLoading={isLoading}
        page={page - 1}
        editable={true}
        handlePaginationChange={handlePaginationChange}
      />
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default PerformanceOverview;
