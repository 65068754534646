import { DataGrid } from "@mui/x-data-grid";
import { getErrorMessage } from "./CommonUtils";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { StyledDataGrid } from "./Common.styled";

const DataGridComponent = (props) => {
  const {
    columnsData,
    rowData,
    rowCount,
    page,
    pageSizeValue,
    onRowClick,
    isLoading,
    height,
    width,
    noRowsOverlay,
    editable,
    hideFooter,
    dataTestId,
    pageSize,
    sorting,
    className = "dark-scrollbar",
    handlePaginationChange = () => false,
  } = props;

  const errorMessage = getErrorMessage();
  const pageSizeNumber = pageSize ? pageSize : 10;

  return (
    <Box sx={{ height: height, width: width }}>
      <StyledDataGrid
        className={className}
        columns={columnsData}
        limit={pageSizeValue}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSizeNumber,
              page: page,
            },
          },
          sorting: {
            sortModel: sorting?.sortModel || [],
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        rows={rowData}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        rowHeight={70}
        onRowClick={onRowClick}
        localeText={errorMessage}
        loading={isLoading}
        noRowsOverlay={null}
        editable={editable}
        hideFooter={hideFooter}
        data-testid={dataTestId}
        onPaginationModelChange={(model) => handlePaginationChange(model.page, model.pageSize)}
      />
    </Box>
  );
};

DataGridComponent.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DataGridComponent.defaultProps = {
  height: 520,
  width: "100%",
};

export default DataGridComponent;
