import { api } from "../../../Common/AxoisConfig";

export const getLeaves = async (employee_id , payload) => {
  const leaves = await api("FAST_API_URL").get(`leaves/history/${employee_id}` , {
    params: payload,
  });
  return leaves?.data;
};

export const getLeaveType = async () => {
  const leaves = await api("FAST_API_URL").get(`leaves/type`);
  return leaves?.data;
};

export const getLeavesRequests = async (payload) => {
  const leaves = await api("FAST_API_URL").get(`leaves/requests`, {
    params: payload,
  });
  return leaves?.data;
};

export const postApplyLeave = async (employee_id, payload) => {
  const applyLeave = await api("FAST_API_URL").post(
    `leaves/apply/${employee_id}`,
    payload
  );
  return applyLeave?.data;
};

export const getLeavesBalence = async (employee_id) => {
  const leaves = await api("FAST_API_URL").get(`leaves/balance/${employee_id}`);
  return leaves?.data;
};

export const getLeaveDetails = async (leaveId) => {
  const leaves = await api("FAST_API_URL").get(`leaves/details/${leaveId}`);
  return leaves?.data;
};

export const putLeaveUpdate = async (applied_leave_id, payload) => {
  const LeaveUpdate = await api("FAST_API_URL").put(
    `leaves/update/${applied_leave_id}`,
    payload
  );
  return LeaveUpdate?.data;
};

export const putCancelLeave = async (applied_leave_id, payload) => {
  const cancelLeave = await api("FAST_API_URL").put(
    `leaves/cancel/${applied_leave_id}`,
    payload
  );
  return cancelLeave?.data;
};

export const getHolidays = async (currentYear, searchQuery) => {
  const leaves = await api("FAST_API_URL").get(
    `holidays/${currentYear}/${searchQuery}`
  );
  return leaves?.data;
};

export const postApproveReject = async (userId, payload) => {
  const approveReject = await api("FAST_API_URL").patch(
    `notifications/${userId}`,
    payload
  );
  return approveReject?.data;
};
