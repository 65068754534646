import { api } from "../../../Common/AxoisConfig";

export const getTeamTimesheet = async (payload) => {
  const teamTimesheet = await api("NODE_API_URL").get(`timesheet/all`, {
    params: payload,
  });
  return teamTimesheet?.data;
};

export const getMyTimesheet = async (userId, employeeId) => {
  const myTimesheet = await api("NODE_API_URL").get(
    `timesheet/current/week?userId=${userId}&employeeId=${employeeId}`
  );
  return myTimesheet?.data;
};

export const putTeamTimesheetSubmit = async (payload) => {
  const teamTimesheetSubmit = await api("NODE_API_URL").put(`timesheet/submit` , payload);
  return teamTimesheetSubmit?.data;
};
