import React from "react";
import { DashboardContainer } from "../Dashboard.styled";
import Grid from "@mui/material/Grid";
import HolidayList from "../../../Leave/components/holidayList";
import HrAdminAttendance from "./HrAdminAttendance";
import HrAdminJobLevel from "./HrAdminJobLevel";
import HrAdminLeaveApproval from "./HrAdminLeaveApproval";
import HrPolicy from "../HrPolicy";
const HrAdminDashboard = () => {
  return (
    <>
      <DashboardContainer container spacing={1}>
        <Grid item xs={12} sm={6} lg={4}>
          <HrAdminAttendance />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <HrAdminJobLevel />
        </Grid>
        <Grid item xs={12} sm={6} lg={5}>
          <HolidayList />
        </Grid>
        <Grid item xs={12} sm={6} lg={7}>
          <HrAdminLeaveApproval />
        </Grid>
        <Grid item xs={12} sm={6} lg={5}>
          <HrPolicy />
        </Grid>
      </DashboardContainer>
    </>
  );
};

export default HrAdminDashboard;
