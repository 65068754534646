import React from "react";
import { Typography } from "@mui/material";

const HrAdminView = () => {
  return (
    <>
      <Typography mt={3}>HrAdmin view is updating</Typography>
    </>
  );
};

export default HrAdminView;
