import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Card from "@mui/material/Card";

export const LeaveStyledGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[0] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px `};
`;

export const LeaveTypography = styled(Typography)`
  marginleft: ${(props) => `${props.theme.palette.spacing[1] * 2}px `};
`;

export const HolidayGrid = styled(Grid)`
  marginbottom: ${(props) => `${props.theme.palette.spacing[1] * 2}px `};
`;

export const HolidayText = styled(Typography)`
  color: #fff;
`;

export const HolidayButton = styled(Button)`
  color: #fff;
`;

export const ForwardArrowIosIcon = styled(ArrowForwardIosIcon)`
  fontsize: 40px;
`;

export const BackArrowIosIcon = styled(ArrowBackIosIcon)`
  fontsize: 40px;
`;
export const LeaveTotal = styled(Typography)`
  font-family: Roboto;
  font-size: 26px;
  font-weight: bold;
  margin: 4px 0 0 0;
`;

export const Title = styled(Typography)`
  font-size: 12px;
  font-family: Roboto;
  color: gray;
  margin: 0;
`;

export const DoughnutContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DoughnutWrapper = styled(Grid)(({ theme }) => ({
  position: "relative",
  width: "150px",
  height: "160px",

  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: "120px",
    height: "120px",
  },
  [`@media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl}px)`]:
    {
      width: "130px",
      height: "130px",
      alignSelf: "center",
    },
}));

export const SlideStylesGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: cornflowerblue;
  text-align: center;
`;

export const DoughnutLeave = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export const StyledList = styled(List)`
  padding-left: 20px;
  font-size: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    width: 150px;
    padding-left: 25px;
  }

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    width: 132px;
  }
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  list-style-type: none;
  position: relative;
  padding-left: 0px;

  &::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: ${({ color }) => color || "#000"};
    margin-right: 8px;
    border-radius: 2px;
  }
`;

export const ListItemTextContainer = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 12px !important;
    font-family: Roboto;
    color: #737373;
  }
`;

export const MuiCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(2)}`};
  border-radius: 8px;
`;

export const LeaveChartCard = styled(MuiCard)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 48px;
`;

export const containerList = styled(Grid)`
  display: flex;
  marigin: 5px;
`;

export const HolidayListItem = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HolidayListContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    padding: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  }
`;

export const LeaveChartContainer = styled(Grid)`
  padding: 0px 24px 0px 0px;
`;

export const LeaveBottomContainer = styled(Grid)`
  margin-bottom: 56px;
`;

export const LeaveTitle = styled(Typography)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1}px `};
`;

export const HolidayTitle = styled(Typography)`
  font-weight: 500;
  font-size: 17px;
  color: #45464e;
  fontfamily: '"Inter", sans-serif';
`;

export const StyledHolidayListContainer = styled(List)`
  height: 40vh;
  overflow-y: auto;
  padding: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 20vh;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    height: 32vh;
  }
`;

export const StyledGrid = styled(StyledHolidayListContainer)`
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      "#d8d8d8" || theme.palette.warning.light};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  &.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &.no-scrollbar::-webkit-scrollbar {
    display: none; /* WebKit Browsers */
  }
`;

export const NoHolidaysContainer = styled(StyledHolidayListContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoHolidaysTitle = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
`;

export const StyledHolidayListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  color: ${({ isPast }) => (isPast ? "#b0b0b0" : "inherit")};
  opacity: ${({ isPast }) => (isPast ? 4 : 8)};
  line-height: 1.57;
`;

export const ApplyLeaveButton = styled(Button)`
  margin: ${(props) => `${props.theme.palette.spacing[1] * 1}px `};
`;
export const ManagerViewGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px `};
`;
