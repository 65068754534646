import React, { useEffect, useState } from "react";
import { Popover, Typography } from "@mui/material";
import { DataMainGrid } from "../../Employees/Employee.Styled";
import Grid from "@mui/material/Grid";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  AddeMyTimesheetFooterTableHeadingGrid,
  AddeMyTimesheetTableHeadingGrid,
  CalendarMonth,
  MyTimesheetContiner,
  MyTimesheetDate,
  MyTimesheetList,
  MyTimesheetListItem,
  Title,
  TitleDateFormat,
} from "./MyTimesheet.styled";
import { StyledButton } from "../../../Common/Ui/styled";
import { useFieldArray, useForm } from "react-hook-form";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MultiControlFormField from "../../../Common/MultiControlFormField";
import {
  formatDateRange,
  RenderRejectApproveButtons,
} from "../../../Common/CommonUtils";
import { MuiCard } from "../../../Common/Common.styled";
import { PerformanceColumn } from "../../Performance/Components/MyPerformance.styled";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DayCalendar } from "../../../Common/CalenderMonthWeek";
import {
  NoHolidaysContainer,
  NoHolidaysTitle,
} from "../../Leave/components/Leave.styled";
import useWeekDetails from "../../../Common/useWeekDetails";
import { CalendarMonthIconContainer } from "../../Attendance/Components/LogAttendance.styled";

const projects = ["Hyper", "VCM", "Delta", "Stop"];

const MyTimesheet = () => {
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const tempData = [];

  const {
    // weekData,
    // weekDetails,
    weekStartDate,
    weekEndDate,
    calendarAnchor,
    // setWeekStartDate,
    // setWeekEndDate,
    handleDateChange,
    handleCalendarIconClick,
    handleCalendarClose,
  } = useWeekDetails(tempData);
  const formattedDateRange = formatDateRange(weekStartDate, weekEndDate);

  const open = Boolean(calendarAnchor);

  const columnsData = [
    { field: "projectName", headerName: "Project Name", width: 1, medium: 1.8 },
    { field: "task", headerName: "Task", width: 1.5, medium: 2 },
    { field: "monday", headerName: "Mon", width: 1, medium: 0.8 },
    { field: "tuesday", headerName: "Tue", width: 1, medium: 0.8 },
    { field: "wednesday", headerName: "Wed", width: 1, medium: 0.8 },
    { field: "thursday", headerName: "Thu", width: 1, medium: 0.8 },
    { field: "friday", headerName: "Fri", width: 1, medium: 0.8 },
    { field: "saturday", headerName: "Sat", width: 1, medium: 0.8 },
    { field: "sunday", headerName: "Sun", width: 1, medium: 0.8 },
    {
      field: "totalHours",
      headerName: "Task Total Hrs / Week",
      width: 1,
      medium: 1,
    },
    { field: "managerName", headerName: "Manager", width: 1, medium: 1.1 },
    { field: "delete", headerName: "Action", width: 0.5, medium: 0.5 },
  ];

  const footerColumnsData = [
    { field: "totalColumn", headerName: "", width: 1, medium: 1.8 },
    { field: "total", headerName: "Total", width: 1.5, medium: 2 },
    { field: "monday", headerName: "0", width: 1, medium: 0.8 },
    { field: "tuesday", headerName: "0", width: 1, medium: 0.8 },
    { field: "wednesday", headerName: "0", width: 1, medium: 0.8 },
    { field: "thursday", headerName: "0", width: 1, medium: 0.8 },
    { field: "friday", headerName: "0", width: 1, medium: 0.8 },
    { field: "saturday", headerName: "", width: 1, medium: 0.8 },
    { field: "sunday", headerName: "", width: 1, medium: 0.8 },
    { field: "totalHours", headerName: "40", width: 1, medium: 1 },
    { field: "managerName", headerName: "", width: 1, medium: 1.1 },
    { field: "delete", headerName: "", width: 0.5, medium: 0.5 },
  ];

  const defaultValue = {
    projects: projects.map((project) => ({
      projectName: project,
      totalHours: 0,
      managerName: "Krishna Vamsi",
      fields: [
        {
          task: "",
          monday: "",
          tuesday: "",
          wednesday: "",
          thursday: "",
          friday: "",
          saturday: "",
          sunday: "",
          delete: false,
        },
      ],
    })),
    mondayAverage: 0,
    tuesdayAverage: 0,
    wednesdayAverage: 0,
    thursdayAverage: 0,
    fridayAverage: 0,
    totalHoursAverage: 0,
  };

  const { control, handleSubmit, watch, setValue, register, getValues } =
    useForm({
      mode: "onChange",
      defaultValues: defaultValue,
    });

  const {
    fields: projectFields,
    // append: appendProject,
    update,
  } = useFieldArray({
    control,
    name: "projects",
  });

  const handleTimeSheetColumn = (projectIndex) => {
    const fields = watch(`projects.${projectIndex}.fields`);
    const updatedFields = [
      ...fields,
      {
        task: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
        sunday: "",
        delete: false,
      },
    ];
    setValue(`projects.${projectIndex}.fields`, updatedFields, {
      shouldDirty: true,
    });
  };

  const onSubmit = async (data) => {
    console.log(data);
  };

  const handleSubmitData = (data) => {
    setRejectLoading(false);
    setApproveLoading(true);
    onSubmit(data, "submit");
  };

  const handleSaveAndDraft = (data) => {
    setRejectLoading(true);
    setApproveLoading(false);
    onSubmit(data, "draft");
  };

  const handleDeleteRow = (projectIndex, rowIndex) => {
    const fields = watch(`projects.${projectIndex}.fields`);
    const updatedFields = fields.filter((_, index) => index !== rowIndex);
    setValue(`projects.${projectIndex}.fields`, updatedFields, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    handleDateChange(new Date());
  }, []);

  const handleCountChange = (index, fieldIndex, selfTimeHours, column) => {
    const isValidRating =
      selfTimeHours === "" ||
      (Number(selfTimeHours) >= 0 && Number(selfTimeHours) <= 12);
    const safeValue = isValidRating ? selfTimeHours : "0";

    setValue(
      `projects.${index}.fields.${fieldIndex}.${column.field}`,
      safeValue
    );

    const days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
      "totalHours",
    ];

    const projects = getValues("projects");
    const currentFields = getValues(`projects.${index}.fields`);

    const totalCounts = currentFields.reduce((acc, field) => {
      days.forEach((day) => {
        acc[day] = (acc[day] || 0) + (Number(field[day]) || 0);
      });
      return acc;
    }, {});

    const overallCount = Object.values(totalCounts).reduce(
      (sum, value) => sum + value,
      0
    );
    setValue(`projects.${index}.totalHours`, overallCount);

    update(index, { ...getValues(`projects.${index}`) });

    const totalHoursSum = projects.reduce(
      (sum, project) => sum + (project.totalHours || 0),
      0
    );

    setValue("totalHoursAverage", totalHoursSum);

    const ratingAverage = projects.reduce((sum, project) => {
      const projectFieldSum = project.fields?.reduce((fieldSum, field) => {
        return fieldSum + (parseFloat(field[column.field]) || 0);
      }, 0);
      return sum + (projectFieldSum || 0);
    }, 0);

    if (days.includes(column.field)) {
      setValue(`${column.field}Average`, ratingAverage);
    } else if (column.field === "totalHours") {
      setValue(`${column.field}Average`, totalHoursSum);
    }
  };

  const mondayAverage = watch("mondayAverage");
  const tuesdayAverage = watch("tuesdayAverage");
  const wednesdayAverage = watch("wednesdayAverage");
  const thursdayAverage = watch("thursdayAverage");
  const fridayAverage = watch("fridayAverage");
  const totalHoursAverage = watch("totalHoursAverage");

  return (
    <>
      <DataMainGrid>
        <MyTimesheetContiner container spacing={2}>
          <Grid>
            <Title>Weekly Sheet</Title>
          </Grid>
          <MyTimesheetDate>
            <MyTimesheetDate>
              <StyledButton
                width={"40px"}
                borderRadius={"5px"}
                variant="outlined"
              >
                <ArrowBackIosSharpIcon />
              </StyledButton>
              <TitleDateFormat>{formattedDateRange} </TitleDateFormat>
              <StyledButton
                width={"40px"}
                borderRadius={"5px"}
                variant="outlined"
              >
                <ArrowForwardIosSharpIcon />
              </StyledButton>
            </MyTimesheetDate>

            <CalendarMonth size="large" onClick={handleCalendarIconClick}>
              <CalendarMonthIconContainer />
            </CalendarMonth>
          </MyTimesheetDate>
        </MyTimesheetContiner>
        <MuiCard>
          <Grid xs={12}>
            <AddeMyTimesheetTableHeadingGrid
              container
              spacing={2}
              blockColor={true}
            >
              {columnsData.map((column) => (
                <Grid
                  item
                  xs={12}
                  sm={column.width}
                  xl={column.medium}
                  key={column.field}
                >
                  <PerformanceColumn
                    variant="body2"
                    style={{ textAlign: "center" }}
                  >
                    {column.headerName}
                  </PerformanceColumn>
                </Grid>
              ))}
            </AddeMyTimesheetTableHeadingGrid>
            {projectFields.length > 0 ? (
              projectFields.map((project, index) => (
                <Grid container key={index}>
                  {watch(`projects.${index}.fields`).map(
                    (field, fieldIndex) => (
                      <MyTimesheetListItem key={fieldIndex} blockColor={true}>
                        <MyTimesheetList container spacing={2}>
                          {columnsData.map((column) => (
                            <Grid
                              item
                              xs={12}
                              sm={column.width}
                              xl={column.medium}
                              key={column.field}
                              style={{
                                display:
                                  column.field === "task" ? "flex" : "block",
                                alignItems:
                                  column.field === "task" ? "center" : "unset",
                              }}
                            >
                              {column.field === "projectName" &&
                              fieldIndex === 0 ? (
                                <MultiControlFormField
                                  name={`projects.${index}.projectName`}
                                  multiline
                                  control={control}
                                  defaultValue={field.projectName || ""}
                                  type="text"
                                />
                              ) : column.field === "projectName" &&
                                fieldIndex !== 0 ? (
                                <Grid
                                  item
                                  xs={12}
                                  sm={column.width}
                                  xl={column.medium}
                                  key={column.field}
                                  style={{ width: 280 }}
                                ></Grid>
                              ) : column.field === "task" ? (
                                <>
                                  <MultiControlFormField
                                    name={`projects.${index}.fields.${fieldIndex}.task`}
                                    control={control}
                                    multiline
                                    defaultValue={field.task || ""}
                                    type="text"
                                  />
                                  <AddRoundedIcon
                                    style={{
                                      marginLeft: "8px",
                                      color: "#6C737F",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleTimeSheetColumn(index)}
                                  />
                                </>
                              ) : [
                                  "monday",
                                  "tuesday",
                                  "wednesday",
                                  "thursday",
                                  "friday",
                                  "saturday",
                                  "sunday",
                                ].includes(column.field) ? (
                                <MultiControlFormField
                                  name={`projects.${index}.fields.${fieldIndex}.${column.field}`}
                                  control={control}
                                  defaultValue={field[column.field] || ""}
                                  multiline
                                  type="number"
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                      max: 12,
                                    },

                                    readOnly:
                                      column.field === "sunday" ||
                                      column.field === "saturday",
                                  }}
                                  register={register(
                                    `projects.${index}.fields.${fieldIndex}.${column.field}`,
                                    {
                                      onChange: (e) =>
                                        handleCountChange(
                                          index,
                                          fieldIndex,
                                          e.target.value,
                                          column
                                        ),
                                    }
                                  )}
                                />
                              ) : ["totalHours", "managerName"].includes(
                                  column.field
                                ) && fieldIndex === 0 ? (
                                <Typography style={{ textAlign: "center" }}>
                                  {project[column.field]}
                                </Typography>
                              ) : column.field === "delete" ? (
                                <DeleteOutlineIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    alignSelf: "center",
                                  }}
                                  onClick={() =>
                                    handleDeleteRow(index, fieldIndex)
                                  }
                                />
                              ) : null}
                            </Grid>
                          ))}
                        </MyTimesheetList>
                      </MyTimesheetListItem>
                    )
                  )}
                </Grid>
              ))
            ) : (
              <NoHolidaysContainer>
                <NoHolidaysTitle variant="h6">
                  No Timesheet Records Found
                </NoHolidaysTitle>
              </NoHolidaysContainer>
            )}
          </Grid>
          <AddeMyTimesheetFooterTableHeadingGrid
            container
            spacing={2}
            blockColor={true}
          >
            {footerColumnsData.map((column) => (
              <Grid
                item
                xs={12}
                sm={column.width}
                xl={column.medium}
                key={column.field}
              >
                {[
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                ].includes(column.field) ? (
                  <MultiControlFormField
                    name={column.field}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    control={control}
                    textOnly={true}
                    defaultValue={defaultValue[`${column.field}Average`]}
                    value={
                      column.field === "monday"
                        ? mondayAverage
                        : column.field === "tuesday"
                        ? tuesdayAverage
                        : column.field === "wednesday"
                        ? wednesdayAverage
                        : column.field === "thursday"
                        ? thursdayAverage
                        : fridayAverage
                    }
                    type="text"
                  />
                ) : column.field === "totalHours" ? (
                  <MultiControlFormField
                    name="totalHours"
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                    control={control}
                    textOnly={true}
                    defaultValue={defaultValue.totalHoursAverage}
                    value={totalHoursAverage}
                    type="text"
                  />
                ) : (
                  <PerformanceColumn variant="body2">
                    {column.headerName || " "}
                  </PerformanceColumn>
                )}
              </Grid>
            ))}
          </AddeMyTimesheetFooterTableHeadingGrid>
        </MuiCard>

        <RenderRejectApproveButtons
          handleReject={handleSubmit(handleSaveAndDraft)}
          handleApproveReject={handleSubmit(handleSubmitData)}
          rejectLoading={rejectLoading}
          approveLoading={approveLoading}
          saveOrRejectButtonLabel="Save & Draft"
          submitButtonLabel="Submit"
          width={"150px"}
          color={"primary"}
        />
        <Popover
          open={open}
          anchorEl={calendarAnchor}
          onClose={handleCalendarClose}
        >
          <DayCalendar onDateSelect={handleDateChange} />
        </Popover>
      </DataMainGrid>
    </>
  );
};

export default MyTimesheet;
