import React from "react";
import { Controller } from "react-hook-form";
import { FieldText } from "./FormTextField.styled";
export const FormTextField = (props) => {
  const {
    name,
    control,
    label,
    type,
    shrink,
    multiline,
    rows,
    disabled,
    register,
    minValue,
    maxValue,
    errors,
    helperText,
    placeholder,
    endAdornment,
    readOnly,
    style,
    variant,
  } = props;

  const handleInputChange = (event, onChange) => {
    const { value } = event.target;
    if (type === "textOnly") {
      const textType = value.replace(/[^a-zA-Z\s]/g, "");
      onChange(textType);
    } else if (type === "number") {
      const textType = value.replace(/[^0-9]/g, "");
      onChange(textType);
    } else {
      onChange(value);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      register={register}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <FieldText
          disableFocus={readOnly}
          onChange={(e) => handleInputChange(e, onChange)}
          value={value == null ? "" : value}
          disabled={disabled}
          label={label}
          type={type}
          {...(variant && { variant })}
          multiline={multiline}
          error={errors}
          helperText={helperText}
          placeholder={placeholder}
          rows={rows}
          autoComplete="off"
          data-testid={name}
          InputLabelProps={{
            shrink: Boolean(value) || shrink,
          }}
          InputProps={{
            inputProps: { min: minValue, max: maxValue },
            endAdornment: endAdornment,
            readOnly: readOnly,
          }}
          style={{
            width: "100%",
            marginBottom: "16px",
            ...style,
          }}
        />
      )}
    />
  );
};
