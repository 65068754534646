import { styled } from "@mui/system";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Grid, ListItem, Typography } from "@mui/material";

export const ListStyled = styled(List)({
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
});

export const StyledDivider = styled(Divider)({
  height: "1px",
  bgcolor: "black",
  margin: "0px",
});

export const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
`;

export const PdfList = styled(Typography)`
  flex-grow: 1;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 950px) {
    font-size: 14px;
  }
  @media screen and (max-width: 750px) {
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
`;

export const Policytext = styled(Typography)`
  font-weight: 600;
  font-size: 17px;
  color: #45464e;
  fontfamily: '"Inter", sans-serif';
`;

export const ViewTypo = styled(Typography)`
  font-weight: 400;
  font-size: 15px;
  color: #01008a;
  cursor: pointer;
  fontfamily: '"Inter", sans-serif';
  line-height: 14.52px;
`;

export const PolicyGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
