export const formattedHistory = (historyData) => {
  if (!Array.isArray(historyData) || historyData.length === 0) {
    return [];
  }

  return historyData?.map((history) => {
    return {
      id: history.employeeId,
      startDate: history.startDate,
      relievedDate: history.relievedDate === null ? "" : history.relievedDate,
      projectName: history.projectName,
      project_id: history.project_id,
      status: history.status,
      department: history.department,
      billableType: history.billableType,
      managerName: history.managerName,
    };
  });
};
