import { SvgIcon } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
// import DescriptionIcon from "@mui/icons-material/Description";
import AdjustIcon from "@mui/icons-material/Adjust";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import ChecklistRoundedIcon from "@mui/icons-material/ChecklistRounded";
// import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
// import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

export const items = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: (
      <SvgIcon fontSize="medium">
        <DashboardRoundedIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Employees",
    path: "/employees",
    icon: (
      <SvgIcon fontSize="medium">
        <GroupsIcon />
      </SvgIcon>
    ),
  },
  // {
  //   title: "My Action",
  //   path: "/my-action",
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <SettingsBackupRestoreIcon />
  //     </SvgIcon>
  //   ),
  // },
  {
    title: "My Assignment",
    path: "/my-assignment",
    icon: (
      <SvgIcon fontSize="medium">
        <AssignmentIndOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Leave",
    path: "/leave",
    icon: (
      <SvgIcon fontSize="medium">
        <EventNoteOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: "My Skill",
    path: "/my-skill",
    icon: (
      <SvgIcon fontSize="medium">
        <AdjustIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Attendance",
    path: "/attendance",
    icon: (
      <SvgIcon fontSize="medium">
        <ChecklistRoundedIcon />
      </SvgIcon>
    ),
  },

  // {
  //   title: "Employee CheckIn",
  //   path: "/employee-checkin",
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <HowToRegOutlinedIcon />
  //     </SvgIcon>
  //   ),
  // },

  {
    title: "Attendance Request",
    path: "/attendance-request",
    icon: (
      <SvgIcon fontSize="medium">
        <InventoryOutlinedIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Performance",
    path: "/performance",
    icon: (
      <SvgIcon fontSize="medium">
        <EqualizerRoundedIcon />
      </SvgIcon>
    ),
  },
  // {
  //   title: "Timesheet",
  //   path: "/timesheet",
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <AccessTimeIcon />
  //     </SvgIcon>
  //   ),
  // },

  // {
  //   title: 'Payroll',
  //   path: '/payroll',
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <PaidIcon />
  //     </SvgIcon>
  //   ),
  // },
  // {
  //   title: 'Asset Management',
  //   path: '/asset-management',
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <DevicesIcon />
  //     </SvgIcon>
  //   ),
  // },
  // {
  //   title: 'User Management',
  //   path: '/user-management',
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <ManageAccountsIcon />
  //     </SvgIcon>
  //   ),
  // },
  // {
  //   title: "Invoice",
  //   path: "/invoice",
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <DescriptionIcon />
  //     </SvgIcon>
  //   ),
  // },
];
