import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TimeCheckIn from "./TimeCheckIn";
import { getAttendanceSummary } from "./services/Services";
import { StyledGridContainer } from "./AttendanceBarChart.styled";
import {
  useNotification,
  useSideNav,
  usePermissionCustomHook,
  useLoader,
} from "../../Common/CommonUtils";
import { useSelector } from "react-redux";
import LogAttendanceDataGrid from "./Components/LogAttendanceDatagrid";
import AttendanceBarChart from "./AttendanceBarChart";

const Attendance = () => {
  useSideNav();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const designation = useSelector((state) => state.user?.data?.designation);
  const role = useSelector((state) => state.user?.data?.role);
  const [checkInCheckOutTimeDetails, setCheckInCheckOutTimeDetails] =
    useState(null);
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const monthYear = `${month}-${year}`;
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");
  const isHR = usePermissionCustomHook("HR");

  useEffect(() => {
    fetchAttendance();
  }, []);

  const fetchAttendance = async () => {
    try {
      setLoading(true);
      const payload = {
        user_id: employeeId,
        month_year: monthYear,
      };
      const attendanceSummary = await getAttendanceSummary(payload);
      setCheckInCheckOutTimeDetails(attendanceSummary?.data);
      setLoading(false);
    } catch (error) {
      setOpenNotification(true);
      setLoading(false);
      setNotificationMessage(
        error.response?.data?.message || "Failed to fetch attendance."
      );
    }
  };

  const isHRAdmin = designation === "HR Admin" && role === "HR";

  return (
    <>
      <StyledGridContainer container spacing={2}>
        {!isHRAdmin && (
          <>
            <Grid item xs={12} sm={5} md={5}>
              <TimeCheckIn
                fetchAttendance={fetchAttendance}
                checkInCheckOutTimeDetails={checkInCheckOutTimeDetails}
                linkAttendance
              />
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
              <AttendanceBarChart />
            </Grid>
          </>
        )}
      </StyledGridContainer>
      {(isDeveloper || isManager || isHR) && <LogAttendanceDataGrid />}

      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default Attendance;
