import Grid from "@mui/material/Grid";

import { useNavigate } from "react-router-dom";

import {
  HynivaLogoStyle,
  LoginPageBox,
  LoginPageHeading,
  LoginPageMainGrid,
} from "../../Login/Login.styled";
import HynivaLogo from "../../../Assets/Images/HynivaLogo.png";

import { useLoader, useNotification, useRemoveSideNav } from "../../../Common/CommonUtils";

import { useSelector } from "react-redux";

import {
  ResetPasswordHeadingGrid,
  ResetPasswordLogoGrid,
} from "../ResetPassword/ResetPassword.styled";
import ChangePasswordForm from "./ChangePasswordForm";
import { postChangePasswordApi } from "../../Login/LoginApiServices/LoginApi";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";

const ChangePassword = () => {
  useRemoveSideNav();
  const navigate = useNavigate();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
    const { setLoading, LoaderPopup } = useLoader();
  const { showSnackbar, AlertSnackbar } = useSnackbar();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const handleCancel = () => {
    navigate(-1);
  };
  const handlePassWordDetailsSubmit = async (data) => {
    const { current_password, new_password, confirm_new_password } = data;
    const payload = {
      current_password,
      new_password,
      confirm_new_password,
    };

    try {
      setLoading(true)
      const changePassword = await postChangePasswordApi(loginUserId, payload);
      showSnackbar(
        changePassword?.message || "Password has been changed Successfully"
      );
      setLoading(false)
      navigate(-1);
    } catch (error) {
      setLoading(false)
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <LoginPageMainGrid item xs={12}>
          <LoginPageBox>
            <ResetPasswordLogoGrid>
              <Grid>
                <HynivaLogoStyle src={HynivaLogo} />
              </Grid>
              <ResetPasswordHeadingGrid>
                <LoginPageHeading variant="h4">
                  Change Password
                </LoginPageHeading>
              </ResetPasswordHeadingGrid>
            </ResetPasswordLogoGrid>
            <ChangePasswordForm
              onSubmit={handlePassWordDetailsSubmit}
              onCancel={handleCancel}
            />
          </LoginPageBox>
        </LoginPageMainGrid>
      </Grid>
      <NotificationPopup />
      <LoaderPopup />
      <AlertSnackbar />
    </>
  );
};

export default ChangePassword;
