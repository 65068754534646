import React, { useEffect } from "react";
import EmployeeDetailsTabs from "./EmployeeDetailsTabs";
import {
  ViewDetailsPageAvatar,
  ViewDetailsPageGrid,
} from "./ViewDetailsPage.styled";
import EmployeeName from "./EmployeeName";
import { useDispatch, useSelector } from "react-redux";
import {
  showLoader,
  useAuthentication,
  useLoader,
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../../Common/CommonUtils";
import { setIsLoading } from "../../../Common/Loader/store/loaderSlice";
import {
  getAsset,
  getDesignationByRole,
  getEmployee,
} from "../Services/service";
import {
  resetPersonalInformationForm,
  setPersonalInformationForm,
} from "../store/personalInformation/personalInformationSlice";
import { setIsViewDetailsPage } from "../store/EmployeeSlice";
import { setDesignations } from "../../../Common/store/metaDataSlice/metaDataSlice";
import { setAssetInfoForm } from "../store/assetsInformationSlice/assetInfoSlice";

const ViewDetailsPage = () => {
  useSideNav();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const employeeId = useSelector((state) => state?.employeeInfo?.employeeId);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const designationRole = useSelector((state) => state.user?.data?.role);
  const isHR = usePermissionCustomHook("HR");
  const employeeData = useSelector((state) => state?.personalInformationForm);

  const employeeRole = useSelector(
    (state) => state?.employeeInfo?.employeeRole
  );
  const isLoading = useSelector((state) => state?.loader?.isLoading);

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    fetchDesignationByRole();
  }, []);

  const fetchEmployee = async () => {
    try {
      setLoading(false);
      dispatch(setIsLoading(true));
      const response = await getEmployee(loginUserId, employeeId);
      const asset = await getAsset(loginUserId, employeeId);
      dispatch(setPersonalInformationForm(response?.data));
      dispatch(setAssetInfoForm(asset?.data));
      dispatch(setIsViewDetailsPage(true));
      dispatch(setIsLoading(false));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      dispatch(setIsViewDetailsPage(true));
      dispatch(setIsLoading(false));
      dispatch(resetPersonalInformationForm(false));
    }
  };

  const fetchDesignationByRole = async () => {
    const designationByRole = isHR ? employeeRole : designationRole;
    try {
      setLoading(true)
      const designations = await getDesignationByRole(designationByRole);
      dispatch(setDesignations(designations));
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  if (isLoading) {
    return showLoader();
  }

  return (
    <>
      {employeeData && (
        <ViewDetailsPageGrid>
          <ViewDetailsPageAvatar />
          <EmployeeName />
        </ViewDetailsPageGrid>
      )}
      <EmployeeDetailsTabs />
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default ViewDetailsPage;
