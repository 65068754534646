export const getMyAssignmentData = (MyAssignmentData) => {
  if (!MyAssignmentData || MyAssignmentData.length === 0) {
    return [];
  }

  const rows = [];

  MyAssignmentData.forEach((employee) => {
    if (employee.projectAssignments && employee.projectAssignments.length > 0) {
      employee.projectAssignments.forEach((project) => {
        rows.push({
          id: `${employee.employeeId}-${project.projectAssignmentId}`,
          employeeId: employee.employeeId,
          employeeName: employee.name,
          department: project.department || null,
          projectName: project.projectName || null,
          utilization: project.utilization || 0,
          billableType: project.billableType || null,
          managerName: project.managerName || null,
          projectAssignmentId: project.projectAssignmentId,
          startDate: project.startDate,
          relievedDate: project.relievedDate,
          createdDateTime: project.createdDateTime,
          updatedDateTime: project.updatedDateTime,
          createdBy: project.createdBy,
          updatedBy: project.updatedBy,
          status: project.status,
          comments: project.comments,
        });
      });
    } else {
      rows.push({
        id: employee.employeeId,
        employeeId: employee.employeeId,
        employeeName: employee.name,
        department: null,
        projectName: "No projects assigned",
        utilization: 0,
        billableType: null,
        managerName: null,
        projectAssignmentId: null,
        startDate: null,
        relievedDate: null,
        createdDateTime: null,
        updatedDateTime: null,
        createdBy: null,
        updatedBy: null,
        status: null,
        comments: null,
      });
    }
  });

  return rows;
};
