import React, { useEffect, useState } from "react";
import { Route, Routes as RouterRoutes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../Components/Login/Login";
import Attendance from "../Components/Attendance/Attendance";
import ForgotPassword from "../Components/ForgotPassword/ForgotPassword";
import ResetPassword from "../Components/ForgotPassword/ResetPassword/ResetPassword";
import { SideNavbar } from "../Components/SideNavbar/SideNavbar";
import Employee from "../Components/Employees/Employee";
import TopNav from "../Components/SideNavbar/TopNav";
import { LayoutContainer, LayoutRoot } from "./EmployeePortalConfig.Styled";
import ViewDetailsPage from "../Components/Employees/ViewDetailspage/ViewDetailsPage";
import Invoice from "../Components/Invoice/Invoice";
import CreateInvoice from "../Components/Invoice/CreateInvoice/CreateInvoice";
import AssetMangement from "../Components/AssetManagment/AssetMangement";
import Payroll from "../Components/Payroll/payroll";
import UserMangement from "../Components/UserManagment/UserMangement";
import Leave from "../Components/Leave/components/Leave";
import HrManagerSkill from "../Components/MySkill/ManagerAndHrViewSkills/HrManagerSkill";
import ChangePassword from "../Components/ForgotPassword/ChangePassword/ChangePassword";
import Dashboard from "../Components/Dashboard/Dashboard";
import ProtectedRoute from "../Common/ProtectedRoute";
import HrAction from "../Components/HrAction/components/HrAction";
import {
  resetNavigationRoute,
  setNavigationRoute,
} from "../Components/SideNavbar/Store/navigationSlice/navigationSlice";
import {
  TimeOutPopup,
  useAuthentication,
  useLoader,
  useNotification,
} from "../Common/CommonUtils";
import { setIsAuthenticated } from "../Common/store/authenticatedSlice/authenticatedSlice";
import { postLogout } from "../Components/Login/LoginApiServices/LoginApi";
import { setIsSideNav } from "../Components/SideNavbar/Store/slideNavbarSlice";
import { resetPasswordEmial } from "../Components/ForgotPassword/ResetPassword/store/resetPasswordSlice";
import {
  resetIsTimeout,
  setIsLogoutTiming,
} from "../Common/store/timeoutSlice/timeoutSlice";
import { resetUserInfo } from "../Common/store/userSlice/userSlice";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import HrManagerMyAssignment from "../Components/MyAssignment/Components/ManagerAndHrViewMyAssignment/HrMangerMyAssignment";
import { StyledGrid } from "../Common/Common.styled";
import HrManagerPerformance from "../Components/Performance/Components/HrAndManagerViewPerformance/HrManagerPerformance";
import MyPerformance from "../Components/Performance/Components/MyPerformance";
import HrManagerTimesheet from "../Components/Timesheet/Components/ManagerAndHrViewTimesheet/HrManagerTimesheet";
import HrEmployeeAttendanceDatagrid from "../Components/Attendance/Components/HREmployeeAttendence/HREmployeeAttendence";
import HrViewAttendanceDatagrid from "../Components/Attendance/Components/HrViewAttendance/HrViewAttendanceDatagrid";

const EmployeePortalConfig = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.up("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [isMobileNavbar, setIsMobileNavbar] = useState(false);
  const [isMiniNavbar, setIsMiniNavbar] = useState(true);
  const navigationRoute = useSelector(
    (state) => state.navigation.navigationRoute
  );

  const isSideNav = useSelector((state) => state.sideNav.isSideNav);

  const [seconds, setSeconds] = useState(60);
  const authorization = useAuthentication();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const navigate = useNavigate();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const user = useSelector((state) => state.user);

  const isLogoutTiming = useSelector((state) => state.timeout.isLogoutTiming);
  let inactivityTimer;

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      dispatch(setIsLogoutTiming(true));
    }, 9 * 60 * 1000);
  };
  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  useEffect(() => {
    if (user !== null) {
      resetInactivityTimer();
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);

      return () => {
        clearTimeout(inactivityTimer);
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
      };
    }
  }, [user]);

  useEffect(() => {
    if (isLogoutTiming) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      if (seconds === 0) {
        handleLogout();
        clearInterval(interval);
        setTimeout(() => {
          dispatch(resetIsTimeout());
        }, 2000);
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [isLogoutTiming, seconds]);

  const handleLogout = async () => {
    try {
      setLoading(true);
      dispatch(setIsLogoutTiming(false));
      await postLogout(authorization, loginUserId);
      dispatch(setIsSideNav(false));
      dispatch(resetPasswordEmial());
      dispatch(setIsAuthenticated(false));
      dispatch(resetNavigationRoute());
      dispatch(resetUserInfo());
      navigate("/");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error.response.data.message || error.response.data.detail
      );
      dispatch(setIsAuthenticated(false));
    }
  };

  const handleActivePath = (path) => {
    dispatch(setNavigationRoute(path));
  };

  return (
    <>
      <StyledGrid className="dark-scrollbar">
        {isSideNav && (
          <TopNav
            isMiniNavbar={isMiniNavbar}
            setIsMiniNavbar={setIsMiniNavbar}
            isMobileNavbar={isMobileNavbar}
            setIsMobileNavbar={setIsMobileNavbar}
          />
        )}
        {isSideNav && (
          <SideNavbar
            handleActivePath={handleActivePath}
            activePath={navigationRoute}
            isMiniNavbar={isMiniNavbar}
            setIsMiniNavbar={setIsMiniNavbar}
            isMobileNavbar={isMobileNavbar}
            setIsMobileNavbar={setIsMobileNavbar}
          />
        )}
        {isSideNav && (
          <TimeOutPopup
            seconds={seconds}
            setSeconds={setSeconds}
            signOut={handleLogout}
          />
        )}
        <NotificationPopup />
        <LoaderPopup />
        <LayoutRoot isSideNav={isSideNav} isTab={isTab} isSmall={isSmall}>
          <LayoutContainer>
            <RouterRoutes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/attendance" element={<Attendance />} />
                <Route
                  path="/attendance/employee-checkin/:id"
                  element={<Attendance />}
                />
                <Route
                  path="/employee-checkin"
                  element={<HrEmployeeAttendanceDatagrid />}
                />
                <Route
                  path="/attendance-request"
                  element={<HrViewAttendanceDatagrid />}
                />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/my-assignment"
                  element={<HrManagerMyAssignment />}
                />
                <Route path="/timesheet" element={<HrManagerTimesheet />} />
                <Route path="/performance" element={<HrManagerPerformance />} />
                <Route path="/my-skill" element={<HrManagerSkill />} />
                <Route path="/asset-management" element={<AssetMangement />} />
                <Route path="/user-management" element={<UserMangement />} />
                <Route path="/payroll" element={<Payroll />} />
                <Route
                  path="/invoice/createInvoice"
                  element={<CreateInvoice />}
                />
                <Route path="/changePassword" element={<ChangePassword />} />
                <Route path="/employees" element={<Employee />} />
                <Route path="/my-action" element={<HrAction />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/leave" element={<Leave />} />
                <Route
                  path="/employees/view-details-page/:id"
                  element={<ViewDetailsPage />}
                />
                <Route
                  path="/view-details-page/:id"
                  element={<ViewDetailsPage />}
                />
                <Route
                  path="/performance/overview-performance/:id"
                  element={<MyPerformance />}
                />
              </Route>
            </RouterRoutes>
          </LayoutContainer>
        </LayoutRoot>
      </StyledGrid>
    </>
  );
};

export default EmployeePortalConfig;
