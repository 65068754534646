export const formattedGrowth = (growtHistoryhData) => {
  if (!Array.isArray(growtHistoryhData) || growtHistoryhData.length === 0) {
    return [];
  }
  return growtHistoryhData.map((growData) => ({
    ...growData,
    id: growData.employeeGrowthHistoryId,
    type: growData.type,
    promotion: growData.promotion,
    startDate: growData.createdDateTime,
  }));
};
