import { useState } from "react";
import {
  LogContainer,
  StatusContainer,
  StyledIcon,
  StyledText,
  RequestTypo,
  LogAttendenceContainer,
  DownloadAttendence,
  CardContainer,
  StyledCard,
  IconContainer,
  ContentContainer,
  CardText,
  ChangeText,
  ContentIconContainer,
  CardTitle,
  FilterAttendenceContainer,
  FilterAttendence,
  CalendarMonthIconContainer,
  SearchContainer,
  ProfileContainer,
  CheckboxLabel,
  EmpTypography,
  BackButtonGrid,
  BackButtonStyle,
} from "../LogAttendance.styled";
import DataGridComponent from "../../../../Common/DataGridComponent";
import LogAttendanceDailog from "../LogAttendanceDailog";
import {
  dropDownOptions,
  useLoader,
  useNotification,
  usePermissionCustomHook,
} from "../../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttendanceDowndload,
  getAttendanceRequests,
} from "../../services/Services";
import {
  FormControlLabel,
  Button,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import useDebounceEffect from "../../../../Common/CustomHooks/useDebounceEffect";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";
import DownloadDropdown from "../../../../Common/DownloadDropdown";
import { downloadOptions } from "../../../../Common/Constant";
import { EmployeeDownloadIconGrid } from "../../../Employees/Employee.Styled";
import PersonIcon from "@mui/icons-material/Person";
import BeachAccessOutlinedIcon from "@mui/icons-material/BeachAccessOutlined";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import { StyledGrid } from "../../../../Common/Common.styled";
import { FormInputSearch } from "../../../../Common/FormInputSearch";
import { useForm } from "react-hook-form";
import {
  CalendarMonth,
  CheckboxCloumn,
  CheckboxRow,
} from "../../../Timesheet/Components/MyTimesheet.styled";
import useWeekDetails from "../../../../Common/useWeekDetails";
import { DayCalendar } from "../../../../Common/CalenderMonthWeek";
import FormDropDown from "../../../../Common/FormDropDown";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { CheckboxContainer } from "../../../MyAssignment/Components/MyAssignment.styled";
import ClearFilterButton from "../../../Employees/ClearFilterButton";
import { HrViewAttendanceDatagridUtils } from "../HrViewAttendance/HrViewAttendanceDatagridUtils";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { setEmployeeAttendanceId } from "../../store/employeeAttendanceIdSlice/employeeAttendanceIdSlice";
import { setNavigationRoute } from "../../../SideNavbar/Store/navigationSlice/navigationSlice";

const cardData = [
  {
    title: "Total Employee",
    value: 150,
    change: "100%",
    day: "Yesterday",
    color: "green",
    icon: <BusinessCenterOutlinedIcon fontSize="small" />,
  },
  {
    title: "Present Employee",
    value: 125,
    change: "95%",
    day: "Yesterday",
    color: "green",
    icon: <PersonIcon fontSize="small" />,
  },
  {
    title: "Absent Employee",
    value: 15,
    change: "8%",
    day: "Yesterday",
    color: "red",
    icon: <ReportOutlinedIcon fontSize="small" />,
  },
  {
    title: "Late arrivals",
    value: 5,
    change: "10%",
    day: "Yesterday",
    color: "green",
    icon: <AlarmOnIcon fontSize="small" />,
  },
  {
    title: "On leave",
    value: 5,
    change: "10%",
    day: "Yesterday",
    color: "green",
    icon: <BeachAccessOutlinedIcon fontSize="small" />,
  },
];

const statusStyles = {
  approved: {
    color: "green",
    Icon: FiberManualRecordRoundedIcon,
  },
  pending: {
    color: "orange",
    Icon: FiberManualRecordRoundedIcon,
  },
  rejected: {
    color: "red",
    Icon: FiberManualRecordRoundedIcon,
  },
};

const HrEmployeeAttendanceDatagrid = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [statusDropdown, setStatusDropdown] = useState(null);
  const [attendenceData, setAttendenceData] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const navigate = useNavigate();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const tempData = [];
  const {
    // weekData,
    // weekDetails,
    weekStartDate,
    weekEndDate,
    calendarAnchor,
    // setWeekStartDate,
    // setWeekEndDate,
    handleDateChange,
    handleCalendarIconClick,
    handleCalendarClose,
  } = useWeekDetails(tempData);
  const open = Boolean(calendarAnchor);

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const metaData = useSelector((state) => state?.metaData);
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const monthYear = `${month}-${year}`;

  const { attendanceDayStatus } = metaData || {};
  const attendanceDayStatusOptionsList = dropDownOptions(attendanceDayStatus);

  const dispatch = useDispatch();
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = attendenceData;
  const SearchResult = searchQuery || statusDropdown;

  const defaultValue = {};
  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });
  const { control, formState, reset, register } = useFunction;
  const { errors } = formState;

  const isHR = usePermissionCustomHook("HR");

  const handleDownloadChange = async (option) => {
    try {
      setLoading(true);
      if (option.value === "excel") {
        const attendanceDownload = await getAttendanceDowndload(
          loginUserId,
          monthYear
        );
        const blob = new Blob([attendanceDownload]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Attendance_${monthYear}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error.response?.data?.message ||
          "An error occurred while fetching attendance data."
      );
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setIsAllSelected(isChecked);
    setAttendenceData((prev) => ({
      ...prev,
      rows: prev.rows.map((row) => ({
        ...row,
        isSelected: isChecked,
      })),
    }));
    setIsAllSelected(false);
  };

  const handleRowCheckboxChange = (employeeId, isChecked) => {
    setAttendenceData((prev) => ({
      ...prev,
      rows: prev.rows.map((row) =>
        row.employeeId === employeeId ? { ...row, isSelected: isChecked } : row
      ),
    }));
  };

  const clearSelection = () => {
    reset(defaultValue);
    setStatusDropdown("");
    setSearchQuery("");
  };

  const groupRowsByEmployeeName = (data) => {
    const grouped = [];
    const employeeGroups = {};

    data.forEach((row) => {
      const { employeeName, employeeId } = row;

      if (!employeeGroups[employeeId]) {
        employeeGroups[employeeId] = {
          rowSpan: 0,
          isFirstInGroup: true,
        };
      }

      employeeGroups[employeeId].rowSpan += 1;
      grouped.push({
        ...row,
        isFirstInGroup: employeeGroups[employeeId].rowSpan === 1,
        rowSpan: employeeGroups[employeeId].rowSpan,
      });
    });

    return grouped;
  };

  const fetchAttendenceData = async () => {
    try {
      setLoading(true);

      const payload = {
        user_id: loginUserId,
        page: page,
        limit: pageSize,
        ...(statusDropdown && { status: statusDropdown }),
        ...(searchQuery && { search: searchQuery }),
      };
      const AttendanceRequests = await getAttendanceRequests(payload);
      const formattedData = AttendanceRequests?.data?.attendance_requests;

      if (!formattedData || formattedData.length === 0) {
        setAttendenceData({ ...attendenceData, isLoading: true });
        setLoading(true);
      } else {
        const MyAssignmentData = HrViewAttendanceDatagridUtils(formattedData);
        const groupedData = groupRowsByEmployeeName(MyAssignmentData);
        setAttendenceData((prev) => ({
          ...prev,
          rows: groupedData,
          isLoading: false,
          totalItems: AttendanceRequests?.pagination?.totalItems,
        }));
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setAttendenceData({ ...attendenceData, isLoading: false });
        setLoading(false);
        setOpenNotification(true);
        setNotificationMessage(
          error.response?.data?.message ||
            "An error occurred while fetching attendance-data."
        );
      }

      setAttendenceData({ ...attendenceData, rows: [] });
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  useDebounceEffect(
    () => {
      fetchAttendenceData();
      setIsFilterSelected(SearchResult);
    },
    500,
    [page, pageSize, searchQuery, statusDropdown]
  );

  const handleViewLeave = (params) => {
    const {
      checkInTime,
      checkOutTime,
      workingHours,
      date,
      remarks,
      dayStatus,
      reason,
      status,
      attendanceId,
      comment,
      attendanceRequestId,
    } = params.row;
    const data = {
      checkInTime,
      checkOutTime,
      workingHours,
      remarks,
      reason,
      dayStatus,
      date,
      status,
      attendanceId,
      comment,
      attendanceRequestId,
    };

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedAttendance(null);
    setIsDialogOpen(false);
  };

  const handleRedirectAttendance = (employeeId) => {
    navigate(`/attendance/employee-checkin/${employeeId}`);
    dispatch(setEmployeeAttendanceId(employeeId));
  };

  const handleGoBack = () => {
    navigate(`/dashboard`);
    dispatch(setNavigationRoute("/dashboard"));
  };

  const sorting = {
    sortModel: [{ field: "employeeName", sort: "asc" }],
  };

  const columnsData = [
    {
      field: "employeeName",
      headerName: "Name",
      width: 250,
      renderHeader: (params) => {
        return (
          <CheckboxCloumn>
            <FormControlLabel
              control={
                <CheckboxContainer
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  indeterminate={
                    attendenceData.rows.some((row) => row.isSelected) &&
                    !isAllSelected
                  }
                />
              }
              label="Employee Name"
            />
          </CheckboxCloumn>
        );
      },
      renderCell: (params) => {
        const { employeeName, isFirstInGroup, employeeId, isSelected } =
          params.row;
        return isFirstInGroup ? (
          <CheckboxRow>
            <FormControlLabel
              value={employeeName}
              control={
                <CheckboxContainer
                  checked={isSelected || false}
                  onChange={(event) =>
                    handleRowCheckboxChange(employeeId, event.target.checked)
                  }
                />
              }
            />
            <CheckboxLabel>
              <ProfileContainer
                src="https://images.unsplash.com/photo-1551782450-a2132b4ba21d"
                alt={employeeName}
              />
              <Grid>
                <Typography variant="body1">{employeeName}</Typography>
                <EmpTypography
                  variant="body2"
                  color="textSecondary"
                  onClick={() => handleRedirectAttendance(employeeId)}
                >
                  {employeeName}
                </EmpTypography>
              </Grid>
            </CheckboxLabel>
          </CheckboxRow>
        ) : null;
      },
    },
    {
      field: "employeeId",
      headerName: "Employee Id",
      width: 150,
    },
    {
      field: "date",
      headerName: "Date",
      width: 170,
    },
    { field: "checkIn", headerName: "Check In", width: 170 },
    { field: "checkOut", headerName: "Check Out", width: 170 },
    { field: "workingHours", headerName: "Hours Worked", width: 170 },
    { field: "type", headerName: "Type", width: 170 },
    { field: "reason", headerName: "Reason", width: 200 },
    // { field: "remarks", headerName: "Remark", width: 120 },
    // { field: "comment", headerName: "Comment", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => {
        const status = params.row.status;
        const statusText = status ? status.toLowerCase() : "";
        const { color, Icon } =
          statusStyles[statusText] || statusStyles.default;

        return (
          <StatusContainer alignItems={"center"}>
            <StyledIcon color={color}>
              <Icon
                size="small"
                style={{ fontSize: "12px", marginTop: "6px" }}
              />
            </StyledIcon>
            <StyledText color={color}>{status}</StyledText>
          </StatusContainer>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        return (
          <Button variant="text" onClick={() => handleViewLeave(params)}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <LogContainer>
        <StyledGrid className="dark-scrollbar">
          <BackButtonGrid>
            <RequestTypo>Employee Attendance</RequestTypo>
            <BackButtonStyle variant="h6" onClick={handleGoBack}>
              Back to Dashboard
            </BackButtonStyle>
          </BackButtonGrid>
          <CardContainer container spacing={2}>
            {cardData.map((card, index) => (
              <StyledCard key={index} item xs={12} sm={2}>
                <ContentIconContainer>
                  <IconContainer color={card.color}>{card.icon}</IconContainer>
                  <ChangeText>
                    <ChangeText component="span" color={card.color}>
                      {card.change}
                    </ChangeText>{" "}
                    vs {card.day}
                  </ChangeText>
                </ContentIconContainer>
                <ContentContainer>
                  <CardTitle variant="h6">{card.title}</CardTitle>
                  <CardText variant="h6">{card.value}</CardText>
                </ContentContainer>
              </StyledCard>
            ))}
          </CardContainer>
          <LogAttendenceContainer container spacing={2}>
            <Grid item xs={12} sm={4} md={4} order={{ xs: 2, sm: 1 }}>
              <SearchContainer xs={7.5} sm={7} md={7}>
                <FormInputSearch
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  placeholder="Search"
                  fullWidth
                />
              </SearchContainer>
              <Grid xs={6} sm={5} md={5}>
                {isFilterSelected && (
                  <ClearFilterButton clearSelection={clearSelection} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={8} order={{ xs: 1, sm: 2 }}>
              <FilterAttendenceContainer container>
                <FilterAttendence item xs={7} sm={8} md={9}>
                  <Grid xs={12} sm={7} md={4}>
                    <FormDropDown
                      label="Status"
                      name="status"
                      control={control}
                      errors={errors?.department}
                      helperText={errors?.department?.message}
                      options={attendanceDayStatusOptionsList}
                      register={register("status", {
                        onChange: (e) => {
                          setStatusDropdown(e.target.value);
                        },
                      })}
                    />
                  </Grid>
                </FilterAttendence>
                <Grid item xs={3} sm={1.5} md={1}>
                  <EmployeeDownloadIconGrid
                    // isRight={true}
                    isFilterSelected={true}
                    isTop={true}
                  >
                    <DownloadAttendence>
                      <DownloadDropdown
                        options={downloadOptions}
                        handleChange={handleDownloadChange}
                        isTop={true}
                      />
                    </DownloadAttendence>
                  </EmployeeDownloadIconGrid>
                </Grid>
                <Grid item xs={2} sm={1.5} md={1}>
                  <CalendarMonth
                    size="small"
                    onClick={handleCalendarIconClick}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    <CalendarMonthIconContainer />
                  </CalendarMonth>
                </Grid>
              </FilterAttendenceContainer>
            </Grid>
          </LogAttendenceContainer>
          <DataGridComponent
            autoHeight
            columnsData={columnsData}
            rowData={attendenceData.rows}
            rowCount={totalItems}
            isLoading={isLoading}
            page={page - 1}
            editable={true}
            handlePaginationChange={handlePaginationChange}
            sorting={sorting}
          />

          {isDialogOpen && selectedAttendance && (
            <LogAttendanceDailog
              attendanceData={selectedAttendance}
              onClose={handleDialogClose}
              onOpen={isDialogOpen}
              isEdit={true}
              isHR={isHR}
              fetchAttendenceData={fetchAttendenceData}
            />
          )}
        </StyledGrid>
      </LogContainer>
      <Popover
        open={open}
        anchorEl={calendarAnchor}
        onClose={handleCalendarClose}
      >
        <DayCalendar onDateSelect={handleDateChange} />
      </Popover>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default HrEmployeeAttendanceDatagrid;
