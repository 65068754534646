import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const Headingtext = styled(Typography)`
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 400;
`;

export const HeadGrid = styled(Grid)`
  display: flex;
  align-items: center;
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
`;

export const TextGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const ButtonGrid = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};

  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.xs}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
  }
`;

export const LeaveText = styled(Typography)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2.4}px`};
  font-weight: 400;
  font-size: 15px;
`;

export const SwitchGrid = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
