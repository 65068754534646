import React from "react";
import { useSideNav } from "../../../../Common/CommonUtils";
import HrAdminManagerPerformance from "./HrAdminManagerPerformance";
import HrManagerPerformanceTab from "./HrManagerPerformanceTab";
import { useSelector } from "react-redux";

const HrManagerPerformance = () => {
  useSideNav();

  const designation = useSelector((state) => state.user?.data?.designation);
  const role = useSelector((state) => state.user?.data?.role);

  return (
    <>
      {designation === "HR Admin" && role === "HR" ? (
        <HrAdminManagerPerformance />
      ) : (
        <HrManagerPerformanceTab />
      )}
    </>
  );
};

export default HrManagerPerformance;
