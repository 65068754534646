import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import {
  BarContainer,
  BoxTextContainer,
  ProgressBarContainer,
  TextContainer,
} from "./Common.styled";

const ProgressBar = (props) => {
  const { value, LinearProgressComponent = LinearProgress } = props;
  return (
    <ProgressBarContainer>
      <BarContainer>
        <LinearProgressComponent variant="determinate" {...props} />
      </BarContainer>
      <BoxTextContainer>
        <TextContainer variant="body2">{`${Math.round(value)}%`}</TextContainer>
      </BoxTextContainer>
    </ProgressBarContainer>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  LinearProgressComponent: PropTypes.elementType.isRequired,
};

export default ProgressBar;
