import React, { useState } from "react";
import EmployeePortalDialog from "../../Common/EmployeePortalDialog";
import CreateEmployeeForm from "./CreateEmployeeForm";
import { postEmployeeApi } from "./Services/service";
import {
  useNotification,
} from "../../Common/CommonUtils";
import { format } from "date-fns";
import { dateFnsFormat } from "../../Common/Constant";
import { useSelector } from "react-redux";

const CreateEmployee = (props) => {
  const { onClose, onOpen, fetchEmployeeData } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
    const [approveLoading, setApproveLoading] = useState(false);


  const employeeID = useSelector((state) => state.user?.data?.employeeId);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleComponentSubmit = async (data) => {
    const {
      branchOffice,
      date,
      email,
      // employeeId,
      firstName,
      lastName,
      role,
      designation,
      employmentType,
    } = data;

    const payload = {
      branchOffice: branchOffice,
      role: role,
      designation: designation,
      dateOfBirth: format(new Date(date), dateFnsFormat),
      officialEmailId: email,
      userId: employeeID,
      firstName: firstName,
      lastName: lastName,
      employmentType: employmentType,
    };

    try {
      setApproveLoading(true);
      await postEmployeeApi(payload);
      fetchEmployeeData();
      setSnackbarOpen(true);
      setOpenNotification(true);
      setNotificationMessage("Successfully Created Employee");
      setApproveLoading(false);
      onClose();
    } catch (error) {
      setApproveLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail || error?.response?.data?.ErrorMessage);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={"Add Employee"}
        customWidth={"520px"}
      >
        <CreateEmployeeForm
          onSubmit={handleComponentSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          loading={approveLoading}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
      <NotificationPopup />
    </>
  );
};

export default CreateEmployee;
