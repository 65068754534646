import { api } from "../../../Common/AxoisConfig";

export const getOverview = async (params) => {
  const overviewData = await api("NODE_API_URL").get(`performance/all?`, {
    params: params,
  });
  return overviewData?.data;
};

export const getMyPerformanceOverview = async (userId, performanceId) => {
  const MyPerformanceOverview = await api("NODE_API_URL").get(
    `performance?userId=${userId}&performanceId=${performanceId}`
  );
  return MyPerformanceOverview?.data;
};

export const putMyPerformanceOverview = async (
  userId,
  performanceId,
  payload
) => {
  const MyPerformanceOverview = await api("NODE_API_URL").put(
    `performance?performanceId=${performanceId}&userId=${userId}`,
    payload
  );
  return MyPerformanceOverview?.data;
};

export const getGrowthData = async (employeeId, userId) => {
  const growthData = await api("NODE_API_URL").get(
    `employee/history?employeeId=${employeeId}&userId=${userId}`
  );
  return growthData?.data;
};

export const getTeamPerformance = async (params) => {
  const teamsData = await api("NODE_API_URL").get(`performance/all?`, {
    params: params,
  });
  return teamsData?.data;
};
