import React, { useState } from "react";
import { LogContainer } from "./LogAttendance.styled";
import DataGridComponent from "../../../Common/DataGridComponent";
import LogAttendence from "./LogAttendance";
import LogAttendanceDailog from "./LogAttendanceDailog";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { isSameMonth } from "date-fns";
import {
  useLoader,
  useNotification,
  usePermissionCustomHook,
} from "../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  StatusAbsent,
  StatusPresent,
  StatusContainer,
  StyledText,
} from "./LogAttendance.styled";
import { getAttendanceSummary } from "../services/Services";
import { formatAttendanceData } from "./LogAttendanceDatagridUtils";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";
import {
  setPage,
  setPageSize,
} from "../../../Common/store/paginationSlice/paginationSlice";
import useDebounceEffect from "../../../Common/CustomHooks/useDebounceEffect";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { DataMainGrid } from "../../Employees/Employee.Styled";
import { StyledButton } from "../../../Common/Ui/styled";

const LogAttendanceDataGrid = () => {
  const isHR = usePermissionCustomHook("HR");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [filteredData, setFilteredData] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [newData, setNewData] = useState([]);
  const { AlertSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pagination = useSelector((state) => state?.pagination);
  const employeeAttendanceId = useSelector(
    (state) => state.employeeAttendanceId.employeeAttendanceId
  );
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = filteredData;

  const statusStyles = {
    approved: {
      color: "green",
    },
    pending: {
      color: "orange",
    },
    rejected: {
      color: "red",
    },
    default: {
      color: "",
    },
  };
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const getCurrentMonthYear = () => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${month}-${year}`;
  };

  const monthYear = getCurrentMonthYear();

  const fetchEmployeeAttendanceData = async () => {
    try {
      setLoading(true);
      const payload = {
        user_id: loginUserId,
        ...(isHR && { employeeId: employeeAttendanceId }),
        month_year: monthYear,
        page: page,
        limit: pageSize,
      };

      const attendanceSummary = await getAttendanceSummary(payload);
      if (
        !attendanceSummary?.data?.attendanceHistory ||
        attendanceSummary?.data?.attendanceHistory.length === 0
      ) {
        setNewData([]);
        setFilteredData({ ...filteredData, isLoading: true });
        setLoading(true);
      } else {
        const formattedData = formatAttendanceData(
          attendanceSummary?.data?.attendanceHistory
        );
        setLoading(false);
        setNewData(formattedData);
        setFilteredData((prev) => ({
          ...prev,
          rows: formattedData,
          isLoading: false,
          totalItems: attendanceSummary?.pagination?.totalItems,
        }));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setLoading(false);
        setOpenNotification(true);
        setFilteredData({ ...filteredData, isLoading: false });
        setNotificationMessage(
          error.response?.data?.message ||
            "An error occurred while fetching attendance-data."
        );
      }

      setNewData([]);
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  useDebounceEffect(
    () => {
      fetchEmployeeAttendanceData();
    },
    500,
    [monthYear, page, pageSize]
  );

  const capitalizeFirstLetter = (status) =>
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const monthMap = (() => {
    const today = new Date();
    return Array.from({ length: 3 }, (_, i) => {
      const monthDate = new Date(today.getFullYear(), today.getMonth() - i);
      return {
        name: monthDate.toLocaleString("default", { month: "long" }),
        date: monthDate,
      };
    });
  })();

  const filterAttendanceData = (data, month) => {
    const selectedMonthDate = new Date(month);
    return data.filter((row) => {
      const attendanceDate = new Date(row.date);
      return isSameMonth(attendanceDate, selectedMonthDate);
    });
  };

  const handleSelectMonth = (month) => {
    const selectedMonth = monthMap.find((m) => m.name === month)?.date;
    if (selectedMonth) {
      setCurrentMonth(selectedMonth);
      const filtered = filterAttendanceData(newData, selectedMonth);
      setFilteredData((prev) => ({
        ...prev,
        rows: filtered,
      }));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleEditAttendance = (params) => {
    const {
      checkInTime,
      checkOutTime,
      workingHours,
      date,
      remarks,
      dayStatus,
      reason,
      status,
      attendanceId,
    } = params.row;
    const data = {
      checkInTime,
      checkOutTime,
      workingHours,
      remarks,
      reason,
      dayStatus,
      date,
      status,
      attendanceId,
    };

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };
  const handleApplyAttendance = (params) => {
    const data = params.row;

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setSelectedAttendance(null);
    setIsDialogOpen(false);
  };
  const columnsData = [
    { field: "date", headerName: "Date", width: 150 },
    {
      field: " dayStatus",
      headerName: "Day Status",
      width: 170,
      renderCell: (params) => {
        const { dayStatus } = params.row;
        const formattedDayStatus = capitalizeFirstLetter(dayStatus);
        return formattedDayStatus === "Present" ? (
          <StatusPresent>{formattedDayStatus}</StatusPresent>
        ) : (
          <StatusAbsent>{formattedDayStatus}</StatusAbsent>
        );
      },
    },
    { field: "checkIn", headerName: "Check In", width: 200 },
    { field: "checkOut", headerName: "Check Out", width: 200 },
    { field: "workingHours", headerName: "Hours Worked", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        const status = params.value ? params.value.toLowerCase() : null;
        const { color } = statusStyles[status] || statusStyles.default;

        return (
          <StatusContainer>
            <StyledText color={color}>{params.value || ""}</StyledText>
          </StatusContainer>
        );
      },
    },
    { field: "reason", headerName: "Reason", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      align: "center",
      getActions: (params) => {
        const { dayStatus, status } = params.row;

        const isEditDisabled = !(
          (dayStatus === "Present" || status === "Rejected") &&
          status !== "Pending" &&
          status !== "Approved"
        );

        const isApplyAttendanceDisabled = !(
          dayStatus === "Absent" &&
          status !== "Pending" &&
          status !== "Approved" &&
          status !== "Rejected"
        );
        return [
          <GridActionsCellItem
            label="Edit"
            onClick={() => handleEditAttendance(params)}
            showInMenu
            disabled={isEditDisabled}
          />,
          <GridActionsCellItem
            label="Apply Attendance"
            onClick={() => handleApplyAttendance(params)}
            showInMenu
            disabled={isApplyAttendanceDisabled}
          />,
        ];
      },
    },
  ];

  return (
    <>
      {isHR && (
        <DataMainGrid>
          <StyledButton variant="outlined" onClick={handleBack}>
            <KeyboardArrowLeftIcon />
            Back
          </StyledButton>
        </DataMainGrid>
      )}
      <LogContainer>
        <LogAttendence
          currentMonth={currentMonth}
          onSelectMonth={handleSelectMonth}
        />
        <DataGridComponent
          autoHeight
          columnsData={columnsData}
          rowData={filteredData.rows}
          rowCount={totalItems}
          isLoading={isLoading}
          page={page - 1}
          editable={true}
          handlePaginationChange={handlePaginationChange}
        />

        {isDialogOpen && selectedAttendance && (
          <LogAttendanceDailog
            attendanceData={selectedAttendance}
            onClose={handleDialogClose}
            onOpen={isDialogOpen}
            fetchEmployeeAttendanceData={fetchEmployeeAttendanceData}
          />
        )}
      </LogContainer>

      <NotificationPopup />
      <LoaderPopup />
      <AlertSnackbar />
    </>
  );
};

export default LogAttendanceDataGrid;
