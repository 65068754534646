import { useState } from "react";
import {
  LogContainer,
  StatusContainer,
  StyledIcon,
  StyledText,
  RequestTypo,
  LogAttendenceContainer,
  DownloadAttendence,
} from "../LogAttendance.styled";
import DataGridComponent from "../../../../Common/DataGridComponent";
import LogAttendanceDailog from "../LogAttendanceDailog";
import {
  useLoader,
  useNotification,
  usePermissionCustomHook,
} from "../../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttendanceDowndload,
  getAttendanceRequests,
} from "../../services/Services";
import { HrViewAttendanceDatagridUtils } from "./HrViewAttendanceDatagridUtils";
import { Button } from "@mui/material";
import useDebounceEffect from "../../../../Common/CustomHooks/useDebounceEffect";
import {
  setPage,
  setPageSize,
} from "../../../../Common/store/paginationSlice/paginationSlice";
import DownloadDropdown from "../../../../Common/DownloadDropdown";
import { downloadOptions } from "../../../../Common/Constant";
import { EmployeeDownloadIconGrid } from "../../../Employees/Employee.Styled";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

const HrViewAttendanceDatagrid = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [attendenceData, setAttendenceData] = useState({
    isLoading: false,
    rows: [],
    totalItems: null,
  });

  const dispatch = useDispatch();
  const pagination = useSelector((state) => state?.pagination);
  const { page, pageSize } = pagination;
  const { isLoading, totalItems } = attendenceData;

  const isHR = usePermissionCustomHook("HR");

  const statusStyles = {
    approved: {
      color: "green",
      Icon: FiberManualRecordRoundedIcon,
    },
    pending: {
      color: "orange",
      Icon: FiberManualRecordRoundedIcon,
    },
    rejected: {
      color: "red",
      Icon: FiberManualRecordRoundedIcon,
    },
  };

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const monthYear = `${month}-${year}`;

  const handleDownloadChange = async (option) => {
    try {
      setLoading(true);
      if (option.value === "excel") {
        const attendanceDownload = await getAttendanceDowndload(
          loginUserId,
          monthYear
        );
        const blob = new Blob([attendanceDownload]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Attendance_${monthYear}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(
        error.response?.data?.message ||
          "An error occurred while fetching attendance data."
      );
    }
  };

  const fetchAttendenceData = async () => {
    try {
      setLoading(true);

      const payload = {
        user_id: loginUserId,
        page: page,
        limit: pageSize,
      };
      const AttendanceRequests = await getAttendanceRequests(payload);
      const formattedData = AttendanceRequests?.data?.attendance_requests;

      if (!formattedData || formattedData.length === 0) {
        setAttendenceData({ ...attendenceData, isLoading: true });
        setLoading(true);
      } else {
        const result = HrViewAttendanceDatagridUtils(formattedData);
        setAttendenceData((prev) => ({
          ...prev,
          rows: result,
          isLoading: false,
          totalItems: AttendanceRequests?.pagination?.totalItems,
        }));
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setAttendenceData({ ...attendenceData, isLoading: false });
        setLoading(false);
        setOpenNotification(true);
        setNotificationMessage(
          error.response?.data?.message ||
            "An error occurred while fetching attendance-data."
        );
      }

      setAttendenceData({ ...attendenceData, rows: [] });
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPage(page + 1));
    dispatch(setPageSize(pageSize));
  };

  useDebounceEffect(
    () => {
      fetchAttendenceData();
    },
    500,
    [page, pageSize]
  );

  const handleViewLeave = (params) => {
    const {
      checkInTime,
      checkOutTime,
      workingHours,
      date,
      remarks,
      dayStatus,
      reason,
      status,
      attendanceId,
      comment,
      attendanceRequestId,
    } = params.row;
    const data = {
      checkInTime,
      checkOutTime,
      workingHours,
      remarks,
      reason,
      dayStatus,
      date,
      status,
      attendanceId,
      comment,
      attendanceRequestId,
    };

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedAttendance(null);
    setIsDialogOpen(false);
  };

  const columnsData = [
    {
      field: "employeeName",
      headerName: "Name",
      width: 200,
    },
    {
      field: "date",
      headerName: "Request Date",
      width: 170,
    },
    { field: "checkIn", headerName: "Check In", width: 170 },
    { field: "checkOut", headerName: "Check Out", width: 170 },
    { field: "workingHours", headerName: "Hours Worked", width: 170 },
    { field: "reason", headerName: "Reason", width: 200 },
    // { field: "remarks", headerName: "Remark", width: 120 },
    // { field: "comment", headerName: "Comment", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      renderCell: (params) => {
        const status = params.row.status;
        const statusText = status ? status.toLowerCase() : "";
        const { color, Icon } =
          statusStyles[statusText] || statusStyles.default;

        return (
          <StatusContainer alignItems={"center"}>
            <StyledIcon color={color}>
              <Icon
                size="small"
                style={{ fontSize: "12px", marginTop: "6px" }}
              />
            </StyledIcon>
            <StyledText color={color}>{status}</StyledText>
          </StatusContainer>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        return (
          <Button variant="text" onClick={() => handleViewLeave(params)}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <LogContainer>
        <LogAttendenceContainer>
          <RequestTypo>Request Attendance</RequestTypo>
          <EmployeeDownloadIconGrid
            isRight={true}
            isFilterSelected={true}
            isTop={true}
            order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
          >
            <DownloadAttendence>
              <DownloadDropdown
                options={downloadOptions}
                handleChange={handleDownloadChange}
                isTop={true}
              />
            </DownloadAttendence>
          </EmployeeDownloadIconGrid>
        </LogAttendenceContainer>
        <DataGridComponent
          autoHeight
          columnsData={columnsData}
          rowData={attendenceData.rows}
          rowCount={totalItems}
          isLoading={isLoading}
          page={page - 1}
          editable={true}
          handlePaginationChange={handlePaginationChange}
        />

        {isDialogOpen && selectedAttendance && (
          <LogAttendanceDailog
            attendanceData={selectedAttendance}
            onClose={handleDialogClose}
            onOpen={isDialogOpen}
            isEdit={true}
            isHR={isHR}
            fetchAttendenceData={fetchAttendenceData}
          />
        )}
      </LogContainer>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default HrViewAttendanceDatagrid;
