import { api } from "../../../Common/AxoisConfig";

export const postGetMyAssignment = async (payload) => {
  const MyAssignment = await api("NODE_API_URL").post(
    "projectAssignment/allProject",
    payload
  );
  return MyAssignment?.data;
};

export const getEmployeeResourceUtiliztion = async (userId, employeeId) => {
  const employeeResourceUtiliztion = await api("NODE_API_URL").get(
    `projectAssignment?userId=${userId}&employeeId=${employeeId}`
  );
  return employeeResourceUtiliztion?.data;
};

export const getEditResourceUtiliztion = async (
  userId,
  projectAssignmentId
) => {
  const resourceUtiliztion = await api("NODE_API_URL").get(
    `projectAssignment/id?userId=${userId}&projectAssignmentId=${projectAssignmentId}`
  );
  return resourceUtiliztion?.data?.data;
};

export const getAssignedProjects = async (payload) => {
  const assignedProjects = await api("NODE_API_URL").get(`projectAssignment`, {
    params: payload,
  });
  return assignedProjects?.data;
};

export const postProjectAllocation = async (payload) => {
  const projectAllocation = await api("NODE_API_URL").post(
    `projectAssignment`,
    payload
  );
  return projectAllocation?.data;
};

export const putProjectAllocation = async (payload) => {
  const projectAllocation = await api("NODE_API_URL").put(
    `projectAssignment`,
    payload
  );
  return projectAllocation?.data;
};

export const getProjectHistory = async (userId, employeeId) => {
  const projectHistory = await api("NODE_API_URL").get(
    `projectAssignment/history?userId=${userId}&employeeId=${employeeId}`
  );
  return projectHistory?.data;
};

export const PostProjectAllocation = async (payload) => {
  await api("NODE_API_URL").post(`projectAssignment`, payload);
};

export const putSubmitMyAssignment = async (userId) => {
  const submitMyAssignment = await api("NODE_API_URL").put(
    `projectAssignment/submit?userId=${userId}`
  );
  return submitMyAssignment?.data?.message;
};

export const putInActiveMyAssignment = async (userId, projectAssignmentId) => {
  const inActiveMyAssignment = await api("NODE_API_URL").put(
    `projectAssignment/inactive?userId=${userId}&projectAssignmentId=${projectAssignmentId}`
  );
  return inActiveMyAssignment?.data?.message;
};

export const postMyAssignmentDownload = async (payload) => {
  const inActiveMyAssignment = await api("NODE_API_URL").post(
    `projectAssignment/download`,
    payload,
    { responseType: "blob" }
  );
  return inActiveMyAssignment?.data;
};
