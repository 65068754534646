export const _setBranches = (state, action) => {
  state.branches = action.payload;
};

export const _setRoles = (state, action) => {
  state.roles = action.payload;
};

export const _setManagers = (state, action) => {
  state.managers = action.payload;
};

export const _setDepartments = (state, action) => {
  state.departments = action.payload;
};

export const _setCoreTechnologies = (state, action) => {
  state.coreTechnologies = action.payload;
};

export const _setDesignations = (state, action) => {
  state.designations = action.payload;
};

export const _setFrameWorks = (state, action) => {
  state.frameWorks = action.payload;
};

export const _setProjects = (state, action) => {
  state.projects = action.payload;
};

export const _setLeaveType = (state, action) => {
  state.leaveType = action.payload;
};

export const _setCategory = (state, action) => {
  state.category = action.payload;
};

export const _setSkillLevel = (state, action) => {
  state.skillLevel = action.payload;
};

export const _setTechnology = (state, action) => {
  state.technology = action.payload;
};

export const _setManagerEmployees = (state, action) => {
  state.managerEmployees = action.payload;
};

export const _setEmploymentType = (state, action) => {
  state.employmentType = action.payload;
};

export const _setUtilization = (state, action) => {
  state.utilization = action.payload;
};

export const _setProjectList = (state, action) => {
  state.projectList = action.payload;
};

export const _setUtilizationPercentage = (state, action) => {
  state.utilizationPercentage = action.payload;
};

export const _setBillableType = (state, action) => {
  state.billableType = action.payload;
};

export const _setQuarterMonthList = (state, action) => {
  state.quarterMonthList = action.payload;
};

export const _setTeamQuarterList = (state, action) => {
  state.teamQuarterList = action.payload;
};

export const _setTechnologyByCategory = (state, action) => {
  state.technologyByCategory = action.payload;
};

export const _setLeaveStructure = (state, action) => {
  state.leaveStructure = action.payload;
};

export const _setAttendanceDayStatus = (state, action) => {
  state.attendanceDayStatus = action.payload;
};