import React, { useState } from "react";
import ApplyLeaveForm from "./ApplyLeaveForm";
import EmployeePortalDialog from "../../../Common/EmployeePortalDialog";
import { usePermissionCustomHook } from "../../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import { setIsMyLeave } from "../store/applyLeavesSlice/applyLeaveSlice";

const ApplyLeave = (props) => {
  const {
    fetchLeavesData,
    onClose,
    onOpen,
    teamsLeaves,
    setTeamsLeaves = () => false,
    teamsLeavesApply,
    popUpActions,
  } = props;
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isHR = usePermissionCustomHook("HR");

  const isNewApplyLeaveForm = useSelector(
    (state) => state?.applyLeaveForm?.isNewApplyLeaveForm
  );

  const handleCloseDialog = () => {
    onClose();
    dispatch(setIsMyLeave(false));
    setTeamsLeaves(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen || isNewApplyLeaveForm}
        onClose={onClose}
        title={
          isNewApplyLeaveForm
            ? "Apply Leave"
            : `${popUpActions ? popUpActions : isHR ? "" : "Team"} Leave`
        }
        customWidth={"520px"}
      >
        <ApplyLeaveForm
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
          teamsLeaves={teamsLeaves}
          teamsLeavesApply={teamsLeavesApply}
          fetchLeavesData={fetchLeavesData}
          popUpActions={popUpActions}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default ApplyLeave;
