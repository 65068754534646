import { createSlice } from "@reduxjs/toolkit";
import initialState from "./PerformanceInitialState";
import {
  _setMyPerformance,
  _setPerformanceId,
  _setIsTeamPerformance,
} from "./PerformanceAction";

export const performanceSlice = createSlice({
  name: "ep/MyPerformance",
  initialState,
  reducers: {
    setPerformanceId: _setPerformanceId,
    setMyPerformance: _setMyPerformance,
    setIsTeamPerformance: _setIsTeamPerformance,
    resetEmployeeId: () => initialState,
  },
});
export const {
  setPerformanceId,
  setMyPerformance,
  resetEmployeeId,
  setIsTeamPerformance,
} = performanceSlice.actions;
export default performanceSlice.reducer;
