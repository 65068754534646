import React, { useState, useEffect } from "react";
import { IconButton, Grid, Popover, MenuItem, Box } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Bar } from "react-chartjs-2";
import { DayCalendar, MonthCalendar } from "../../Common/CalenderMonthWeek";
import { MuiCard } from "../../Common/Common.styled";
import { getAttendanceSummary } from "./services/Services";
import { useLoader, useNotification } from "../.././Common/CommonUtils";
import { useSelector } from "react-redux";
import {
  AttendanceTypo,
  AttendanceTypo2,
  BarContainor,
  BarHeader,
  TotalHourBox,
  StyledSelect,
  RightGrid,
} from "./AttendanceBarChart.styled";
import { useForm } from "react-hook-form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AttendanceBarChart = () => {
  const [timeframe, setTimeframe] = useState("Week");
  const [calendarAnchor, setCalendarAnchor] = useState(null);
  const [tempData, setTempData] = useState([]);
  const [monthYear, setMonthYear] = useState(null);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [monthsWeek, setMonthsWeek] = useState([]);
  const [weekDetails, setWeekDetails] = useState([]);
  const [monthDetails, setMonthDetails] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      { label: "", data: [], backgroundColor: "#3FC9B0", barThickness: 10 },
    ],
  });
  const { control } = useForm({ mode: "onChange" });
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const getCurrentMonthYear = () => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${month}-${year}`;
  };

  const monthWithYear = getCurrentMonthYear();

  const fetchedAttendanceData = async () => {
    try {
      setLoading(true);
      const payload = {
        user_id: loginUserId,
        month_year: monthYear === null ? monthWithYear : monthYear,
      };
      const attendanceSummary = await getAttendanceSummary(payload);
      if (
        !attendanceSummary?.data?.attendanceHistory ||
        attendanceSummary?.data?.attendanceHistory.length === 0
      ) {
        setTempData([]);
        setLoading(true);
      } else {
        setLoading(false);
        setTempData(attendanceSummary?.data?.attendanceHistory);
        setMonthData(attendanceSummary?.data?.attendanceWeeklyReport);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setLoading(false);
        setOpenNotification(true);
        const errorMessage = error.response.data.detail;
        setNotificationMessage(errorMessage);
      }

      setTempData([]);
    }
  };

  useEffect(() => {
    fetchedAttendanceData();
  }, [monthWithYear, monthYear]);

  const getWeekDates = (selectedDate) => {
    const weekDates = [];
    const date = new Date(selectedDate);
    const dayOfWeek = date.getDay();
    date.setDate(date.getDate() - ((dayOfWeek + 6) % 7));

    for (let i = 0; i < 7; i++) {
      const current = new Date(date);
      current.setDate(date.getDate() + i);
      const formatted = `${current.getDate()}-${
        current.getMonth() + 1
      }-${current.getFullYear()}`;
      weekDates.push(formatted);
    }
    return weekDates;
  };

  const handleDateChange = (date) => {
    if (timeframe === "Week") {
      const weekDates = getWeekDates(date);
      const MMDDYYYYDates = weekDates.map((date) => {
        const [day, month, year] = date.split("-");
        const paddedDay = day.padStart(2, "0");
        const paddedMonth = month.padStart(2, "0");
        return `${paddedMonth}-${paddedDay}-${year}`;
      });

      function parseDate(dateString) {
        const [month, day, year] = dateString.split("-");
        return new Date(`${year}-${month}-${day}`);
      }

      function getWorkingHoursForWeek(start, end, tempData) {
        const result = [];
        const startDate = parseDate(start);
        const endDate = parseDate(end);

        for (
          let date = new Date(startDate);
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const formattedDate = `${String(date.getMonth() + 1).padStart(
            2,
            "0"
          )}-${String(date.getDate()).padStart(2, "0")}-${date.getFullYear()}`;

          const match = tempData.find((item) => item.date === formattedDate);
          result.push({
            date: formattedDate,
            workingHours:
              match?.workedHours === null
                ? 0
                : match?.workedHours === undefined
                ? 0
                : match?.workedHours,
          });
        }

        return result;
      }

      const workingHoursForWeek = getWorkingHoursForWeek(
        MMDDYYYYDates[0],
        MMDDYYYYDates[MMDDYYYYDates.length - 1],
        tempData
      );
      const weekHours = workingHoursForWeek.map((item) => {
        const workingHours = item.workingHours;

        if (typeof workingHours === "string" && workingHours.includes(":")) {
          const [hours, minutes] = workingHours.split(":").map(Number);
          if (!isNaN(hours) && !isNaN(minutes)) {
            const decimalMinutes = (minutes / 60) * 100;
            return Number((hours + decimalMinutes / 100).toFixed(2));
          }
          return 0;
        }
        return typeof workingHours === "number" ? workingHours : 0;
      });

      setWeekData(weekHours);

      function formatWeekRange(dates) {
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[dates.length - 1]);
        const formatDate = (date) => {
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          const year = date.getFullYear();
          return `${month}-${day}-${year}`;
        };
        const formattedStart = formatDate(startDate);
        const formattedEnd = formatDate(endDate);
        return `${formattedStart} -- ${formattedEnd}`;
      }
      const weekRange = formatWeekRange(MMDDYYYYDates);
      setWeekDetails(weekRange);
    } else if (timeframe === "Month") {
      function formatDate(date) {
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      }
      const formattedDate = formatDate(date);
      const mmYyyyDate = new Date(formattedDate);
      const MMYYYYDate = `${("0" + (mmYyyyDate.getMonth() + 1)).slice(
        -2
      )}-${date.getFullYear()}`;
      setMonthYear(MMYYYYDate);
      if (monthData[0] && monthData[0].week) {
        const weekData = Object.keys(monthData[0].week).map((weekKey) => {
          const { workedHours, startDate, endDate } =
            monthData[0].week[weekKey];
          return {
            week: weekKey,
            startDate,
            endDate,
            workedHours,
          };
        });
        const monthHours = weekData.map((item) => {
          const workedHours = item.workedHours;

          if (workedHours === "00:00" || workedHours === "0:00") {
            return 0;
          }

          if (typeof workedHours === "string" && workedHours.includes(":")) {
            const [hours, minutes] = workedHours.split(":").map(Number);
            if (!isNaN(hours) && !isNaN(minutes)) {
              const decimalMinutes = minutes / 60;
              return Number((hours + decimalMinutes).toFixed(2));
            }
          }
          return 0;
        });

        setMonthsWeek(monthHours);
      }
      function getMonthName(dateStr) {
        const [month, year] = dateStr.split("-");
        const date = new Date(year, month - 1);
        return date.toLocaleString("default", { month: "long" });
      }
      const monthName = getMonthName(MMYYYYDate);
      setMonthDetails(monthName);
    }
  };

  useEffect(() => {
    handleDateChange(new Date());
  }, [timeframe]);

  useEffect(() => {
    const labels =
      timeframe === "Week"
        ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        : ["Week 1", "Week 2", "Week 3", "Week 4"];

    const data = timeframe === "Week" ? weekData : monthsWeek;

    setChartData({
      labels,
      datasets: [
        {
          label: "Hours Worked",
          data,
          backgroundColor: "#3FC9B0",
          barThickness: 10,
        },
      ],
    });
  }, [timeframe, weekData, monthData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: timeframe === "Week" ? 12 : 60,
        grid: {
          display: true,
        },
      },
      x: {
        categoryPercentage: 0.8,
        barPercentage: 0.9,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  const handleCalendarIconClick = (event) => {
    setCalendarAnchor(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchor(null);
  };

  const totalWeekWorkingHours = weekData.reduce(
    (total, hours) => total + hours,
    0
  );

  const totalWorkedHours = monthData[0]?.totalWorkedHours;

  const open = Boolean(calendarAnchor);

  return (
    <MuiCard>
      <BarContainor item xs={12} elevation={3}>
        <BarHeader>
          <AttendanceTypo>
            History:{timeframe === "Week" ? weekDetails : monthDetails}
          </AttendanceTypo>
          <RightGrid container style={{ alignItems: "center" }}>
            <StyledSelect
              value={timeframe}
              onChange={handleTimeframeChange}
              size="small"
              variant="outlined"
            >
              <MenuItem value="Week">Week</MenuItem>
              <MenuItem value="Month">Month</MenuItem>
            </StyledSelect>
            <IconButton size="small" onClick={handleCalendarIconClick}>
              <CalendarMonthIcon />
            </IconButton>
          </RightGrid>
        </BarHeader>
        <Popover
          open={open}
          anchorEl={calendarAnchor}
          onClose={handleCalendarClose}
        >
          {timeframe === "Week" ? (
            <DayCalendar onDateSelect={handleDateChange} />
          ) : (
            <MonthCalendar onDateSelect={handleDateChange} />
          )}
        </Popover>

        <Box>
          {chartData?.datasets[0]?.data?.length > 0 ? (
            <Bar data={chartData} options={options} />
          ) : (
            <Box textAlign="center" padding="20px">
              <AttendanceTypo>No Data Available</AttendanceTypo>
            </Box>
          )}
        </Box>

        <TotalHourBox>
          <AttendanceTypo2>
            Total Hours:
            {timeframe === "Week" ? totalWeekWorkingHours : totalWorkedHours}
          </AttendanceTypo2>
        </TotalHourBox>
      </BarContainor>
      <NotificationPopup />
      <LoaderPopup />
    </MuiCard>
  );
};

export default AttendanceBarChart;
