import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormDropDown from "../../../../Common/FormDropDown";
import { FormTextField } from "../../../../Common/FormTextField";
import {
  dropDownOptions,
  RenderSaveCancelButtons,
  useNotification,
} from "../../../../Common/CommonUtils";
import CommonSwitch from "../../../../Common/CommonSwitch";
import { requiredField } from "../../../../Common/Constant";
import { StyledTextField } from "../MyAssignment.styled";
import {
  CreateEmployeeLeftTextGrid,
  CreateEmployeeRightTextGrid,
} from "../../../Employees/CreateEmployeeForm.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  postProjectAllocation,
  putProjectAllocation,
} from "../../services/MyAssignmentServices";
import {
  resetMyAssignmentForm,
  setIsSubmitMyAssignment,
} from "../../store/MyAssignmentSlice";
import useSnackbar from "../../../../Common/CustomHooks/useSnackbar";

const assignmentSchema = yup.object().shape({
  employeeName: yup.string().required(requiredField),
  department: yup.string().required(requiredField),
  project: yup.string().required(requiredField),
  billableType: yup.string().required(requiredField),
  managerName: yup.string().required(requiredField),
  utilization: yup.number().required(requiredField),
  comments: yup.string().required(requiredField),
});

const MyAssignmentForm = (props) => {
  const { onCancel, selectedValue, fetchMyAssignment, totalUtilization } =
    props;
  const [addLoading, setAddLoading] = useState(false);
  const dispatch = useDispatch();
  const metaData = useSelector((state) => state?.metaData);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { showSnackbar, AlertSnackbar } = useSnackbar();

  const myAssignmentForm = useSelector((state) => state?.myAssignmentForm);
  const isMyAssignmentForm = useSelector(
    (state) => state?.myAssignmentForm?.isMyAssignmentForm
  );

  const isActiveOrNot = myAssignmentForm?.status === "active" ? true : false;
  const [isActive, setIsActive] = useState(isActiveOrNot);
  const empName = `${
    selectedValue.employeeName || myAssignmentForm?.employeeName || " "
  } - ID ${selectedValue.employeeId || myAssignmentForm?.employeeId}`;

  const { departments, billableType, utilizationPercentage, projectList } =
    metaData || [];

  const filteredUtilizationPercentage = utilizationPercentage?.filter(
    (item) => {
      const utilizationValue = parseInt(item.name, 10);
      return totalUtilization + utilizationValue <= 100;
    }
  );

  const mapToProjectOptionsList = (items) =>
    items?.map((item) => ({
      label: item.projectName,
      value: item.projectId,
      managerName: item.managerName,
    })) || [];

  const mapToutilizationOptionsList = (items) =>
    items?.map((item) => ({
      label: `${item.name}%`,
      value: item.name,
    })) || [];

  const departmentsOptionsList = dropDownOptions(departments);
  const billableTypesOptionsList = dropDownOptions(billableType);
  const projectsOptionsList = mapToProjectOptionsList(projectList);
  const utailizationOptionsList = mapToutilizationOptionsList(
    filteredUtilizationPercentage
  );

  const defaultValue = {
    employeeName: empName || " ",
    managerName: myAssignmentForm?.managerName,
    employeeId: selectedValue.employeeId || myAssignmentForm?.employeeId,
    utilization: myAssignmentForm?.utilization,
    billableType: myAssignmentForm?.billableType,
    department: myAssignmentForm?.department,
    project: myAssignmentForm?.projectId,
    comments: myAssignmentForm?.comments,
    status: myAssignmentForm?.status,
  };

  const { control, handleSubmit, formState, register, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(assignmentSchema),
  });
  const { errors } = formState;

  const handleAdd = async (data) => {
    const {
      employeeId,
      department,
      comments,
      // employeeName,
      project,
      billableType,
      utilization,
    } = data || null;

    const payload = {
      userId: parseInt(loginUserId),
      employeeId: employeeId,
      projectId: parseInt(project),
      status: isActive ? "active" : "inactive",
      department: department,
      billableType: billableType,
      utilization: utilization,
      comments: comments,
      ...(!isMyAssignmentForm && {
        projectAssignmentId: myAssignmentForm?.projectAssignmentId,
      }),
    };

    try {
      setAddLoading(true);
      const ProjectAllocation = isMyAssignmentForm
        ? await postProjectAllocation(payload)
        : await putProjectAllocation(payload);
      setAddLoading(false);
      fetchMyAssignment();
      if (isMyAssignmentForm) {
        dispatch(setIsSubmitMyAssignment(true));
      } else {
        dispatch(setIsSubmitMyAssignment(false));
      }
      onCancel();
      showSnackbar(
        ProjectAllocation?.message ||
          "Successfully updated project assignment details."
      );
    } catch (error) {
      setAddLoading(false);
      dispatch(setIsSubmitMyAssignment(false));
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSwitchChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleProjectChange = (e) => {
    const selectedOption = projectsOptionsList.find(
      (option) => option.value === e.target.value
    );
    setValue("managerName", selectedOption?.managerName);
  };

  return (
    <>
      <Grid container spacing={3}>
        <CreateEmployeeLeftTextGrid item xs={12} sm={6}>
          <FormTextField
            name="employeeName"
            label="Employee Name"
            control={control}
            readOnly
            errors={!!errors.employeeName}
            helperText={errors.employeeName?.message}
          />
          <FormDropDown
            name="project"
            label="Project"
            control={control}
            options={projectsOptionsList}
            errors={!!errors.project}
            readOnly={!isMyAssignmentForm}
            helperText={errors.project?.message}
            register={register("project", {
              onChange: (e) => {
                handleProjectChange(e);
              },
            })}
          />
          <FormTextField
            name="managerName"
            label="Manager"
            control={control}
            errors={!!errors.managerName}
            helperText={errors.managerName?.message}
            readOnly
          />

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <StyledTextField
                value={isActive ? "Active" : "Inactive"}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <CommonSwitch
                      checked={isActive}
                      onChange={handleSwitchChange}
                    />
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormDropDown
                name="utilization"
                label="Utilization"
                control={control}
                options={utailizationOptionsList}
                errors={!!errors.utilization}
                helperText={errors.utilization?.message}
              />
            </Grid>
          </Grid>
        </CreateEmployeeLeftTextGrid>
        <CreateEmployeeRightTextGrid item xs={12} sm={6}>
          <FormDropDown
            name="department"
            label="Department"
            control={control}
            options={departmentsOptionsList}
            errors={!!errors.department}
            helperText={errors.department?.message}
          />
          <FormDropDown
            name="billableType"
            label="Billing Type"
            control={control}
            options={billableTypesOptionsList}
            errors={!!errors.billableType}
            helperText={errors.billableType?.message}
          />
          <FormTextField
            name="comments"
            label="Comments"
            control={control}
            multiline
            rows={4}
            errors={!!errors.comments}
            helperText={errors.comments?.message}
          />
        </CreateEmployeeRightTextGrid>
        <RenderSaveCancelButtons
          handleCancel={handleCancel}
          handleSubmit={handleSubmit(handleAdd)}
          buttonLoading={addLoading}
          buttonName={isMyAssignmentForm ? "Add" : "Save"}
        />
      </Grid>
      <NotificationPopup />
    </>
  );
};

export default MyAssignmentForm;
