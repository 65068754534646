import React, { useState } from "react";
import {
  MuiCompactMarginTab,
  MuiLargeSpacingTab,
  TabContainer,
  StickyTab,
} from "../../../Common/Common.styled";
import { HorizontalLineStyling } from "./ViewDetailsPage.styled";
import TabPanel, { a11yProps } from "../../../Common/TabPanel/TabPanel";
import EmployeeInformation from "./ViewDetailsPageTab/EmployeeInformation/EmployeeInformation";
import EducationAndCertificationDetails from "./ViewDetailsPageTab/EducationAndCertification/EducationAndCertificationDetails";
import DocumentDetails from "./ViewDetailsPageTab/DocumentDetails";
import OtherDetails from "./ViewDetailsPageTab/OtherDetails";
import SalaryAndBankDetails from "./ViewDetailsPageTab/SalaryAndBankDetails/SalaryAndBankDetails";
import PerformanceDetails from "./ViewDetailsPageTab/Performance/PerformanceDetails";

const EmployeeDetailsTabs = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabContainer>
        <StickyTab
          value={value}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          <MuiCompactMarginTab label="Employee Information" {...a11yProps(0)} />
          <MuiLargeSpacingTab label="Bank Info" {...a11yProps(1)} />
          {/* <MuiLargeSpacingTab
            label="Education & Certification"
            {...a11yProps(2)}
          />
          <MuiLargeSpacingTab label="Performance" {...a11yProps(3)} />
          <MuiLargeSpacingTab label="Documents" {...a11yProps(4)} /> */}
        </StickyTab>
        {/* <HorizontalLineStyling /> */}
      </TabContainer>
      <TabPanel value={value} index={0}>
        <EmployeeInformation />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SalaryAndBankDetails />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EducationAndCertificationDetails />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PerformanceDetails />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DocumentDetails />
      </TabPanel>
    </>
  );
};

export default EmployeeDetailsTabs;
