import { createSlice } from '@reduxjs/toolkit';
import { _setTabValue } from './tabAction';
import initialState from './tabIntialState';

export const tabSlice = createSlice({
  name: 'ep/tab',
  initialState,
  reducers: {
    setTabValue: _setTabValue,
    resetTab: () => initialState,
  },
});

export const { setTabValue, resetTab } = tabSlice.actions;

export default tabSlice.reducer;