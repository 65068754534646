import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoughnutChart from "./DoughnutChart";
import { getLeavesBalence } from "../../Leave/services/LeaveApi";
import { useLoader, useNotification } from "../../../Common/CommonUtils";
import {
  ApplyLink,
  DividerContainer,
  LeaveChartCard,
  LeaveChartCircle,
  LeaveChartContainer,
  LeaveOverview,
  LeaveOverviewContainer,
  NoLeavesContainer,
  Title,
} from "./Dashboard.styled";
import { useNavigate } from "react-router-dom";
import {
  setIsMyLeave,
  setIsNewApplyLeaveForm,
} from "../../Leave/store/applyLeavesSlice/applyLeaveSlice";
import { setNavigationRoute } from "../../SideNavbar/Store/navigationSlice/navigationSlice";
import { NoHolidaysTitle } from "../../Leave/components/Leave.styled";

const LeaveChart = () => {
  const dispatch = useDispatch();
  const [leaveBalence, setLeaveBalence] = useState([]);
  const navigate = useNavigate();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { setLoading, LoaderPopup } = useLoader();

  useEffect(() => {
    fetchLeavesBalence();
  }, []);

  const fetchLeavesBalence = async () => {
    try {
      setLoading(true);
      const leavesBalence = await getLeavesBalence(loginUserId);
      setLeaveBalence(leavesBalence);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const bgColor = ["#80bfff", "#ffad33"];
  const chartData = leaveBalence?.leave_summary?.map((item, index) => ({
    label: item.leave_type,
    value: item.availed_leave,
    total: item.total_leave,
    balanceLeave: item.balance_leave,
    bgColor: bgColor[index],
  }));

  const handleLeaveOverview = () => {
    navigate("/leave");
    dispatch(setNavigationRoute("/leave"));
  };

  const handleApplyLeave = () => {
    navigate("/leave");
    handleLeaveOverview();
    dispatch(setIsNewApplyLeaveForm(true));
    dispatch(setIsMyLeave(true));
  };

  return (
    <>
      <LeaveChartCard>
        <LeaveChartContainer container>
          <Title>Leaves</Title>
          <ApplyLink onClick={handleApplyLeave}>Apply Leave</ApplyLink>
        </LeaveChartContainer>
        <DividerContainer />
        <LeaveChartCircle marginLeft={1} container>
          {chartData ? (
            chartData?.map((item, index) => (
              <DoughnutChart
                key={index}
                label={item.label}
                value={item.value}
                bgColor={item.bgColor}
                total={item.total}
                balanceLeave={item.balanceLeave}
              />
            ))
          ) : (
            <NoLeavesContainer>
              <NoHolidaysTitle variant="h2">No Leaves Found</NoHolidaysTitle>
            </NoLeavesContainer>
          )}
        </LeaveChartCircle>
        <LeaveOverviewContainer>
          <LeaveOverview underline="always" onClick={handleLeaveOverview}>
            Leave Overview
          </LeaveOverview>
        </LeaveOverviewContainer>
      </LeaveChartCard>
      <NotificationPopup />
      <LoaderPopup />
    </>
  );
};

export default LeaveChart;
